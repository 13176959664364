import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";
import { authorizationRedirect } from "../../constants/api";
import global from "../../constants/global";

let accessT = Cookies.get("babtbu") || "";

export const accountInfoRequest = createAsyncThunk(
  "account/accountInfo",
  async () => {
    let config = {
      method: "get",
      url: `${global.apiBaseUrl}auth/isme`,
      headers: {
        Authorization: `Bearer ${accessT}`,
        "Content-Type": "application/json",
      },
    };

    let resp = null;

    await axios
      .request(config)
      .then((response) => {
        resp = response?.data;
      })
      .catch((error) => {
        // if(error?.response?.status === 401 ||
        //   error?.response?.status === 403){
        //     return
        //     // authorizationRedirect()
        // }
        // else{
        // resp = error?.response?.data?.detail
        resp = error?.response;
        // }
      });
    return resp;
  }
);

export const changePasswordRequest = createAsyncThunk(
  "account/changePassword",
  async (data) => {
    let config = {
      method: "post",
      url: `${global.apiBaseUrl}auth/change_password`,
      headers: {
        Authorization: `Bearer ${accessT}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    let resp = null;

    await axios
      .request(config)
      .then((response) => {
        resp = response?.data;
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403 
        ) {
          authorizationRedirect();
        } else {
          resp = error?.response;
        }
      });
    return resp;
  }
);

export const updateEmailRequest = createAsyncThunk(
  "account/updateEmail",
  async (data) => {
    let config = {
      method: "post",
      url: `${global.apiBaseUrl}auth/update_email`,
      headers: {
        Authorization: `Bearer ${accessT}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    let resp = null;

    await axios
      .request(config)
      .then((response) => {
        resp = response?.data;
      })
      .catch((error) => {
        if (error?.response?.status === 401 ||
          error?.response?.status === 403) {
          authorizationRedirect();
        } else {
          resp = error?.response;
        }
      });
    return resp;
  }
);

export const updateCountryRequest = createAsyncThunk(
  "account/updateCountry",
  async (data) => {
    let config = {
      method: "post",
      url: `${global.apiBaseUrl}auth/update_country`,
      headers: {
        Authorization: `Bearer ${accessT}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    let resp = null;

    await axios
      .request(config)
      .then((response) => {
        resp = response?.data;
      })
      .catch((error) => {
        if (error?.response?.status === 401 ||
          error?.response?.status === 403) {
          authorizationRedirect();
        } else {
          resp = error?.response;
        }
      });
    return resp;
  }
);

export const accountSlice = createSlice({
  name: "subscription",
  initialState: {
    isLoading: false,
    accountInfo: null,
    updateIsLoading: false,
    plans: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(accountInfoRequest.pending, (state, action) => {
      // if (!state.isLoading) {
      state.isLoading = true;
      // }
    });
    builder.addCase(changePasswordRequest.pending, (state, action) => {
      // if (!state.isLoading) {
      state.updateIsLoading = true;
      // }
    });
    builder.addCase(updateEmailRequest.pending, (state, action) => {
      // if (!state.isLoading) {
      state.updateIsLoading = true;
      // }
    });
    builder.addCase(updateCountryRequest.pending, (state, action) => {
      // if (!state.isLoading) {
      state.updateIsLoading = true;
      // }
    });

    builder.addCase(accountInfoRequest.fulfilled, (state, action) => {
      // if (state.isLoading ) {
      state.accountInfo = action.payload;
      state.isLoading = false;
      // }
    });
    builder.addCase(changePasswordRequest.fulfilled, (state, action) => {
      // if (state.isLoading ) {
      state.data = action.payload;
      state.updateIsLoading = false;
      // }
    });
    builder.addCase(updateEmailRequest.fulfilled, (state, action) => {
      // if (state.isLoading ) {
      state.data = action.payload;
      state.updateIsLoading = false;
      // }
    });
    builder.addCase(updateCountryRequest.fulfilled, (state, action) => {
      // if (state.isLoading ) {
      state.data = action.payload;
      state.updateIsLoading = false;
      // }
    });

    builder.addCase(accountInfoRequest.rejected, (state, action) => {
      // if (state.isLoading ) {
      state.isLoading = false;
      state.error = "nothing here";
      // }
    });
    builder.addCase(changePasswordRequest.rejected, (state, action) => {
      // if (state.isLoading ) {
      state.error = action.payload;
      state.updateIsLoading = false;
      // }
    });
    builder.addCase(updateEmailRequest.rejected, (state, action) => {
      // if (state.isLoading ) {
      state.error = action.payload;
      state.updateIsLoading = false;
      // }
    });
    builder.addCase(updateCountryRequest.rejected, (state, action) => {
      // if (state.isLoading ) {
      state.error = action.payload;
      state.updateIsLoading = false;
      // }
    });
  },
});

export default accountSlice.reducer;
