import React, { useEffect, useState } from "react";
import SubOverviewComp from "../../components/subscription/overview";
import SubPlanComp from "../../components/subscription/plan";
import SubHistoryComp from "../../components/subscription/history";
import { useLocation } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import LinkedCardsManager from "../../components/subscription/CardManager";

export default function SubscriptionPage() {
  const [page, setPage] = useState("overview");
  const location = useLocation();
  const { state } = location;
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const subPages = ["overview", "plans", "history", "linked cards"];

  useEffect(() => {
    if (tab === "plans") {
      setPage("plans");
    }
    if (tab === "history") {
      setPage("history");
    }
    if (tab === "overview") {
      setPage("overview");
    }
    if (tab === "linked cards") {
      setPage("linked cards");
    }
  }, [tab]);

  const changePage = (val) => {
    setSearchParams({
      tab: val,
    });
    setPage(val);
  };
  useEffect(() => {
    if (!searchParams.size)
      setSearchParams(() => {
        const param = { tab: subPages[0] };
        changePage(param.tab);
        return param;
      });
  }, []);

  return (
    <div>
      <div className="">
        <div className="">
          <div className="">
            <div className="mt-4 mb-6 flex gap-6 ">
              {subPages?.map((val, i) => (
                <div
                  className={`font-semiboldInter cursor-pointer text-[#181B01] text-sm leading-[20.3px] tracking-[-2%] justify-between  ${
                    val === page &&
                    "active bg-light border  rounded-[25px] px-6 flex justify-center items-center"
                  }`}
                  onClick={val === "cards" ? () => {} : () => changePage(val)}
                  key={i}
                >
                  <p className="py-3 capitalize ">{val}</p>
                </div>
              ))}
            </div>
            <hr className="" />
          </div>
        </div>
        <div className="">
          {page === "overview" && <SubOverviewComp changePage={changePage} />}
          {page === "plans" && <SubPlanComp />}
          {page === "history" && <SubHistoryComp />}
          {page === "linked cards" && <LinkedCardsManager />}
        </div>
      </div>
    </div>
  );
}
