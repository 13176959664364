import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../redux/features/auth/authSlice";
import dashboardReducer from "../redux/features/dashboard/dashboardSlice";
import conversationsReducer from "../redux/features/conversations/conversationsSlice";
import ticketingReducer from "../redux/features/ticketing/ticketingSlice";
import customersReducer from "../redux/features/customers/customersSlice";
import generalConfigReducer from "../redux/features/configurations/generalConfig/generalConfig";
import dataConfigReducer from "../redux/features/configurations/dataConfig/dataConfigSlice";
import escalationConfigReducer from "../redux/features/configurations/escalationConfig/escalationConfigSlice";
import routeReducer from "../redux/features/route/routeSlice";
import subscriptionReducer from "./features/subscription/subscriptionSlice";
import profileReducer from "../redux/features/profile/profileSlice";
import teamReducer from "../redux/features/teams/teamSlice";
import allContentByCategoryReducer from "./features/contents/contentSlice";
import accountReducer from "./features/account/accountSlice";
import notificationReducer from "./features/notifications/notificationSlice";
import verifyTokenReducer from "./features/verifyToken/verifyTokenSlice";
import AIChatReducer from "./features/chat/chatSlice";

export default configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),

  reducer: {
    authReducer,
    dashboardReducer,
    conversationsReducer,
    ticketingReducer,
    customersReducer,
    generalConfigReducer,
    dataConfigReducer,
    escalationConfigReducer,
    subscriptionReducer,
    profileReducer,
    teamReducer,
    verifyTokenReducer,

    allContentByCategoryReducer,
    accountReducer,
    notificationReducer,
    AIChatReducer,
    routeReducer,
  },
});
