import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";

import { BsChevronLeft, BsChevronRight } from "react-icons/bs";

const Pagination = ({ pageCount, onChange, initialPage }) => {
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    onChange(currentPage);
  }, [currentPage]);

  const handlePageClick = (page) => {
    setCurrentPage(page.selected);
  };
  return (
    <div>
      <div className="">
        <ReactPaginate
          activeClassName={"paginations_active "}
          className="paginates w-auto flex justify-center"
          disabledClassName={"paginations_disabled"}
          pageClassName="page-item"
          nextLabel={<BsChevronRight size={20} />}
          nextLinkClassName={"next_page"}
          onPageChange={handlePageClick}
          pageCount={pageCount}
          initialPage={initialPage}
          previousLabel={<BsChevronLeft size={20} />}
          previousLinkClassName={"previous_page"}
        />
      </div>
    </div>
  );
};

export default Pagination;
