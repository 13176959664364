import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";

const Card = ({ paragraph, button, descParagraph, to, cardPics, state }) => {
  return (
    <div className="  border-[#D9D9D999] shadow-custom bg-white border-opacity-[60%] border-[0.4px]  rounded-lg p-3 mt-2 mb-6 ">
      <div className="firstHalf">
        <div className="cardhalf ">
          <div className="cardImg">{cardPics}</div>
          <p>{paragraph}</p>
        </div>
        <div>
          <Link state={state} style={{ textDecoration: "none" }} to={to}>
            <button className="flex px-4 py-2 rounded-3xl border items-center gap-[10px] border-light">
              <p className="font-semiboldInter text-sm leading-[16.94px]  text-[#462B60] ">
                {button}{" "}
              </p>
              <div>
                <BsArrowRight />
              </div>
            </button>
          </Link>
        </div>
      </div>
      <div className="secondHalf">
        <p>Description:</p>
        <p>{descParagraph}</p>
      </div>
    </div>
  );
};

export default Card;
