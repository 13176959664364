import React, { useEffect, useState } from "react";
import cusAvatar from "../../../assets/cus-avatar.png";
import toast from "react-hot-toast";
import { FaEdit, FaSpinner, FaToggleOn } from "react-icons/fa";
import Table from "../../../reuseable/table/Table";
import moment from "moment";
import CustomPagination from "../../customPagination/CustomPagination";
import edit from "../../../assets/edit-02.svg";
import Pagination from "../../../reuseable/Pagination";
import ClickOutside from "../../utils/helpers";
import { singleConversationRequest } from "../../../redux/features/conversations/conversationsSlice";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { updateTicketRequest } from "../../../redux/features/ticketing/ticketingSlice";

export default function TicketingListComp(props) {
  const [page, setPage] = useState("open");
  const [items, setItems] = useState([]);
  const [activeItem, setActiveItem] = useState(0);
  const [activeStatus, setActiveStatus] = useState("Open");
  const [sessionId, setSessionId] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [ticketStatus, setTicketStatus] = useState("");

  const [moreOptions, setMoreOptions] = useState(false);
  const [moreOptionsId, setMoreOptionsId] = useState("");
  const [moveTicketModal, setMoveTicketModal] = useState(false);
  const [moveTicketValue, setMoveTicketValue] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [filteredItems, setFilteredItems] = useState([]);
  const itemsPerPage = 10;

  const location = useLocation();

  const { state } = location;

  const ticketData = ["Open", "Ongoing", "Blocked", "Done"];

  const dispatch = useDispatch();

  const truncateWithEllipsis = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    } else {
      return text.slice(0, maxLength) + "...";
    }
  };

  const node = ClickOutside(() => {
    setMoveTicketModal(false);
  });

  useEffect(() => {
    if (Array.isArray(props?.allTickets)) {
      setItems(props.allTickets);
    }
  }, [props.allTickets]);

  useEffect(() => {
    if (items.length > 0) {
      // Iterate through all tabs
      for (let i = 0; i < ticketData.length; i++) {
        // Filter items for the current tab
        const tabItems = items?.filter(
          (ticket) => ticket.ticket_status === ticketData[i].toLowerCase()
        );
        // If the tab has items, set it as the active tab and update filteredItems
        if (tabItems.length > 0) {
          setActiveItem(i);
          setActiveStatus(ticketData[i]);
          setFilteredItems(tabItems);
          return; // Exit the loop once the first tab with data is found
        }
      }
    }
  }, [items]);

  const handleTabClick = (index) => {
    setActiveItem(index);
    setActiveStatus(ticketData[index]); // Update active status based on clicked tab
  };

  // Function to handle tab click
  // const handleTabClick = (index) => {
  //   setActiveItem(index);
  //   setActiveStatus(ticketData[index]); // Update active status based on clicked tab
  // };
  useEffect(() => {
    const filteredItems = items?.filter(
      (ticket) => ticket?.ticket_status === activeStatus.toLowerCase()
    );
    setFilteredItems(filteredItems);
    // setActiveItem(activeItem);
  }, [activeItem]);

  let editModal = (ticketData) => {
    let activeTicket = {
      title: ticketData?.title,
      description: ticketData?.description,
      status: ticketData?.ticket_status,
      id: moreOptionsId,
      assigned_to: ticketData?.assigned_to,
      session_id:ticketData.session_id
    };
    props?.toggleEditModal(activeTicket);
  };

  let moveTicket = (ticketDatas) => {
    if (!moveTicketValue) {
      toast.error("Please select ticket status");
      return;
    }
    const updatedTicketData = {
      ticket_id: ticketDatas.ticket_id,
      title: ticketDatas.title,
      description: ticketDatas.description,
      ticket_status: moveTicketValue,
      assigned_to: ticketDatas.assigned_to,
    };
    props?.updateTicketByMovement(updatedTicketData);
  };

  useEffect(() => {
    setTotalPages(Math.ceil(filteredItems?.length / itemsPerPage));
  }, [filteredItems?.length]);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };
  let getConvo = () => {
    if (sessionId) {
      let data = {
        session_id: sessionId,
      };
      dispatch(singleConversationRequest(data))
        .then((res) => {
          if (res?.payload?.status === 200) {
          } else {
            toast.error(res?.payload?.data?.message);
          }
        })
        .catch((err) => {});
    }
  };

  useEffect(() => {
    getConvo();
  }, [sessionId]);

  let updateTicketByMovement = (activeTicket) => {
    let data = {
      title: activeTicket?.title,
      description: activeTicket?.description,
      ticket_id: activeTicket?.ticket_id,
      ticket_status: activeTicket?.ticket_status,
      assigned_to: activeTicket?.assigned_to?.email,
    };
    dispatch(updateTicketRequest(data))
      .then((res) => {
        if (res?.payload?.status === true) {
          toast.success("Ticket Moved successfully");
        } else {
          toast.error(res?.payload?.data?.response);
        }
      })
      .catch((err) => {});
  };

  const columns = [
    {
      Header: "S/N",
      accessor: "id",
      Cell: (row) => (
        <div className="font-PJ font-medium text-[12px] text-[#424242]">
          {currentPage > 0
            ? itemsPerPage * currentPage + parseInt(row.cell.row.id) + 1
            : parseInt(row.cell.row.id) + 1}
        </div>
      ),
    },
    {
      Header: "User",
      accessor: "name",
      Cell: (row) => {
        const firstTitleInitial = row.cell.row.original.title
          .charAt(0)
          .toUpperCase();
        const backgroundColor = row.index % 2 === 0 ? "#FDFFE6" : "#F7E6FF";

        return (
          <div className="flex gap-4 items-center">
            <div
              className={`bg-[#F7E6FF] rounded-full h-8 w-8 text-base flex items-center justify-center text-black font-semiboldInter font-semibold`}
            >
              {firstTitleInitial}
            </div>
            <div>
              <p className="font-PJ-semibold text-[14px] text-[#101010]">
                {(row.cell.row.original.title &&
                  truncateWithEllipsis(row.cell.row.original.title, 20)) ||
                  "Name Not Available"}
              </p>
              <p className="font-inter font-normal mt-[6px] text-[12px] text-[#636363]">
                {(row.cell.row.original.email &&
                  truncateWithEllipsis(row.cell.row.original.email, 20)) ||
                  "Email Not Available"}
              </p>
            </div>
          </div>
        );
      },
    },
    {
      Header: "Ticket Id",
      accessor: "ticket_id",
      Cell: (row) => (
        <div className="font-PJ font-medium text-[12px]">
          {(row.cell.row.original.ticket_id &&
            truncateWithEllipsis(row.cell.row.original.ticket_id, 25)) ||
            "Ticket Id Not Available"}
        </div>
      ),
    },
    {
      Header: "Status",
      accessor: "ticket_status",
      Cell: (row) => (
        <div className="font-PJ font-medium text-[12px]">
          {(row.cell.row.original.ticket_status &&
            truncateWithEllipsis(row.cell.row.original.ticket_status, 20)) ||
            "Ticket Status Not Available"}
        </div>
      ),
    },
    {
      Header: "Time Created",
      accessor: "time_created",
      Cell: (row) => (
        <div className="font-inter font-normal text-[14px] text-[#424242] ">
          {moment(row.cell.value).format("MMM DD, yyyy") || "Not Available "}
        </div>
      ),
    },
    {
      Header: "Ticket Content",
      accessor: "description",
      Cell: (row) => (
        <div className="font-PJ font-medium text-[12px] text-[#404040] ">
          {(row.cell.row.original.description &&
            truncateWithEllipsis(row.cell.row.original.description, 20)) ||
            "description Not Available"}
        </div>
      ),
    },
    {
      Header: "Actions",
      accessor: "action",
      Cell: (row) => (
        <div>
          <div className="more-dropdown">
            <button
              className="btn btn-view"
              onClick={() => {
                setMoreOptions(!moreOptions);
                setMoreOptionsId(row.cell.row.original?.ticket_id);
              }}
            >
              <i className="ri-more-fill me-2 ri-xl" />
            </button>

            {moreOptions &&
              moreOptionsId === row.cell.row.original?.ticket_id && (
                <div className="relative">
                  <div className="absolute top-0 right-6 p-4 w-[160px] bg-white z-20 rounded-lg shadow-lg">
                    <div
                      className="flex justify-start items-center gap-3"
                      onClick={() => {
                        editModal(row.cell.row.original);
                        setMoveTicketModal(false);
                      }}
                    >
                      <img src={edit} alt="edit" />
                      <p className="font-PJ-semibold">Edit Ticket</p>
                    </div>
                    {/* <hr className="mt-3 mb-3" /> */}
                    <div
                      className="flex py-2 gap-3 mt-3 items-center"
                      onClick={() => {
                        setMoveTicketModal(!moveTicketModal);
                        const ticketStatus =
                          row.cell.row.original?.ticket_status;
                        // const ticketId = row.cell.row.original?.ticket_id;
                        // const ticketIdString =
                        //   typeof ticketId === "string"
                        //     ? ticketId.toLowerCase()
                        //     : "";
                        setMoveTicketValue(ticketStatus);
                      }}
                    >
                      <FaToggleOn className="w-4 h-4" />
                      <p className="font-PJ-semibold">Move Ticket</p>
                    </div>

                    {moveTicketModal &&
                      moreOptionsId === row.cell.row.original?.ticket_id && (
                        <div
                          ref={node}
                          className="more-options shadow-lg py-4"
                          style={{
                            top: "25px",
                            left: `${
                              ticketStatus[ticketStatus.length - 1] === row
                                ? "-240px"
                                : "0px"
                            }`,
                            width: "220px",
                            zIndex: "2",
                          }}
                        >
                          {sessionId && (
                            <div className="border-bottom">
                              <div>
                                <h4 style={{ fontSize: "12px" }}>
                                  Attached Conversation
                                </h4>
                                <div className="flex gap-2 small">
                                  <p>Name:</p>
                                  <p>{name}</p>
                                </div>
                                <div className="flex gap-2 small">
                                  <p>Email:</p>
                                  <p>{email}</p>
                                </div>
                              </div>
                              <div className="mb-2">
                                <Link
                                  state={{
                                    ...state,
                                    session_id: sessionId,
                                  }}
                                  to="/Messages"
                                  className="conversationBtn"
                                >
                                  Go to Conversation
                                </Link>
                              </div>
                            </div>
                          )}
                          <div className="mt-1 font-PJ-semibold text-xs leading-[18px] tracking-[-4%]">
                            Move Ticket to
                          </div>
                          <p className="font-inter leading-[12px] tracking-[-4%] text-[8px]">
                            You can only select one at a time
                          </p>
                          <div className=" ">
                            <div className="grid grid-cols-2 font-inter text-base ">
                              <div className="flex py-2 items-center me-3">
                                {moveTicketValue === "open" ? (
                                  <i
                                    className="ri-checkbox-line ri-xl mr-2"
                                    onClick={() => setMoveTicketValue("")}
                                  />
                                ) : (
                                  <i
                                    className="ri-checkbox-blank-line ri-xl mr-2"
                                    onClick={() => setMoveTicketValue("open")}
                                  />
                                )}
                                <p className="mb-0">Open</p>
                              </div>

                              <div className="flex py-2 items-center me-3">
                                {moveTicketValue === "ongoing" ? (
                                  <i
                                    className="ri-checkbox-line ri-xl mr-2"
                                    onClick={() => setMoveTicketValue("")}
                                  />
                                ) : (
                                  <i
                                    className="ri-checkbox-blank-line ri-xl mr-2"
                                    onClick={() =>
                                      setMoveTicketValue("ongoing")
                                    }
                                  />
                                )}
                                <p className="mb-0">Ongoing</p>
                              </div>

                              <div className="flex py-2 items-center me-3">
                                {moveTicketValue === "done" ? (
                                  <i
                                    className="ri-checkbox-line ri-xl mr-2"
                                    onClick={() => setMoveTicketValue("")}
                                  />
                                ) : (
                                  <i
                                    className="ri-checkbox-blank-line ri-xl mr-2"
                                    onClick={() => setMoveTicketValue("done")}
                                  />
                                )}
                                <p className="mb-0">Done</p>
                              </div>

                              <div className="flex py-2 items-center me-3">
                                {moveTicketValue === "blocked" ? (
                                  <i
                                    className="ri-checkbox-line ri-xl mr-2"
                                    onClick={() => setMoveTicketValue("")}
                                  />
                                ) : (
                                  <i
                                    className="ri-checkbox-blank-line ri-xl mr-2"
                                    onClick={() =>
                                      setMoveTicketValue("blocked")
                                    }
                                  />
                                )}
                                <p className="mb-0">Blocked</p>
                              </div>
                            </div>
                            {/* Repeat the above pattern for the other options */}
                          </div>
                          <div className="flex justify-center">
                            <button
                              className="bg-primary text-light py-1 px-8 font-inter text-sm rounded mt-3"
                              onClick={() => moveTicket(row.cell.row.original)}
                            >
                              {props?.updateTicketIsLoading ? (
                                <div>
                                  <FaSpinner className="animate-spin" />
                                  <span className="sr-only"> Loading...</span>
                                </div>
                              ) : (
                                "Move Ticket"
                              )}
                            </button>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              )}
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="align-center">
      <div className="main-tabs">
        <div className="flex gap-4">
          {ticketData.map((val, index) => {
            if (items) {
              const tabItems = items?.filter(
                (ticket) => ticket.ticket_status === val.toLowerCase()
              );

              const hasItems = tabItems.length > 0;

              return (
                hasItems && (
                  <div
                    key={index}
                    className={`flex cursor-pointer  font-inter font-medium rounded-[25px] tracking-[-2%] text-sm p-2 items-center gap-4 ${
                      activeItem === index
                        ? " px-4 py-1 bg-[#F1E4FF] h-11 border-[#EAD6FF] border-1 "
                        : " "
                    }`}
                    onClick={() => {
                      handleTabClick(index);
                      setActiveStatus(val);
                    }}
                  >
                    <p>{val}</p>
                    <div>
                      <p
                        className={`py-0 px-2 flex justify-center items-center  text-white rounded-[25px] ${
                          activeItem === index
                            ? "bg-primary "
                            : " bg-[#f0f0f0] !text-primary"
                        }  `}
                      >
                        {tabItems.length}
                      </p>
                    </div>
                  </div>
                )
              );
            } else {
              return null;
            }
          })}
        </div>
        <div activeKey={page} id="subTabs" onSelect={(tab) => setPage(tab)}>
          <div className="mt-8">
            <div>
              <div className="mt-2">
                <div className="">
                  <Table
                    columns={columns}
                    data={filteredItems || []}
                    currentPage={currentPage}
                    itemPerPage={itemsPerPage}
                    clg
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="py-2 flex justify-end items-end">
          {filteredItems?.length > 0 && (
            <Pagination
              onChange={handlePageChange}
              pageCount={totalPages}
              initialPage={currentPage}
            />
          )}
        </div>
      </div>
    </div>
  );
}
