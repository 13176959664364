import React, { useRef, useState } from "react";

const NotificationSettings = () => {
  const [newsAndUpdatesChecked, setNewsAndUpdatesChecked] = useState(false);
  const [broadcastingChecked, setBroadcastingChecked] = useState(false);
  const [remindersChecked, setRemindersChecked] = useState(false);
  const [notificationsChecked, setNotificationsChecked] = useState(false);

  const [allNotificationsChecked, setAllNotificationsChecked] = useState(false);
  const [remindersPushChecked, setRemindersPushChecked] = useState(false);
  const [messagesPushChecked, setMessagesPushChecked] = useState(false);

  const handleNewsAndUpdatesToggle = () => {
    setNewsAndUpdatesChecked((prevChecked) => !prevChecked);
  };

  const handleBroadcastingToggle = () => {
    setBroadcastingChecked((prevChecked) => !prevChecked);
  };

  const handleRemindersToggle = () => {
    setRemindersChecked((prevChecked) => !prevChecked);
  };

  const handleNotificationsToggle = () => {
    setNotificationsChecked((prevChecked) => !prevChecked);
  };

  const handleAllNotificationsToggle = () => {
    setAllNotificationsChecked((prevChecked) => !prevChecked);
  };

  const handleRemindersPushToggle = () => {
    setRemindersPushChecked((prevChecked) => !prevChecked);
  };

  const handleMessagesPushToggle = () => {
    setMessagesPushChecked((prevChecked) => !prevChecked);
  };

  return (
    <div className="bg-white rounded-xl border border-[#F0F0F0] px-[82px] py-[45px] mt-4 mb-[123px]  ">
      <div>
        <div className="flex gap-[196px] mt-12">
          <div className="">
            <h3 className="font-inter text-lg leading-[27px] tracking-[-1.5%] text-primary ">
              Email Notifications
            </h3>
            <p className="font-PJ-light lg:w-[326px] text-sm leading-[21px] text-[#1C1F00]">
              Get emails to figure out what’s going on when you’re not online.
              You can turn these off
            </p>
          </div>
          <div className="  ">
            <div className="flex gap-2 ">
              <div
                className={`w-[40px] h-[21px] rounded-[20px] relative border-2 cursor-pointer ${
                  newsAndUpdatesChecked
                    ? "bg-[#d2d0d0] border-[#d2d0d0] "
                    : "bg-purple-800 border-purple-800"
                }`}
                onClick={handleNewsAndUpdatesToggle}
              >
                <div
                  className={`w-[19px] h-[19px] rounded-full absolute top-[-1.0px] left-[-1px]  transform transition-transform transition-background ${
                    newsAndUpdatesChecked
                      ? "bg-[#ffffff]"
                      : "translate-x-full bg-white"
                  }`}
                ></div>
              </div>

              <div>
                <h3 className="font-inter text-base leading-[24px] tracking-[-1.5%] text-primary">
                  News and Update
                </h3>
                <p className="font-PJ-light lg:w-[326px] text-[12px] leading-[18px] text-[#1C1F00]">
                  News about product and feature update
                </p>
              </div>
            </div>
            <div className="flex gap-2 mt-[31px]">
              <div
                className={`w-[40px] h-[21px] rounded-[20px] relative border-2 cursor-pointer ${
                  broadcastingChecked
                    ? "bg-[#d2d0d0] border-[#d2d0d0] "
                    : "bg-purple-800 border-purple-800"
                }`}
                onClick={handleBroadcastingToggle}
              >
                <div
                  className={`w-[19px] h-[19px] rounded-full absolute top-[-1.0px] left-[-1px]  transform transition-transform transition-background ${
                    broadcastingChecked
                      ? "bg-[#ffffff]"
                      : "translate-x-full bg-white"
                  }`}
                ></div>
              </div>

              <div>
                <h3 className="font-inter text-base leading-[24px] tracking-[-1.5%] text-primary">
                  Broadcasting
                </h3>
                <p className="font-PJ-light lg:w-[326px] text-[12px] leading-[18px] text-[#1C1F00]">
                  Emails about new broadcasts that have been sent{" "}
                </p>
              </div>
            </div>

            <div className="flex gap-2 mt-[31px]">
              <div
                className={`w-[40px] h-[21px] rounded-[20px] relative border-2 cursor-pointer ${
                  remindersChecked
                    ? "bg-[#d2d0d0] border-[#d2d0d0] "
                    : "bg-purple-800 border-purple-800"
                }`}
                onClick={handleRemindersToggle}
              >
                <div
                  className={`w-[19px] h-[19px] rounded-full absolute top-[-1.0px] left-[-1px]  transform transition-transform transition-background ${
                    remindersChecked
                      ? "bg-[#ffffff]"
                      : "translate-x-full bg-white"
                  }`}
                ></div>
              </div>

              <div>
                <h3 className="font-inter text-base leading-[24px] tracking-[-1.5%] text-primary">
                  Reminders
                </h3>
                <p className="font-PJ-light lg:w-[326px] text-[12px] leading-[18px] text-[#1C1F00]">
                  There are notifications to remind you of updates you may have
                  missed{" "}
                </p>
              </div>
            </div>
            <div className="flex gap-2 mt-[31px]">
              <div
                className={`w-[40px] h-[21px] rounded-[20px] relative border-2 cursor-pointer ${
                  notificationsChecked
                    ? "bg-[#d2d0d0] border-[#d2d0d0] "
                    : "bg-purple-800 border-purple-800"
                }`}
                onClick={handleNotificationsToggle}
              >
                <div
                  className={`w-[19px] h-[19px] rounded-full absolute top-[-1.0px] left-[-1px]  transform transition-transform transition-background ${
                    notificationsChecked
                      ? "bg-[#ffffff]"
                      : "translate-x-full bg-white"
                  }`}
                ></div>
              </div>

              <div>
                <h3 className="font-inter text-base leading-[24px] tracking-[-1.5%] text-primary">
                  Notifications{" "}
                </h3>
                <p className="font-PJ-light lg:w-[326px] text-[12px] leading-[18px] text-[#1C1F00]">
                  You can receive all in-app notifications on any action as a
                  mail too.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
        <hr className="mt-10" />
        <div className="flex gap-[196px] mt-12">
          <div className="">
            <h3 className="font-inter text-lg leading-[27px] tracking-[-1.5%] text-primary ">
              Push Notifications{" "}
            </h3>
            <p className="font-PJ-light lg:w-[326px] text-sm leading-[21px] text-[#1C1F00]">
              Get push notifications in-app to find out what’s going on when
              you’re online
            </p>
          </div>
          <div className="  ">
            <div className="flex gap-2 ">
              <div
                className={`w-[40px] h-[21px] rounded-[20px] relative border-2 cursor-pointer ${
                  allNotificationsChecked
                    ? "bg-[#d2d0d0] border-[#d2d0d0] "
                    : "bg-purple-800 border-purple-800"
                }`}
                onClick={handleAllNotificationsToggle}
              >
                <div
                  className={`w-[19px] h-[19px] rounded-full absolute top-[-1.0px] left-[-1px]  transform transition-transform transition-background ${
                    allNotificationsChecked
                      ? "bg-[#ffffff]"
                      : "translate-x-full bg-white"
                  }`}
                ></div>
              </div>

              <div>
                <h3 className="font-inter text-base leading-[24px] tracking-[-1.5%] text-primary">
                  All Notifications{" "}
                </h3>
                <p className="font-PJ-light lg:w-[326px] text-[12px] leading-[18px] text-[#1C1F00]">
                  Receive notifications on all actions taken whilst using the
                  application{" "}
                </p>
              </div>
            </div>
            <div className="flex gap-2 mt-[31px]">
              <div
                className={`w-[40px] h-[21px] rounded-[20px] relative border-2 cursor-pointer ${
                  remindersPushChecked
                    ? "bg-[#d2d0d0] border-[#d2d0d0] "
                    : "bg-purple-800 border-purple-800"
                }`}
                onClick={handleRemindersPushToggle}
              >
                <div
                  className={`w-[19px] h-[19px] rounded-full absolute top-[-1.0px] left-[-1px]  transform transition-transform transition-background ${
                    remindersPushChecked
                      ? "bg-[#ffffff]"
                      : "translate-x-full bg-white"
                  }`}
                ></div>
              </div>

              <div>
                <h3 className="font-inter text-base leading-[24px] tracking-[-1.5%] text-primary">
                  Reminders{" "}
                </h3>
                <p className="font-PJ-light lg:w-[326px] text-[12px] leading-[18px] text-[#1C1F00]">
                  There are notifications to remind you of updates you may have
                  missed{" "}
                </p>
              </div>
            </div>

            <div className="flex gap-2 mt-[31px]">
              <div
                className={`w-[40px] h-[21px] rounded-[20px] relative border-2 cursor-pointer ${
                  messagesPushChecked
                    ? "bg-[#d2d0d0] border-[#d2d0d0] "
                    : "bg-purple-800 border-purple-800"
                }`}
                onClick={handleMessagesPushToggle}
              >
                <div
                  className={`w-[19px] h-[19px] rounded-full absolute top-[-1.0px] left-[-1px]  transform transition-transform transition-background ${
                    messagesPushChecked
                      ? "bg-[#ffffff]"
                      : "translate-x-full bg-white"
                  }`}
                ></div>
              </div>

              <div>
                <h3 className="font-inter text-base leading-[24px] tracking-[-1.5%] text-primary">
                  Messages{" "}
                </h3>
                <p className="font-PJ-light lg:w-[326px] text-[12px] leading-[18px] text-[#1C1F00]">
                  Receive a notification when you have new messages from
                  customers{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationSettings;
