import Cookies from 'js-cookie'
import global from './global'

export const authorizationRedirect = () => {
	//place rentry location.....login
	Cookies.remove('authenticated');
	Cookies.remove('babtbu');
	Cookies.remove('brbtbu');
	localStorage.removeItem("tm")
	window.location.assign(global.appBaseUrl + "login");
}

export let serverCodes = [
	500,501,502,503,504,505,506,507,508,509,510,511,
]

