import React, { useEffect } from "react";

const CustomCheckboxGroup = ({ options, selectedOptions, onChange }) => {
  // Ensure that "Widget" option is preselected only once when the component mounts
  useEffect(() => {
    if (
      !selectedOptions.includes("widget") &&
      options.some((option) => option.value === "widget")
    ) {
      onChange(["widget"]);
    }
  }, []); // Empty dependency array to run the effect only once when the component mounts

  const handleCheckboxChange = (value) => {
    // If the clicked option is already selected, don't do anything
    if (selectedOptions.includes(value)) return;

    // Deselect all other options and select the clicked option
    const newSelectedOptions = [value];
    onChange(newSelectedOptions);
  };

  return (
    <div>
      {options.map((option) => (
        <div key={option.value} className="py-2">
          <div className="flex justify-start border rounded-lg px-2 items-center">
            <input
              type="checkbox"
              id={option.value}
              value={option.value}
              checked={selectedOptions.includes(option.value)}
              onChange={() => handleCheckboxChange(option.value)}
              className="hidden"
            />
            <div className="flex items-center justify-center">
              <label
                htmlFor={option.value}
                className="w-[16px] h-[16px] mb-7  flex items-center border-[#9F9F9B] justify-center border-2 rounded-full cursor-pointer p-1 mr-2"
                style={{
                  backgroundColor: selectedOptions.includes(option.value)
                    ? "#51376A"
                    : "white",
                }}
              />
              <span>{option.label}</span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CustomCheckboxGroup;
