import React, { useState } from "react";
import { FaCheck, FaSpinner } from "react-icons/fa";
import AuthHeader from "../../../reuseable/authHeader";
import { signUpRequest } from "../../../redux/features/auth/authSlice";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { emailValidator } from "../../../components/utils/emailValidator";

const SignUpStep2 = ({ onPrevious, onNext, setDetails }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordStrength, setPasswordStrength] = useState([
    false,
    false,
    false,
  ]);
  const [lengthValue, setLengthValue] = useState(0);
  const [uppercaseValue, setUppercaseValue] = useState(0);
  const [specialCharValue, setSpecialCharValue] = useState(0);
  const [passwordErrors, setPasswordErrors] = useState([]);
  const [loading, setLoading] = useState(false);

  const firstData = JSON.parse(localStorage.getItem("sd"));

  const dispatch = useDispatch();

  let attemptSignup = () => {
    let data = {
      name: firstData.companyName,
      contact_first_name: firstData.firstName,
      contact_last_name: firstData.lastName,
      contact_phone: firstData.companyPhone,
      contact_email: email,
      country: firstData.companyCountry,
      password: password,
    };
    if (!password) {
      toast.error("Password cannot be blank");
      return;
    }

    if (!emailValidator(email)?.status) {
      toast.error(emailValidator(email)?.message);
    } else {
      setLoading(true);
      dispatch(signUpRequest(data))
        .then((res) => {
          let status = res?.payload?.status;
          if (status === 200) {
            toast.success("Check your email for token sent");
            localStorage.removeItem("sd");
            localStorage.setItem(
              "signOrgId",
              res?.payload?.data?.organization?.organisation_id
            );
            setDetails({ email: email, password: password });

            onNext();
          } else if (status == 500) {
            setLoading(false);
            toast.error(
              "There was an error creating your account, please try again"
            );
          } else {
            setLoading(false);
            if (res?.payload?.data?.detail != null) {
              toast.error(res?.payload?.data?.detail);
            } else {
              toast.error("Error creating account, please try again");
            }
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const handlePrevious = () => {
    // Go back to previous step
    onPrevious();
  };

  const handleNext = () => {
    // Validate password and proceed to next step
    if (passwordErrors.length === 0) {
      attemptSignup();
    } else {
      toast.error("Password does not meet the requirements.");
    }
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    validatePassword(newPassword);
  };

  const validatePassword = (value) => {
    const errors = [];
    // Check password requirements
    setPasswordStrength([
      value.match(/[a-z]/) ? true : false,
      value.match(/[A-Z]/) ? true : false,
      value.match(/[0-9!@#$%^&*]/) ? true : false,
    ]);
    if (!value.match(/[a-z]/)) {
      errors.push("At least 1 lower-case letter");
    }
    if (!value.match(/[A-Z]/)) {
      errors.push("At least 1 upper-case letter");
    }
    if (!value.match(/[0-9!@#$%^&*]/)) {
      errors.push("At least 1 number or special character (e.g. @#$)");
    }
    if (value.length < 8) {
      errors.push("At least 8 total characters");
    }
    // Update password errors
    setPasswordErrors(errors);
    // Update range bar values
    setLengthValue(value.length);
    setUppercaseValue((value.match(/[A-Z]/) || []).length);
    setSpecialCharValue((value.match(/[!@#$%^&*]/) || []).length);
  };

  return (
    <div className="">
      <div className="container mx-auto">
        <div className="flex justify-center">
          <div className="w-full max-w-md py-2">
            <div className="text-center">
              <p className="font-PJ-medium font-medium text-[32px] leading-[40.32px]">
                Email and Password
              </p>
              <p className="font-inter font-normal text-base leading-[22px]">
                Please input all details
              </p>
            </div>
            <div className="mt-4">
              <div className="mb-4">
                <label htmlFor="email" className="block mb-2">
                  Email Address
                </label>
                <input
                  type="email"
                  id="email"
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-green-500"
                  onChange={handleEmailChange}
                />
              </div>
              {/* Password Field */}
              <div className="mb-4">
                <label htmlFor="password" className="block mb-2">
                  Create Password
                </label>
                <input
                  type="password"
                  id="password"
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-green-500"
                  onChange={handlePasswordChange}
                />

                <div className="flex justify-between gap-1 mt-4 mb-4">
                  <div className="w-1/3">
                    <div
                      className={`h-[4px] ${
                        passwordStrength[0]
                          ? "bg-green-500"
                          : passwordStrength[0] === false && lengthValue > 0
                          ? "bg-red-500"
                          : "bg-gray-200"
                      } rounded-full`}
                    ></div>
                  </div>
                  <div className="w-1/3">
                    <div
                      className={`h-[4px] ${
                        passwordStrength[1]
                          ? "bg-green-500"
                          : passwordStrength[1] === false && uppercaseValue > 0
                          ? "bg-red-500"
                          : "bg-gray-200"
                      } rounded-full`}
                    ></div>
                  </div>
                  <div className="w-1/3">
                    <div
                      className={`h-[4px] ${
                        passwordStrength[2]
                          ? "bg-green-500"
                          : passwordStrength[2] === false &&
                            specialCharValue > 0
                          ? "bg-red-500"
                          : "bg-gray-200"
                      } rounded-full`}
                    ></div>
                  </div>
                </div>
                {/* Password Requirement Checklist */}
                <div className="mt-2 space-y-2">
                  <div className="flex items-center text-[#5E5E5E] font-regularInter font-normal text-xs">
                    <FaCheck
                      className={`mr-2 ${
                        password.match(/[a-z]/) ? "opacity-100" : "opacity-0"
                      } bg-[#9F6ECC] w-3 h-3 !text-white rounded-full  `}
                    />
                    <span>At least 1 lower-case letter</span>
                  </div>
                  <div className="flex items-center text-[#5E5E5E] font-regularInter font-normal text-xs">
                    <FaCheck
                      className={`mr-2 ${
                        password.match(/[A-Z]/) ? "opacity-100" : "opacity-0"
                      } bg-[#9F6ECC] w-3 h-3 !text-white  rounded-full  `}
                    />
                    <span>At least 1 upper-case letter</span>
                  </div>
                  <div className="flex items-center text-[#5E5E5E] font-regularInter font-normal text-xs">
                    <FaCheck
                      className={`mr-2 ${
                        password.match(/[0-9!@#$%^&*]/)
                          ? "opacity-100"
                          : "opacity-0"
                      } bg-[#9F6ECC] w-3 h-3 !text-white rounded-full  `}
                    />
                    <span>
                      At least 1 number or special character (e.g. @#$)
                    </span>
                  </div>
                  <div className="flex items-center text-[#5E5E5E] font-regularInter font-normal text-xs">
                    <FaCheck
                      className={`mr-2 ${
                        password.length >= 8 ? "opacity-100" : "opacity-0"
                      } bg-[#9F6ECC] w-3 h-3 !text-white rounded-full `}
                    />
                    <span>At least 8 total characters</span>
                  </div>
                </div>
              </div>
              <div className="flex w-full justify-between">
                <button
                  className="bg-[#101010] w-full text-[#E5C9FF] flex justify-center items-center rounded-lg px-6 py-3 font-inter font-medium leading-[19.36px] text-base"
                  onClick={handleNext}
                >
                  {loading ? (
                    <FaSpinner className="animate-spin" />
                  ) : (
                    "Continue"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpStep2;
