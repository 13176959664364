import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";
import { authorizationRedirect, serverCodes } from "../../constants/api";
import global from "../../constants/global";

let accessT = Cookies.get("babtbu") || "";
let orgId = Cookies.get("orgId") || "";

export const allFaqsRequest = createAsyncThunk("all/Faqs", async (data) => {
  let config = {
    method: "post",
    url: `${global.apiBaseUrl}faqs/get/all`,
    headers: {
      Authorization: `Bearer ${accessT}`,
      "Content-Type": "application/json",
    },
    data: data,
  };

  let resp = null;

  await axios
    .request(config)
    .then((response) => {
      resp = response;
    })
    .catch((error) => {
      if (
        serverCodes.includes(error?.request?.status) ||
        !error?.request?.status
      ) {
        resp = {
          data: {
            response: "Oops, Something went wrong, please try again later.",
          },
        };
        return;
      } else if (
        error?.response?.status === 401 ||
        error?.response?.status === 403
      ) {
        authorizationRedirect();
      } else {
        resp = error?.response;
      }
    });
  return resp;
});
export const createFaqsRequest = createAsyncThunk("Faqs/create", async (data) => {
  let config = {
    method: "post",
    url: `${global.apiBaseUrl}faqs/create`,
    headers: {
      Authorization: `Bearer ${accessT}`,
      "Content-Type": "multipart/form-data",
    },
    data: data,
  };

  let resp = null;

  await axios
    .request(config)
    .then((response) => {
      resp = response;
    })
    .catch((error) => {
      if (
        serverCodes.includes(error?.request?.status) ||
        !error?.request?.status
      ) {
        resp = {
          data: {
            response: "Oops, Something went wrong, please try again later.",
          },
        };
        return;
      } else if (
        error?.response?.status === 401 ||
        error?.response?.status === 403
      ) {
        authorizationRedirect();
      } else {
        resp = error?.response;
      }
    });
  return resp;
});
export const deleteFaqsRequest = createAsyncThunk("Faqs/delete", async (data) => {
  let config = {
    method: "post",
    url: `${global.apiBaseUrl}faqs/delete`,
    headers: {
      Authorization: `Bearer ${accessT}`,
      "Content-Type": "application/json",
    },
    data: data,
  };

  let resp = null;

  await axios
    .request(config)
    .then((response) => {
      resp = response;
    })
    .catch((error) => {
      if (
        serverCodes.includes(error?.request?.status) ||
        !error?.request?.status
      ) {
        resp = {
          data: {
            response: "Oops, Something went wrong, please try again later.",
          },
        };
        return;
      } else if (
        error?.response?.status === 401 ||
        error?.response?.status === 403
      ) {
        authorizationRedirect();
      } else {
        resp = error?.response;
      }
    });
  return resp;
});
export const updateFaqsRequest = createAsyncThunk("Faqs/update", async (data) => {
  let config = {
    method: "post",
    url: `${global.apiBaseUrl}faqs/update`,
    headers: {
      Authorization: `Bearer ${accessT}`,
      "Content-Type": "multipart/form-data",
    },
    data: data,
  };

  let resp = null;

  await axios
    .request(config)
    .then((response) => {
      resp = response;
    })
    .catch((error) => {
      if (
        serverCodes.includes(error?.request?.status) ||
        !error?.request?.status
      ) {
        resp = {
          data: {
            response: "Oops, Something went wrong, please try again later.",
          },
        };
        return;
      } else if (
        error?.response?.status === 401 ||
        error?.response?.status === 403
      ) {
        authorizationRedirect();
      } else {
        resp = error?.response;
      }
    });
  return resp;
});

export const faqSlice = createSlice({
  name: "faqs",
  initialState: {
    isLoading: false,
    profileInfo: null,
    data: null,
    updateIsLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(allFaqsRequest.pending, (state, action) => {
      // if (!state.isLoading) {
      state.isLoading = true;
      // }
    });
    builder.addCase(createFaqsRequest.pending, (state, action) => {
      // if (!state.isLoading) {
      state.isLoading = true;
      // }
    });
    builder.addCase(deleteFaqsRequest.pending, (state, action) => {
      // if (!state.isLoading) {
      state.isLoading = true;
      // }
    });
    builder.addCase(updateFaqsRequest.pending, (state, action) => {
      // if (!state.isLoading) {
      state.isLoading = true;
      // }
    });

    builder.addCase(allFaqsRequest.fulfilled, (state, action) => {
      // if (state.isLoading ) {
      state.data = action.payload;
      state.updateIsLoading = false;
      // }
    });
    builder.addCase(createFaqsRequest.fulfilled, (state, action) => {
      // if (state.isLoading ) {
      state.data = action.payload;
      state.updateIsLoading = false;
      // }
    });
    builder.addCase(deleteFaqsRequest.fulfilled, (state, action) => {
      // if (state.isLoading ) {
      state.data = action.payload;
      state.updateIsLoading = false;
      // }
    });
    builder.addCase(updateFaqsRequest.fulfilled, (state, action) => {
      // if (state.isLoading ) {
      state.data = action.payload;
      state.updateIsLoading = false;
      // }
    });

    builder.addCase(allFaqsRequest.rejected, (state, action) => {
      // if (state.isLoading ) {
      state.isLoading = false;
      state.error = action.payload;
      // }
    });
    builder.addCase(createFaqsRequest.rejected, (state, action) => {
      // if (state.isLoading ) {
      state.isLoading = false;
      state.error = action.payload;
      // }
    });
    builder.addCase(deleteFaqsRequest.rejected, (state, action) => {
      // if (state.isLoading ) {
      state.isLoading = false;
      state.error = action.payload;
      // }
    });
    builder.addCase(updateFaqsRequest.rejected, (state, action) => {
      // if (state.isLoading ) {
      state.isLoading = false;
      state.error = action.payload;
      // }
    });
  },
});
export default faqSlice.reducer;
