import React, { useState, useEffect, useRef } from "react";
import ProfileLink from "../profileLink";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Breadcrumb from "./Breadcrumb";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { orgSubscriptionHistoryRequest } from "../../redux/features/subscription/subscriptionSlice";

const Header = ({
  headerTitle,
  breadcrumbs,
  crumbs,
  breadcrumbsIcon,
  to1,
  to2,
}) => {
  const [subscriptionData, setSubscriptionData] = useState([]);
  const [daysLeft, setDaysLeft] = useState(null); // null as initial value
  const [activeSub, setActiveSub] = useState(null);
  const isMounted = useRef(false);
  const dispatch = useDispatch();

  const getSubHistory = () => {
    dispatch(orgSubscriptionHistoryRequest())
      .then((res) => {
        if (res?.payload?.status === 200) {
          const subscriptions = res.payload.data;
          setSubscriptionData(subscriptions); // Set subscription data
          checkSubs(subscriptions);
        } else {
          toast.error(res?.payload?.data?.response);
        }
      })
      .catch(() => {
        toast.error("Failed to fetch subscription history.");
      });
  };

  // Calculate remaining days from expiration date
  const calculateRemainingDays = (dateString) => {
    const endDate = new Date(dateString);
    const currentDate = new Date();
    const timeDiff = endDate.getTime() - currentDate.getTime();
    return Math.ceil(timeDiff / (1000 * 3600 * 24));
  };

  // Filter subscriptions and check the days left
  const checkSubs = (subs) => {
    if (subs.length === 0) return;

    // Filter out subscriptions with 'None' expiration dates
    const validSubs = subs.filter((sub) => sub.expiration_date !== "None");

    if (validSubs.length === 0) return;

    // Sort subscriptions by expiration date
    const sortedSubs = validSubs.sort(
      (a, b) => new Date(b.expiration_date) - new Date(a.expiration_date)
    );

    const lastSub = sortedSubs[0]; // Get the most recent subscription

    const remainingDays = calculateRemainingDays(lastSub.expiration_date);

    setDaysLeft(remainingDays); // Set the days left state

    setActiveSub(lastSub);
  };

  useEffect(() => {
    if (isMounted.current) return;
    isMounted.current = true;
    getSubHistory();
  }, []);

  return (
    <div className="">
      <div className="border-b-[0.4px] border-[#E7E7E7] top-0 flex pl-8 pr-10 justify-between items-center">
        <div className="mb-[14px]">
          <div className="HomeTitle !font-PJMedium text-2xl capitalize">
            {headerTitle}
          </div>
          <Breadcrumb />
        </div>
        <div className="flex justify-center items-center gap-12">
          <div className="flex items-center">
            <ProfileLink />
          </div>
        </div>
      </div>

      <div className="">
        {/* Show warning when days left is between 1 and 5 */}
        {daysLeft !== null && daysLeft <= 5 && daysLeft > 0 && (
          <div className="upgrade warningAlert">
            <div className="circularProgress">
              <CircularProgressbar
                counterClockwise
                value={daysLeft}
                minValue={0}
                maxValue={30} // Adjust maxValue based on the typical subscription duration
                text={`${daysLeft}`}
                styles={buildStyles({
                  textSize: "36px",
                  pathColor: "#FFD700", // Yellow for warning
                  trailColor: "#FFE4B5",
                  textColor: "#FFD700",
                })}
              />
            </div>
            <p className="!text-white">
              {`Your ${activeSub?.subscription_plan_id?.name} plan will expire in ${daysLeft} days.`}
            </p>
            <a href="/Subscription">
              <button className="bg-white px-4 py-2 rounded-[24px] font-inter font-semibold text-[#54266A]">
                Upgrade Now
              </button>
            </a>
          </div>
        )}

        {/* Show message when the subscription has ended */}
        {daysLeft !== null && daysLeft <= 0 && (
          <div className="upgrade subscriptionEndedAlert">
            <p className="!text-white">
              {`Your ${activeSub?.subscription_plan_id?.name} subscription has ended.`}
            </p>
            <a href="/Subscription">
              <button className="bg-white px-4 py-2 rounded-[24px] font-inter font-semibold text-[#54266A]">
                Renew Subscription
              </button>
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
