import React, { useEffect, useState } from "react";
import CustomSelect from "../agentConversation/customSelect"; // Adjust the path based on your file structure
import { BsPerson } from "react-icons/bs";
import { IoTrendingDown, IoTrendingUp } from "react-icons/io5";
import { TbNote } from "react-icons/tb";
import { useDispatch } from "react-redux";
import { conversationAnalytics } from "../../../redux/features/dashboard/dashboardSlice";
import Cookies from "js-cookie";
import { FaFileAlt } from "react-icons/fa";
import { LuFileBarChart2 } from "react-icons/lu";

const AgentConversations = () => {
  const dispatch = useDispatch();
  const [selectedAgentEmail, setSelectedAgentEmail] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(""); // Default to current month
  const [selectedYear, setSelectedYear] = useState(""); // Default to current year
  const [activeConversations, setActiveConversations] = useState(0);
  const [activeChange, setActiveChange] = useState(0);
  const [closedConversations, setClosedConversations] = useState(0);
  const [closedChange, setClosedChange] = useState(0);
  const [agentMetric, setAgentMetric] = useState([]);

  const orgId = Cookies.get("orgId") || "";

  const getConvo = () => {
    const data = {
      organisation_id: orgId,
      agent_email: selectedAgentEmail,
      month: selectedMonth,
      year: selectedYear,
    };

    console.log("Request Data:", data);

    dispatch(conversationAnalytics(data))
      .then((res) => {
        if (res?.payload?.status === 200) {
          const analyticsData = res?.payload?.data;
          console.log("Analytics Data:", analyticsData);

          // Assuming the data structure has these properties
          setActiveConversations(
            analyticsData?.agent_metrics?.active_conversations || 0
          );
          setActiveChange(analyticsData?.agent_metrics?.active_change || 0); // Adjust this key based on the actual structure
          setClosedConversations(
            analyticsData?.agent_metrics?.closed_conversations || 0
          );
          setClosedChange(analyticsData?.agent_metrics?.closed_change || 0); // Adjust this key based on the actual structure

          setAgentMetric(analyticsData?.agent_metrics || []);
        } else {
          console.error("Error:", res?.payload?.data?.message);
        }
      })
      .catch((err) => {
        console.error("Error fetching conversation analytics:", err);
      });
  };

  useEffect(() => {
    if (selectedAgentEmail && selectedMonth && selectedYear) {
      getConvo();
    }
  }, [selectedAgentEmail, selectedMonth, selectedYear]);

  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center">
          <LuFileBarChart2 className="w-5 h-5 mr-2" />
          <h2 className="text-lg font-medium text-gray-700">
            Agent Conversations
          </h2>
        </div>
        <div className="flex items-center space-x-4">
          <div className="w-48">
            <CustomSelect
              placeholder="Select Agent"
              type="agent"
              onChange={(option) => setSelectedAgentEmail(option.email)}
            />
          </div>

          <div className="w-48">
            <CustomSelect
              placeholder="Select Month"
              type="month"
              onChange={(option) => setSelectedMonth(option.value)}
            />
          </div>

          <div className="w-48">
            <CustomSelect
              placeholder="Select Year"
              type="year"
              onChange={(option) => setSelectedYear(option.value)}
            />
          </div>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4">
        {/* Active Conversation Card */}
        <div className="bg-[#f9f3fe] px-4 py-6 rounded-xl border-[0.4px] flex justify-between items-center">
          <div className="flex gap-3">
            <div className="w-10 h-10 flex border justify-center items-center rounded-[8px] bg-[#f9f3fe]">
              <BsPerson className="w-5 h-5" />
            </div>
            <div>
              <p className="text-sm text-gray-500 flex items-center">
                ACTIVE CONVERSATION
              </p>
              <h3 className="text-[26px] tracking-[-2%] leading-[31.2px] font-PJ-semibold text-[#101010]">
                {activeConversations}
              </h3>
            </div>
          </div>
          <div className="text-right">
            <p
              className={`text-sm flex items-center ${
                activeChange > 0 ? "text-green-500" : "text-orange-500"
              }`}
            >
              {activeChange > 0 ? <IoTrendingUp /> : <IoTrendingDown />}
              {Math.abs(activeChange)}%
            </p>
          </div>
        </div>

        {/* Closed Conversation Card */}
        <div className="bg-[#f9f3fe] px-4 py-6 rounded-xl border-[0.4px] flex justify-between items-center">
          <div className="flex gap-3">
            <div className="w-10 h-10 flex border justify-center items-center rounded-[8px] bg-[#f9f3fe]">
              <BsPerson className="w-5 h-5" />
            </div>
            <div>
              <p className="text-sm text-gray-500 flex items-center">
                CLOSED CONVERSATION
              </p>
              <h3 className="text-[26px] tracking-[-2%] leading-[31.2px] font-PJ-semibold text-[#101010]">
                {closedConversations}
              </h3>
            </div>
          </div>
          <div className="text-right">
            <p
              className={`text-sm flex items-center ${
                closedChange > 0 ? "text-green-500" : "text-orange-500"
              }`}
            >
              {closedChange > 0 ? <IoTrendingUp /> : <IoTrendingDown />}
              {Math.abs(closedChange)}%
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgentConversations;
