import { createSlice } from "@reduxjs/toolkit";

const routeState = createSlice({
  name: "routeState",
  initialState: {
    orgSubscription: {},
    planName: "",
    isExpired: false,
    profileInfo: {},
  },
  reducers: {
    setOrgSubscription: (state, action) => {
      state.orgSubscription = action.payload;
    },
    setPlanName: (state, action) => {
      state.planName = action.payload;
    },
       setIsExpired: (state, action) => {
      state.isExpired = action.payload;
    },
    setProfileInfo: (state, action) => {
      state.profileInfo = action.payload;
    },
  },
});

export const { setOrgSubscription, setPlanName, setProfileInfo, setIsExpired } =
  routeState.actions;

export default routeState.reducer;
