import Cookies from "js-cookie";
import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { RiCloseLine } from "react-icons/ri";

import {
  ActiveTag,
  ActiveTags,
  ClosedTag,
  EmptyStateComp,
  InactiveTags,
  MainLoader,
  VisitingTag,
} from "../../components/utils";
import {
  allCustomersRequest,
  createCustomerRequest,
} from "../../redux/features/customers/customersSlice";
import cusAvatar from "../../assets/cus-avatar.png";
import { Link, useLocation } from "react-router-dom";
import moment from "moment";
import { FaSpinner } from "react-icons/fa";
import Table from "../../reuseable/table/Table";
import { BsChevronDown, BsPlus } from "react-icons/bs";
import { TbEyeClosed } from "react-icons/tb";
import Pagination from "../../reuseable/Pagination";

export const subscriptionPlans = {
  BASIC: "Basic",
  FREETRIAL: "FREE-TRIAL",
  PROFESSIONAL: "Professional",
  ADVANCED: "Advance",
};

export default function CustomersPage() {
  const isMounted = useRef(false);
  const [addCusModal, setAddCusModal] = useState(false);
  const [viewCusModal, setViewCusModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [activeCustomerData, setActiveCustomerData] = useState({});
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const routeState = useSelector((state) => state.routeReducer);

  const { state } = location;
  const { orgSubscription, planName, menu, profileInfo } = routeState;
  const itemsPerPage = 10;

  const customersState = useSelector((state) => state.customersReducer);

  let dispatch = useDispatch();
  let orgId = Cookies.get("orgId") || "";

  let email_reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,15})+$/;

  useEffect(() => {
    if (isMounted.current) return;
    isMounted.current = true;
    getCustomerList();
  }, []);

  useEffect(() => {
    setTotalPages(
      Math.ceil(customersState?.allCustomer?.data?.length / itemsPerPage)
    );
  }, [customersState?.allCustomer?.data?.length]);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  const isPlanExpired = routeState?.isExpired;


  const isPlanValid = Object.values(subscriptionPlans).includes(planName);

  let getCustomerList = () => {
    setLoading(true);
    dispatch(allCustomersRequest())
      .then((res) => {
        setLoading(false);
        if (res?.payload?.status !== 200) {
          toast.error(res?.payload?.data?.detail);
        }
      })
      .catch((err) => { });
  };

  let addNewCustomer = () => {
    let data = {
      organisation_id: orgId,
      name: fullName,
      email: email,
    };

    if (!fullName) {
      toast.error("Full Name cannot be blank");
      return;
    }
    if (!email) {
      toast.error("Email cannot be blank");
      return;
    }
    if (!email_reg.test(email)) {
      toast.error("Invalid Email Address");
      return;
    }

    dispatch(createCustomerRequest(data))
      .then((res) => {
        if (res?.payload?.status === 200) {
          toast.success("Customer Added Successfully");
          setAddCusModal(false);
          getCustomerList();
        } else {
          toast.error(res?.payload?.data?.detail);
        }
      })
      .catch((err) => { });
  };

  const columns = [
    {
      Header: "S/N",
      accessor: "id",
      Cell: (row) => (
        <div className="font-PJ-bold font-medium text-xs text-[#424242]">
          {currentPage > 0
            ? itemsPerPage * currentPage + parseInt(row.cell.row.id) + 1
            : parseInt(row.cell.row.id) + 1}
        </div>
      ),
    },
    {
      Header: "User",
      accessor: "name",
      Cell: (row) => {
        const firstNameInitial = row.cell.row.original.name
          .charAt(0)
          .toUpperCase();

        return (
          <div className="flex gap-4 items-center">
            <div
              className={` bg-[#F7E6FF] rounded-full h-8 w-8 text-base flex items-center justify-center text-black font-semiboldInter font-semibold`}
            >
              {firstNameInitial}
            </div>
            <div>
              <p className="font-PJ-bold font-medium text-[14px] text-[#101010]">
                {row.cell.row.original.name || "Name Not Available"}
              </p>
              <p className="font-inter font-normal mt-[6px] text-[12px] text-[#636363]">
                {row.cell.row.original.email || "Email Not Available"}
              </p>
            </div>
          </div>
        );
      },
    },
    {
      Header: "Customer ID",
      accessor: "customer_id",
      Cell: (row) => (
        <div className="font-inter font-normal text-[14px] text-[#424242] ">
          {row.cell.value}
        </div>
      ),
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: (row) => (
        <div className=" ">
          {row.cell.value ? <InactiveTags /> : <ActiveTags />}
        </div>
      ),
    },
    {
      Header: " ",
      accessor: "action",
      Cell: (row) => (
        <div>
          <div
            className="dropdown-trigger"
            onClick={() => {
              setViewCusModal(true);
              setActiveCustomerData(row.cell.row.original);
            }}
          >
            <i className="ri-more-fill me-2 ri-xl" />
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="px-4 overflow-y-auto">
      {addCusModal && (
        <div className="main-modal flex justify-center items-center fixed inset-0 z-[999] bg-gray-800 bg-opacity-50">
          <div className="bg-white py-6 w-[32%] rounded-lg overflow-hidden shadow-xl">
            <div className="">
              <div className="">
                <div className="flex justify-between px-10 items-center">
                  <h5 className="p-2 font-PJ-medium font-medium text-lg">
                    Add New Customer
                  </h5>
                  <RiCloseLine
                    onClick={() => setAddCusModal(false)}
                    className="cursor-pointer"
                  />
                </div>
                <hr />
                <div className="mt-5 px-11">
                  <label htmlFor="name">Full Name</label>
                  <div className="pb-6 pt-2">
                    <input
                      type="text"
                      value={fullName}
                      required
                      className="form-input border w-full p-2"
                      onChange={(name) => setFullName(name.target.value)}
                      placeholder="John Doe"
                    />
                  </div>
                  <label htmlFor="email">Email Address</label>
                  <div className="pb-6 pt-2">
                    <input
                      type="email"
                      value={email}
                      required
                      className="form-input border w-full p-2"
                      onChange={(email) => setEmail(email.target.value)}
                      placeholder="name@mail.com"
                    />
                  </div>
                </div>
                <hr />
              </div>
              <div className=" flex justify-start xl:mt-4  mt-4 align-center">
                <div className="flex px-10 gap-2">
                  <button
                    className="bg-[#101010] text-[#E5C9FF] rounded-[10px]  font-inter font-medium text-sm flex gap-2 items-center py-3 px-4"
                    onClick={() => {
                      setAddCusModal(true);
                      setEmail("");
                      setFullName("");
                    }}
                  >
                    <BsPlus size={20} />
                    Add Customer
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {viewCusModal && (
        <div className=" fixed inset-0 flex items-center justify-center z-[999] bg-gray-900 bg-opacity-50">
          <div className="bg-white w-[30%]  py-6 rounded-2xl overflow-hidden shadow">
            <div className="">
              <div className=" ">
                <div className="flex justify-between px-10 ">
                  <h5 className="font-PJ-medium leading-[25.2px] tracking-[-4%] text-primary text-xl  ">
                    Customer Details{" "}
                  </h5>
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      setViewCusModal(false);
                      setActiveCustomerData({});
                    }}
                  >
                    <i className="ri-close-line ri-lg  close-modal text-black" />
                  </div>
                </div>
                <hr className="mt-3" />
                <div className="border shadow-sm mx-6 rounded-lg my-4">
                  <div className="">
                    <div className=" mb-4 mt-4 ">
                      <div
                        className={` bg-[#F7E6FF] rounded-full h-11 ml-[16.5px] w-11 text-[16.5px] leading-[19.8px]  flex items-center justify-center text-black font-semiboldInter `}
                      >
                        {activeCustomerData?.name
                          ?.split(" ")
                          .map((part) => part.charAt(0))
                          .join("")
                          .toUpperCase()}
                      </div>{" "}
                    </div>
                  </div>
                  <div className="flex justify-between px-[16.5px] items-center pb-4 ">
                    <div className="  capitalize font-semiboldInter text-lg">
                      <h1 className="font-PJ-medium text-xl leading-[25.2px] tracking-[-4%] ">
                        {activeCustomerData?.name || "Not Available"}
                      </h1>
                      <h6 className="font-PJ-regular text-[#636363] text-base leading-[19.36px] tracking-[-4%] ">
                        {activeCustomerData?.email || "Not Available"}
                      </h6>
                    </div>
                    <div className="  ">
                      <div className=" text-center font-inter text-primary ">
                        <Link
                          state={{
                            orgSubscription,
                            planName,
                            menu: menu?.name,
                            profileInfo,
                          }}
                          to={`/Messages`}
                          className=""
                        >
                          <button className="bg-primary text-light py-3 px-5 rounded-[10px] font-inter text-sm leading-[16.9px] tracking-[-4%] text-center">
                            Go to message
                          </button>
                        </Link>
                        {activeCustomerData?.conversations?.map((val) => (
                          <>
                            <hr />
                            <div className="align-items-end mb-2">
                              <div className="">
                                <span className="">
                                  <small className="text-color-light">
                                    Time Initiated
                                  </small>
                                  <h6 className="mb-0 pt-1">
                                    {moment(val?.time_created).format("lll") ||
                                      "Not Available"}
                                  </h6>
                                </span>
                              </div>
                              <div className="">
                                <span className="">
                                  <small className="text-color-light">
                                    Status
                                  </small>{" "}
                                  <br />
                                  {val?.status === "active" && <ActiveTag />}
                                  {val?.status === "closed" && <ClosedTag />}
                                  {val?.status === "pending" && <VisitingTag />}
                                </span>
                              </div>
                              <div className="">
                                <Link
                                  to={`/Messages?ref=${val?.session_id}`}
                                  className="link link-underline"
                                >
                                  <button className="btn btn-view px-0">
                                    <i className="ri-eye-line me-1" />
                                    View
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className=" flex justify-end xl:mt-4  mt-4 align-center">
        <div className="flex gap-2">
          {!isPlanExpired && (
            <button
              className="bg-[#101010] text-[#E5C9FF] rounded-[10px]  font-inter font-medium text-sm flex gap-2 items-center py-3 px-4"
              onClick={() => {
                setAddCusModal(true);
                setEmail("");
                setFullName("");
              }}
            >
              <BsPlus size={20} />
              Add Customer
            </button>
          )}
        </div>
      </div>
      {loading ? (
        <MainLoader />
      ) : (
        <>
          {!isPlanExpired && customersState?.allCustomer?.data?.length > 0 ? (
            <div className="mt-5">
              <div className="">
                <Table
                  columns={columns}
                  itemPerPage={itemsPerPage}
                  data={customersState?.allCustomer?.data.slice().reverse()}
                  currentPage={currentPage}
                />
              </div>
            </div>
          ) : (
            <div className="flex justify-center items-center mt-10">
              <EmptyStateComp
                title={
                  !isPlanExpired ? "No Customer Data" : "No Active Subscription"
                }
                desc={
                  !isPlanExpired
                    ? "You have no customer data yet, your customers will appear here as soon as you have some."
                    : "You have no active subscription, your customers will appear here as soon as you subscribe."
                }
              />
            </div>
          )}

          <div className="py-2  flex justify-end items-end">
            {isPlanValid && customersState?.allCustomer?.data?.length > 0 && !isPlanExpired && (
              <Pagination
                onChange={handlePageChange}
                pageCount={totalPages}
                initialPage={currentPage}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
}
