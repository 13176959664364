import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";
import { authorizationRedirect, serverCodes } from "../../constants/api";
import global from "../../constants/global";

let accessT = Cookies.get("babtbu") || "";
let orgId = Cookies.get("orgId") || "";

export const allPlansRequest = createAsyncThunk(
  "subscription/allPlans",
  async () => {
    let config = {
      method: "get",
      url: `${global.apiBaseUrl}subscriptions/plans`,
      headers: {
        Authorization: `Bearer ${accessT}`,
        "Content-Type": "application/json",
      },
    };

    let resp = null;

    await axios
      .request(config)
      .then((response) => {
        resp = response;
      })
      .catch((error) => {
        if (
          serverCodes.includes(error?.request?.status) ||
          !error?.request?.status
        ) {
          resp = {
            data: {
              response: "Oops, Something went wrong, please try again later.",
            },
          };
          return;
        } else if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          authorizationRedirect();
        } else {
          resp = error?.response;
        }
      });
    return resp;
  }
);

export const trySubscriptionRequest = createAsyncThunk(
  "subscription/subscribe",
  async (data) => {
    let config = {
      method: "post",
      url: `${global.apiBaseUrl}subscriptions/v2/initiate`,
      headers: {
        Authorization: `Bearer ${accessT}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    let resp = null;

    await axios
      .request(config)
      .then((response) => {
        resp = response;
      })
      .catch((error) => {
        resp = error?.response;
      });
    return resp;
  }
);

export const orgSubscriptionHistoryRequest = createAsyncThunk(
  "subscription/orgActiveSubscription",
  async () => {
    let config = {
      method: "post",
      url: `${global.apiBaseUrl}subscriptions/organisation/get`,
      headers: {
        Authorization: `Bearer ${accessT}`,
        "Content-Type": "application/json",
      },
      data: { organization_id: orgId },
    };

    let resp = null;

    await axios
      .request(config)
      .then((response) => {
        resp = response;
      })
      .catch((error) => {
        if (
          serverCodes.includes(error?.request?.status) ||
          !error?.request?.status
        ) {
          resp = {
            data: {
              response: "Oops, Something went wrong, please try again later.",
            },
          };
          return;
        } else if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          authorizationRedirect();
        } else {
          resp = error?.response;
        }
      });
    return resp;
  }
);

export const cancelSubRequest = createAsyncThunk(
  "subscription/cancelSub",
  async (data) => {
    let config = {
      method: "post",
      url: `${global.apiBaseUrl}subscriptions/cancel`,
      headers: {
        Authorization: `Bearer ${accessT}`,
        "Content-Type": "application/json",
      },
      data: {
        organization_id: orgId,
        subscription_id: data?.subscription_id,
      },
    };

    let resp = null;

    await axios
      .request(config)
      .then((response) => {
        resp = response;
      })
      .catch((error) => {
        if (
          serverCodes.includes(error?.request?.status) ||
          !error?.request?.status
        ) {
          resp = {
            data: {
              response: "Oops, Something went wrong, please try again later.",
            },
          };
          return;
        } else if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          authorizationRedirect();
        } else {
          resp = error?.response;
        }
      });
    return resp;
  }
);

export const subAutoRenewToggleRequest = createAsyncThunk(
  "subscription/subAutoRenewToggle",
  async (data) => {
    let config = {
      method: "post",
      url: `${global.apiBaseUrl}subscriptions/autorenew/toggle`,
      headers: {
        Authorization: `Bearer ${accessT}`,
        "Content-Type": "application/json",
      },
      data: {
        organization_id: orgId,
        subscription_id: data?.subscription_id,
      },
    };

    let resp = null;

    await axios
      .request(config)
      .then((response) => {
        resp = response;
      })
      .catch((error) => {
        if (
          serverCodes.includes(error?.request?.status) ||
          !error?.request?.status
        ) {
          resp = {
            data: {
              response: "Oops, Something went wrong, please try again later.",
            },
          };
          return;
        } else if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          authorizationRedirect();
        } else {
          resp = error?.response;
        }
      });
    return resp;
  }
);

export const addCardRequest = createAsyncThunk(
  "subscription/addCard",
  async () => {
    let config = {
      method: "post",
      url: `${global.apiBaseUrl}subscriptions/card/remove`,
      headers: {
        Authorization: `Bearer ${accessT}`,
        "Content-Type": "application/json",
      },
      data: { organization_id: orgId },
    };

    let resp = null;

    await axios
      .request(config)
      .then((response) => {
        resp = response;
      })
      .catch((error) => {
        if (
          serverCodes.includes(error?.request?.status) ||
          !error?.request?.status
        ) {
          resp = {
            data: {
              response: "Oops, Something went wrong, please try again later.",
            },
          };
          return;
        } else if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          authorizationRedirect();
        } else {
          resp = error?.response;
        }
      });
    return resp;
  }
);

export const allCardsRequest = createAsyncThunk(
  "subscription/allCards",
  async () => {
    let config = {
      method: "post",
      url: `${global.apiBaseUrl}subscriptions/cards`,
      headers: {
        Authorization: `Bearer ${accessT}`,
        "Content-Type": "application/json",
      },
      data: { organization_id: orgId },
    };

    let resp = null;

    await axios
      .request(config)
      .then((response) => {
        resp = response;
      })
      .catch((error) => {
        if (
          serverCodes.includes(error?.request?.status) ||
          !error?.request?.status
        ) {
          resp = {
            data: {
              response: "Oops, Something went wrong, please try again later.",
            },
          };
          return;
        } else if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          authorizationRedirect();
        } else {
          resp = error?.response;
        }
      });
    return resp;
  }
);

export const removeCardRequest = createAsyncThunk(
  "subscription/removeCard",
  async (data) => {
    let config = {
      method: "post",
      url: `${global.apiBaseUrl}subscriptions/card/remove`,
      headers: {
        Authorization: `Bearer ${accessT}`,
        "Content-Type": "application/json",
      },
      data: {
        organization_id: orgId,
        card_id: data?.card_id,
      },
    };

    let resp = null;

    await axios
      .request(config)
      .then((response) => {
        resp = response;
      })
      .catch((error) => {
        if (
          serverCodes.includes(error?.request?.status) ||
          !error?.request?.status
        ) {
          resp = {
            data: {
              response: "Oops, Something went wrong, please try again later.",
            },
          };
          return;
        } else if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          authorizationRedirect();
        } else {
          resp = error?.response;
        }
      });
    return resp;
  }
);
export const orgSubscriptionRequest = createAsyncThunk(
  "subscription/orgSubscription",
  async () => {
    let config = {
      method: "post",
      url: `${global.apiBaseUrl}subscriptions/organisation/get`,
      headers: {
        Authorization: `Bearer ${accessT}`,
        "Content-Type": "application/json",
      },
      data: {
        organization_id: orgId,
      },
    };

    let resp = null;

    await axios
      .request(config)
      .then((response) => {
        resp = response;
      })
      .catch((error) => {
        if (
          serverCodes.includes(error?.request?.status) ||
          !error?.request?.status
        ) {
          resp = {
            data: {
              response: "Oops, Something went wrong, please try again later.",
            },
          };
          return;
        } else if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          authorizationRedirect();
        } else {
          resp = error?.response;
        }
      });
    return resp;
  }
);
export const orgCardsRequest = createAsyncThunk(
  "subscription/orgCards",
  async () => {
    let config = {
      method: "post",
      url: `${global.apiBaseUrl}subscriptions/cards`,
      headers: {
        Authorization: `Bearer ${accessT}`,
        "Content-Type": "application/json",
      },
      data: {
        organization_id: orgId,
      },
    };

    let resp = null;

    await axios
      .request(config)
      .then((response) => {
        resp = response;
      })
      .catch((error) => {
        if (
          serverCodes.includes(error?.request?.status) ||
          !error?.request?.status
        ) {
          resp = {
            data: {
              response: "Oops, Something went wrong, please try again later.",
            },
          };
          return;
        } else if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          authorizationRedirect();
        } else {
          resp = error?.response;
        }
      });
    return resp;
  }
);
export const orgCardsRemoveRequest = createAsyncThunk(
  "subscription/orgCards/remove",
  async (data) => {
    const { card_id, organization_id } = data;

    if (!accessT) {
      throw new Error("Access token is missing.");
    }

    const config = {
      method: "post",
      url: `${global.apiBaseUrl}subscriptions/card/remove`,
      headers: {
        Authorization: `Bearer ${accessT}`,
        "Content-Type": "application/json",
      },
      data: { card_id, organization_id }, 
    };

    try {
      const response = await axios.request(config);
      return response;
    } catch (error) {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        authorizationRedirect();
      }

      if (
        serverCodes.includes(error?.request?.status) ||
        !error?.request?.status
      ) {
        throw new Error("Oops, Something went wrong, please try again later.");
      } else {
        throw new Error(
          error?.response?.data?.response || "Failed to remove card."
        );
      }
    }
  }
);


export const subscriptionSlice = createSlice({
  name: "subscription",
  initialState: {
    isLoading: false,
    updateIsLoading: false,
    data: null,
    plans: null,
    organisationPlans: null,
    organisationCards: null,
    subHistory: null,
    allCards: null,
    cancelSub: null,
    autoRenewToggle: null,
    addedCard: null,
    removedCard: null,

    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(allPlansRequest?.pending, (state, action) => {
      // if (!state.isLoading) {
      state.isLoading = true;
      // }
    });
    builder.addCase(trySubscriptionRequest?.pending, (state, action) => {
      // if (!state.isLoading) {
      state.updateIsLoading = true;
      // }
    });
    builder.addCase(orgSubscriptionHistoryRequest?.pending, (state, action) => {
      // if (!state.isLoading) {
      state.isLoading = true;
      // }
    });
    builder.addCase(allCardsRequest?.pending, (state, action) => {
      // if (!state.isLoading) {
      state.isLoading = true;
      // }
    });
    builder.addCase(cancelSubRequest?.pending, (state, action) => {
      // if (!state.isLoading) {
      state.updateIsLoading = true;
      // }
    });
    builder.addCase(subAutoRenewToggleRequest?.pending, (state, action) => {
      // if (!state.isLoading) {
      state.updateIsLoading = true;
      // }
    });
    builder.addCase(addCardRequest?.pending, (state, action) => {
      // if (!state.isLoading) {
      state.updateIsLoading = true;
      // }
    });
    builder.addCase(removeCardRequest?.pending, (state, action) => {
      // if (!state.isLoading) {
      state.updateIsLoading = true;
      // }
    });
    builder.addCase(orgSubscriptionRequest?.pending, (state, action) => {
      // if (!state.isLoading) {
      state.isLoading = true;
      // }
    });
    builder.addCase(orgCardsRequest?.pending, (state, action) => {
      // if (!state.isLoading) {
      state.isLoading = true;
      // }
    });

    builder.addCase(allPlansRequest?.fulfilled, (state, action) => {
      // if (state.isLoading ) {
      // console.log(action.payload)
      state.plans = action?.payload;
      state.isLoading = false;
      // }
    });
    builder.addCase(trySubscriptionRequest?.fulfilled, (state, action) => {
      // if (state.isLoading ) {
      state.data = action?.payload;
      state.updateIsLoading = false;
      // }
    });
    builder.addCase(
      orgSubscriptionHistoryRequest?.fulfilled,
      (state, action) => {
        // if (state.isLoading ) {
        state.subHistory = action?.payload;
        state.isLoading = false;
        // }
      }
    );
    builder.addCase(allCardsRequest?.fulfilled, (state, action) => {
      // if (state.isLoading ) {
      state.allCards = action?.payload;
      state.isLoading = false;
      // }
    });
    builder.addCase(cancelSubRequest?.fulfilled, (state, action) => {
      // if (state.isLoading ) {
      state.cancelSub = action?.payload;
      state.updateIsLoading = false;
      // }
    });
    builder.addCase(subAutoRenewToggleRequest?.fulfilled, (state, action) => {
      // if (state.isLoading ) {
      state.autoRenewToggle = action?.payload;
      state.updateIsLoading = false;
      // }
    });
    builder.addCase(addCardRequest?.fulfilled, (state, action) => {
      // if (state.isLoading ) {
      state.addedCard = action?.payload;
      state.updateIsLoading = false;
      // }
    });
    builder.addCase(removeCardRequest?.fulfilled, (state, action) => {
      // if (state.isLoading ) {
      state.removedCard = action?.payload;
      state.updateIsLoading = false;
      // }
    });
    builder.addCase(orgSubscriptionRequest?.fulfilled, (state, action) => {
      // if (state.isLoading ) {
      // console.log(action.payload)
      state.organisationPlans = action?.payload;
      state.isLoading = false;
      // }
    });
    builder.addCase(orgCardsRequest?.fulfilled, (state, action) => {
      // if (state.isLoading ) {
      // console.log(action.payload)
      state.organisationCards = action?.payload;
      state.isLoading = false;
      // }
    });

    builder.addCase(allPlansRequest?.rejected, (state, action) => {
      // console.log(action.payload)
      // if (state.isLoading ) {
      state.isLoading = false;
      state.error = action?.payload;
      // }
    });
    builder.addCase(trySubscriptionRequest?.rejected, (state, action) => {
      // console.log(action.payload)
      // if (state.isLoading ) {
      state.error = action?.payload;
      state.updateIsLoading = false;
      // }
    });
    builder.addCase(
      orgSubscriptionHistoryRequest?.rejected,
      (state, action) => {
        // console.log(action.payload)
        // if (state.isLoading ) {
        state.error = action?.payload;
        state.isLoading = false;
        // }
      }
    );
    builder.addCase(allCardsRequest?.rejected, (state, action) => {
      // console.log(action.payload)
      // if (state.isLoading ) {
      state.error = action?.payload;
      state.isLoading = false;
      // }
    });
    builder.addCase(cancelSubRequest?.rejected, (state, action) => {
      // console.log(action.payload)
      // if (state.isLoading ) {
      state.error = action?.payload;
      state.updateIsLoading = false;
      // }
    });
    builder.addCase(subAutoRenewToggleRequest?.rejected, (state, action) => {
      // console.log(action.payload)
      // if (state.isLoading ) {
      state.error = action?.payload;
      state.updateIsLoading = false;
      // }
    });
    builder.addCase(addCardRequest?.rejected, (state, action) => {
      // console.log(action.payload)
      // if (state.isLoading ) {
      state.error = action?.payload;
      state.updateIsLoading = false;
      // }
    });
    builder.addCase(removeCardRequest?.rejected, (state, action) => {
      // console.log(action.payload)
      // if (state.isLoading ) {
      state.error = action?.payload;
      state.updateIsLoading = false;
      // }
    });
    builder.addCase(orgSubscriptionRequest?.rejected, (state, action) => {
      // console.log(action.payload)
      // if (state.isLoading ) {
      state.isLoading = false;
      state.error = action?.payload;
      // }
    });
    builder.addCase(orgCardsRequest?.rejected, (state, action) => {
      // console.log(action.payload)
      // if (state.isLoading ) {
      state.isLoading = false;
      state.error = action?.payload;
      // }
    });
  },
});

export default subscriptionSlice.reducer;
