import React, { useEffect, useState } from "react";
import profile from "../../../assets/homeIcons/Frame 1000003239.svg";
import "react-phone-number-input/style.css";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import {
  profileInfoRequest,
  updateProfileInfoRequest,
} from "../../../redux/features/profile/profileSlice";
import toast from "react-hot-toast";
import Cookies from "js-cookie";
import picture from "../../../assets/broadcasting/icon.svg";

const ProfileSetUp = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");
  const profileState = useSelector((state) => state.profileReducer);
  const [profile_img, setProfile_img] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const profileDetails = location.state?.profileInfo;
  let orgId = Cookies.get("orgId") || "";

  useEffect(() => {
    dispatch(profileInfoRequest()); // Dispatch action to fetch profile details when component mounts
  }, [dispatch]);

  const navigate = useNavigate();

  // Update state variables with profile details once fetched
  useEffect(() => {
    if (profileState.profileInfo?.data) {
      const { data } = profileState.profileInfo;
      setFirstName(data.contact_first_name || "");
      setLastName(data.contact_last_name || "");
      setEmail(data.contact_email || "");
      setPhoneNumber(data.contact_phone || "");
      setBusinessName(data.name || "");
    }
  }, [profileState.profileInfo]);

  let profileUpdate = () => {
    let data = {
      organization_id: orgId,
      contact_first_name: firstName,
      contact_last_name: lastName,
      contact_email: email,
      contact_phone: phoneNumber,
      name: businessName,
      country: country,
      city: region,
    };

    if (!firstName) {
      toast.error("First Name cannot be blank");
      return;
    }
    if (!lastName) {
      toast.error("Last Name cannot be blank");
      return;
    }
    // if (!emailValidator(email)?.status) {
    //   toast.error(emailValidator(email)?.message);
    //   return;
    // }
    // if (emailValidator(email)?.status === true) {
    //   toast.error("");
    // }
    // if (!phoneNumber) {
    //   toast.error("Business Phone Number cannot be blank");
    //   return;
    // }
    // if (!businessName) {
    //   toast.error("Business Name cannot be blank");
    //   return;
    // }
    // if (!country) {
    //   toast.error("Business Country cannot be blank");
    //   return;
    // }
    // if (!region) {
    //   toast.error("Business City cannot be blank");
    //   return;
    // }

    dispatch(updateProfileInfoRequest(data))
      .then((res) => {
        if (res?.payload?.status === 200) {
          toast.success("Account successfully updated");
          navigate("/settings?tab=profile", { replace: true });
        } else {
          toast.error(res?.payload?.data?.detail?.detail);
        }
      })
      .catch((err) => {
        const errorMessage =
          typeof err === "string" ? err : err.message || "An error occurred";
        toast.error(errorMessage);
        console.error(err);
      });
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleProfileImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setProfile_img(URL.createObjectURL(file));
    }
  };

  const handlePhoneNumberChange = (value) => {
    setPhoneNumber(value);
    const countryCode = value?.split(" ")[0];
    setCountry(countryCode);
  };

  return (
    <div className="flex justify-center">
      <div className="my-12 px-8 w-[937px]">
        <div className="">
          <div className="">
            <div className="">
              <div className=" bg-white rounded-xl border border-[#F0F0F0] px-[82px] py-[45px] ">
                <div className="flex gap-8  ">
                  <img
                    className="w-[90px] h-[90px] rounded-full"
                    src={profile_img || profile}
                    alt="profilepic"
                  />
                  <div>
                    <div className="">
                      <h4 className="font-PJ-semibold text-[#181B01] font-semibold text-[20px] leading-[25.2px] tracking-[-4%] ">
                        {businessName || "GovaHQ"}
                      </h4>
                      <p className="font-PJ-regular w-[200px] text-sm leading-[20.3px] text-[#1E1E1E66] text-opacity-[40%]  ">
                        This image will be displayed on your profile
                      </p>
                    </div>
                    <div className="mt-[-20px]">
                      <label
                        htmlFor="profileImageUpload"
                        className="flex w-[149px] items-center border-[1.5px] border-[#595959] rounded-lg px-3 py-2 gap-[10px] "
                      >
                        <img src={picture} alt="" />
                        <p className="font-PJ-regular font-medium text-sm leading-[20.3px] text-[#595959]">
                          Change Photo
                        </p>
                      </label>
                      <input
                        style={{
                          visibility: "hidden",
                          position: "absolute",
                          top: 0,
                          left: 0,
                        }}
                        type="file"
                        id="profileImageUpload"
                        accept="image/*"
                        onChange={handleProfileImageUpload}
                      />
                      <div style={{ position: "relative", zIndex: 0 }}></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full mt-4 max-w-8xl">
          <div className="bg-white rounded-xl shadow border-gray-200 p-[82px]">
            <div className="mb-[74px] flex justify-between gap-[87px]">
              <div>
                <h6 className="text-lg font-medium text-gray-900 mb-2">
                  Business Information
                </h6>
                <p className="text-sm text-gray-600 mb-4">
                  Take control of your account with our easy-to-use settings.
                </p>
              </div>
              <div className="grid grid-cols-2 gap-4 w-full">
                <div className="w-full">
                  <label
                    className="block font-medium text-[#1e1e1e] text-opacity-[60%] font-PJ-regular text-base leading-[20.16px] mb-1"
                    htmlFor="firstName"
                  >
                    First Name
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    className="block w-full rounded-md border px-3 border-gray-300 shadow-sm"
                    placeholder="Daramola"
                    onChange={(e) => setFirstName(e.target.value)}
                    value={firstName}
                  />
                </div>
                <div className="w-full">
                  <label
                    className="block font-medium text-[#1e1e1e] text-opacity-[60%] font-PJ-regular text-base leading-[20.16px] mb-1"
                    htmlFor="lastName"
                  >
                    Last Name
                  </label>
                  <input
                    type="text"
                    id="lastName"
                    className="block w-full rounded-md border px-3 border-gray-300 shadow-sm"
                    placeholder="Joseph"
                    onChange={(e) => setLastName(e.target.value)}
                    value={lastName}
                  />
                </div>
                <div>
                  <label
                    className="block  font-medium text-[#1e1e1e] text-opacity-[60%] font-PJ-regular text-base leading-[20.16px] mb-1"
                    htmlFor="businessName"
                  >
                    Business Name
                  </label>
                  <input
                    type="text"
                    id="businessName"
                    className="block w-full rounded-md border px-3 border-gray-300 shadow-sm"
                    placeholder="Venixs Inc"
                    onChange={(e) => setBusinessName(e.target.value)}
                    value={businessName}
                  />
                </div>
                <div>
                  <label
                    className="block font-medium text-[#1e1e1e] text-opacity-[60%] font-PJ-regular text-base leading-[20.16px] mb-1"
                    htmlFor="businessEmail"
                  >
                    Business Email
                  </label>
                  <input
                    type="email"
                    id="businessEmail"
                    className="block w-full rounded-md border border-gray-300 px-3 shadow-sm"
                    placeholder="johndoe@gmail.com"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                </div>
              </div>
            </div>
            <hr className="" />
            <div className="mb-8 mt-[74px] flex justify-center gap-[87px]">
              <div>
                <h6 className="text-lg font-medium text-gray-900 mb-2">
                  Profile Information
                </h6>
                <p className="text-sm text-gray-600 mb-4">
                  Take control of your account with our easy-to-use settings.
                </p>
              </div>
              <div className="grid grid-cols-2 gap-6 w-full">
                <div>
                  <label
                    className="block  font-medium text-[#1e1e1e] text-opacity-[60%] font-PJ-regular text-base leading-[20.16px] mb-1"
                    htmlFor="country"
                  >
                    Country
                  </label>
                  <CountryDropdown
                    id="country"
                    className="block w-full rounded-md border border-gray-300 shadow-sm"
                    value={country}
                    onChange={(val) => setCountry(val)}
                  />
                </div>
                <div>
                  <label
                    className="block font-PJ-regular text-base leading-[20.16px] font-medium text-[#1e1e1e] text-opacity-[60%] mb-1"
                    htmlFor="region"
                  >
                    State
                  </label>
                  <RegionDropdown
                    id="region"
                    className="block w-full rounded-md border border-gray-300 shadow-sm"
                    country={country}
                    value={region}
                    onChange={(val) => setRegion(val)}
                  />
                </div>
                <div className="col-span-2">
                  <label
                    className="block font-PJ-regular text-base leading-[20.16px] font-medium text-[#1e1e1e] text-opacity-[60%] mb-1"
                    htmlFor="businessPhone"
                  >
                    Business Phone Number
                  </label>
                  <PhoneInputWithCountrySelect
                    id="businessPhone"
                    className="block w-full rounded-md border px-4 !border-gray-300 shadow-sm"
                    defaultCountry="NG"
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    placeholder="9078967662"
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-end mt-[82px]">
              <button
                className="text-black px-4 py-2 font-inter text-base leading-[19.2px] tracking-[-4%] "
                onClick={() => window.location.reload()}
              >
                Refresh
              </button>
              <button
                className="bg-[#010101] border border-[#F0F0F0] text-[#E5C9FF] font-semiboldInter text-base leading-[19.36px] tracking-[-4%] px-4 py-3 rounded-lg"
                onClick={profileUpdate}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileSetUp;
