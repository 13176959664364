import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import { useDispatch } from "react-redux";
import { dashboardInfoRequest } from "../../../redux/features/dashboard/dashboardSlice";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

const ConversationsChart = ({
  orgId,
  selectedAgentEmail,
  selectedMonth,
  selectedYear,
}) => {
  const [activeConversationsData, setActiveConversationsData] = useState([]);
  const [closedConversationsData, setClosedConversationsData] = useState([]);
  const [labels, setLabels] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const getConvo = async () => {
      const data = {
        organisation_id: orgId,
        agent_email: selectedAgentEmail,
        month: selectedMonth,
        year: selectedYear,
      };

      try {
        const res = await dispatch(dashboardInfoRequest(data));
        if (res?.payload?.status === 200) {
          const analyticsData = res?.payload?.data;

          // Extracting and organizing data for the chart
          const activeData = [];
          const closedData = [];
          const dates = [];

          analyticsData?.graph_data?.forEach((item) => {
            if (item.type === "Active Conversations") {
              activeData.push(item.value);
            } else if (item.type === "Closed Conversations") {
              closedData.push(item.value);
            }

            if (!dates.includes(item.date)) {
              dates.push(item.date);
            }
          });

          setActiveConversationsData(activeData);
          setClosedConversationsData(closedData);
          setLabels(dates);
        } else {
          console.error("Error response:", res?.payload?.data?.response);
        }
      } catch (err) {
        console.error("Error fetching conversation analytics:", err);
      }
    };

    getConvo();
  }, [dispatch, orgId, selectedAgentEmail, selectedMonth, selectedYear]);

  const data = {
    labels: labels, // Dates from the response
    datasets: [
      {
        label: "Active Conversations",
        data: activeConversationsData,
        backgroundColor: "#375DFB",
        hoverBackgroundColor: "#375DFB",
        borderWidth: 0,
        barThickness: 36,
        borderRadius: 2,
      },
      {
        label: "Closed Conversations",
        data: closedConversationsData,
        backgroundColor: "#FF6384",
        hoverBackgroundColor: "#FF6384",
        borderWidth: 0,
        barThickness: 36,
        borderRadius: 2,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false, // Important to allow custom height
    plugins: {
      legend: {
        position: "top",
      },
      tooltip: {
        callbacks: {
          label: (context) => `Conversations: ${context.raw}`,
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Date",
        },
        ticks: {
          autoSkip: true,
          display: false, // Hide x-axis labels if needed
        },
        grid: {
          display: false, // Hide grid lines on x-axis
        },
        stacked: false,
        categoryPercentage: 0.6,
        barPercentage: 0.8,
        border: {
          display: false, // Hide the x-axis line
        },
      },
      y: {
        title: {
          display: false,
          text: "Number of Conversations",
        },
        ticks: {
          display: false, // Hide y-axis labels if needed
        },
        grid: {
          display: false, // Hide grid lines on y-axis
        },
        border: {
          display: false, // Hide the y-axis line
        },
      },
    },
  };

  return (
    <div className="w-full h-[400px] md:h-[300px] lg:h-[400px] min-w-[320px] min-h-[200px]">
      <Bar data={data} options={options} />
    </div>
  );
};

export default ConversationsChart;
