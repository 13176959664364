import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import nexts from "../../assets/nexts.svg";
import prevs from "../../assets/prevs.svg";

const CustomPagination = ({ pageCount, onChange, initialPage }) => {
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    onChange(currentPage);
  }, [currentPage]);

  const handlePageClick = (page) => {
    setCurrentPage(page.selected);
  };
  return (
    <div>
      <div className="">
        <ReactPaginate
          activeClassName={"pagination_active "}
          className="paginate w-auto flex justify-center"
          disabledClassName={"pagination_disabled"}
          pageClassName="page-item"
          nextLabel={
            <i color="" className="">
              <img width={20} src={nexts} alt="Next" />
            </i>
          }
          nextLinkClassName={"next_page"}
          onPageChange={handlePageClick}
          pageCount={pageCount}
          initialPage={initialPage}
          previousLabel={
            <i className="">
              <img width={20} src={prevs} alt="Previous" />
            </i>
          }
          previousLinkClassName={"previous_page"}
        />
      </div>
    </div>
  );
};

export default CustomPagination;
