import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";
import { authorizationRedirect, serverCodes } from "../../constants/api";
import global from "../../constants/global";

let accessT = Cookies.get("babtbu") || "";
let orgId = Cookies.get("orgId") || "";

export const allTeamRequest = createAsyncThunk("all/team", async (data) => {
  let config = {
    method: "post",
    url: `${global.apiBaseUrl}team/all`,
    headers: {
      Authorization: `Bearer ${accessT}`,
      "Content-Type": "application/json",
    },
    data: data,
  };

  let resp = null;

  await axios
    .request(config)
    .then((response) => {
      resp = response;
    })
    .catch((error) => {
      if (
        serverCodes.includes(error?.request?.status) ||
        !error?.request?.status
      ) {
        resp = {
          data: {
            response: "Oops, Something went wrong, please try again later.",
          },
        };
        return;
      } else if (
        error?.response?.status === 401 ||
        error?.response?.status === 403
      ) {
        authorizationRedirect();
      } else {
        resp = error?.response;
      }
    });
  return resp;
});

export const teamAddRequest = createAsyncThunk("team/add", async (data) => {
  let config = {
    method: "post",
    url: `${global.apiBaseUrl}team/add`,
    headers: {
      Authorization: `Bearer ${accessT}`,
      "Content-Type": "application/json",
    },
    data: data,
  };

  let resp = null;

  await axios
    .request(config)
    .then((response) => {
      resp = response;
    })
    .catch((error) => {
      if (
        serverCodes.includes(error?.request?.status) ||
        !error?.request?.status
      ) {
        resp = {
          data: {
            response: "Oops, Something went wrong, please try again later.",
          },
        };
        return;
      } else if (
        error?.response?.status === 401 ||
        error?.response?.status === 403
      ) {
        authorizationRedirect();
      } else {
        resp = error?.response;
      }
    });
  return resp;
});
export const teamDeleteRequest = createAsyncThunk(
  "team/delete",
  async (data) => {
    let config = {
      method: "post",
      url: `${global.apiBaseUrl}team/delete`,
      headers: {
        Authorization: `Bearer ${accessT}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    let resp = null;

    await axios
      .request(config)
      .then((response) => {
        resp = response;
      })
      .catch((error) => {
        if (
          serverCodes.includes(error?.request?.status) ||
          !error?.request?.status
        ) {
          resp = {
            data: {
              response: "Oops, Something went wrong, please try again later.",
            },
          };
          return;
        } else if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          authorizationRedirect();
        } else {
          resp = error?.response;
        }
      });
    return resp;
  }
);
export const teamUpdateRequest = createAsyncThunk(
  "team/update",
  async (data) => {
    let config = {
      method: "post",
      url: `${global.apiBaseUrl}team/update/role`,
      headers: {
        Authorization: `Bearer ${accessT}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    let resp = null;

    await axios
      .request(config)
      .then((response) => {
        resp = response;
      })
      .catch((error) => {
        if (
          serverCodes.includes(error?.request?.status) ||
          !error?.request?.status
        ) {
          resp = {
            data: {
              response: "Oops, Something went wrong, please try again later.",
            },
          };
          return;
        } else if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          authorizationRedirect();
        } else {
          resp = error?.response;
        }
      });
    return resp;
  }
);

export const teamSlice = createSlice({
  name: "Team",
  initialState: {
    isLoading: false,
    profileInfo: null,
    data: null,
    updateIsLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(allTeamRequest.pending, (state, action) => {
      // if (!state.isLoading) {
      state.isLoading = true;
      // }
    });
    builder.addCase(teamAddRequest.pending, (state, action) => {
      // if (!state.isLoading) {
      state.isLoading = true;
      // }
    });
    builder.addCase(teamDeleteRequest.pending, (state, action) => {
      // if (!state.isLoading) {
      state.isLoading = true;
      // }
    });
    builder.addCase(teamUpdateRequest.pending, (state, action) => {
      // if (!state.isLoading) {
      state.isLoading = true;
      // }
    });

    builder.addCase(allTeamRequest.fulfilled, (state, action) => {
      // if (state.isLoading ) {
      state.data = action.payload;
      state.updateIsLoading = false;
      // }
    });
    builder.addCase(teamAddRequest.fulfilled, (state, action) => {
      // if (state.isLoading ) {
      state.data = action.payload;
      state.updateIsLoading = false;
      // }
    });

    builder.addCase(teamDeleteRequest.fulfilled, (state, action) => {
      // if (state.isLoading ) {
      state.data = action.payload;
      state.updateIsLoading = false;
      // }
    });
    builder.addCase(teamUpdateRequest.fulfilled, (state, action) => {
      // if (state.isLoading ) {
      state.data = action.payload;
      state.updateIsLoading = false;
      // }
    });
    builder.addCase(allTeamRequest.rejected, (state, action) => {
      // if (state.isLoading ) {
      state.isLoading = false;
      state.error = action.payload;
      // }
    });
    builder.addCase(teamAddRequest.rejected, (state, action) => {
      // if (state.isLoading ) {
      state.isLoading = false;
      state.error = action.payload;
      // }
    });
    builder.addCase(teamDeleteRequest.rejected, (state, action) => {
      // if (state.isLoading ) {
      state.isLoading = false;
      state.error = action.payload;
      // }
    });
    builder.addCase(teamUpdateRequest.rejected, (state, action) => {
      // if (state.isLoading ) {
      state.isLoading = false;
      state.error = action.payload;
      // }
    });
  },
});
export default teamSlice.reducer;
