import React, { useEffect, useRef, useState } from "react";
import Header from "../../../reuseable/header";
import profile from "../../../assets/homeIcons/Frame 1000003239.svg";
import "react-phone-number-input/style.css";
import flags from "react-phone-number-input/flags";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import toast from "react-hot-toast";
import { profileInfoRequest } from "../../../redux/features/profile/profileSlice";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MainLoader } from "../../../components/utils";

const ProfileSetUp = () => {
  const isMounted = useRef(false);
  const teamDetails = JSON.parse(localStorage.getItem("tm"));
  const [profile_img, setProfile_img] = useState(null);
  const [firstName, setFirstName] = useState(teamDetails?.name.split(" ")[0]);
  const [lastName, setLastName] = useState(teamDetails?.name.split(" ")[1]);
  const [businessName, setBusinessName] = useState(
    teamDetails?.name.split(" ")
  );
  const [phoneNumber, setPhoneNumber] = useState(
    teamDetails?.phoneNumber || ""
  );
  const [country, setCountry] = useState(teamDetails?.country);
  const [businessEmail, setBusinessEmail] = useState(
    teamDetails?.name.split(" ")
  );
  const [loading, setLoading] = useState(false);

  const profileState = useSelector((state) => state.profileReducer);

  let dispatch = useDispatch();
  const location = useLocation();
  const { state } = location;
  let orgId = Cookies.get("orgId") || "";

  useEffect(() => {
    if (isMounted.current) return;
    isMounted.current = true;
    getProfileInfo();
  }, []);

  const handlePhoneNumberChange = (value) => {
    setPhoneNumber(value);
  };

  let getProfileInfo = () => {
    setLoading(true);
    dispatch(profileInfoRequest())
      .then((res) => {
        setLoading(false);
        if (res?.payload?.status === 200) {
          setFirstName(res?.payload?.data?.contact_first_name);
          setLastName(res?.payload?.data?.contact_last_name);
          setBusinessName(res?.payload?.data?.name);
          setPhoneNumber(res?.payload?.data?.contact_phone);
          setBusinessEmail(res?.payload?.data?.contact_email);
          setProfile_img(res?.payload?.data?.logo);
          setCountry(res?.payload?.data?.country);
        } else {
          toast.error(res?.payload?.data?.response);
        }
      })
      .catch((err) => {});
  };

  return (
    <div>
      {loading ? (
        <MainLoader />
      ) : (
        <div className="flex justify-center items-center ">
          <div className="profileD">
            <div className="flex justify-between items-center">
              <div className=" flex flex-col justify-center items-center">
                <img src={profile_img || profile} alt="profilepic" />
                <h4>{businessName}</h4>
                <p>Lagos, {country}</p>
              </div>
              <div>
                <Link
                  style={{ textDecoration: "none" }}
                  to={"/edit-profile"}
                  state={{ ...state, teamDetails }}
                >
                  <button className="profileCardButton">Edit Profile</button>
                </Link>
              </div>
            </div>
            <div className="businessInfo">
              <div className="w-[25%]">
                <h6>Business information</h6>
                <p>
                  Take control of your account with our easy to use settings.
                </p>
              </div>
              <div className="profileInput w-[75%] ">
                <div className="">
                  <label htmlFor="First Name">First Name</label>
                  <input
                    type="text"
                    value={firstName}
                    disabled
                    className="form-control mt-2"
                    placeholder="John"
                  />
                </div>
                <div>
                  <label htmlFor="Last Name">Last Name</label>
                  <input
                    value={lastName}
                    type="text"
                    className="form-control mt-2"
                    placeholder="Doe"
                    disabled
                  />
                </div>
                <div>
                  <label htmlFor="Business Name">Business Name</label>
                  <input
                    value={businessName}
                    type="text"
                    className="form-control mt-2"
                    disabled
                    placeholder="Venixs"
                  />
                </div>
                <div>
                  {" "}
                  <label htmlFor="Business Email">Business Email</label>
                  <input
                    value={businessEmail}
                    type="email"
                    className="form-control mt-2"
                    disabled
                    placeholder="John@company.com"
                  />
                </div>
              </div>
            </div>
            <div className="businessInfo">
              <div className="w-[25%]">
                <h6>Profile information</h6>
                <p>
                  Take control of your account with our easy to use settings.
                </p>
              </div>
              <div className="businessInput w-[75%]">
                <div>
                  <label htmlFor="businessPhone">Business Phone Number</label>
                  <div className=" ">
                    <PhoneInputWithCountrySelect
                      className="phoneInput mt-2"
                      type="tel"
                      defaultCountry={country}
                      disabled
                      value={phoneNumber}
                      onChange={handlePhoneNumberChange}
                      placeholder="08012345678"
                      countrySelectProps={{
                        showFlags: true, // Show country flags
                      }}
                    />
                  </div>
                </div>
                <div className="flex justify-between mt-8 gap-4">
                  <div className="w-full">
                    <label htmlFor="country">Country</label>
                    <input
                      className="mt-2 w-full"
                      value={country}
                      disabled
                      placeholder="Lagos,Nigeria"
                      type="text"
                    />
                  </div>
                  <div className="w-full">
                    <label htmlFor="password">Password</label>
                    <input
                      className="mt-2 w-full"
                      disabled
                      placeholder="************"
                      type="password"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileSetUp;
