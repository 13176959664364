import React from "react";
import Header from "../../reuseable/header";
import { Link, useLocation } from "react-router-dom";
import user from "../../assets/gettingstarted/profile_llustration-removebg-preview 1.svg";
import widgetPic from "../../assets/gettingstarted/Mobile app development and mobile user interface.svg";
import configurationPic from "../../assets/gettingstarted/gear.svg";
import linkPic from "../../assets/gettingstarted/four colored puzzles.svg";
import { BsArrowRight } from "react-icons/bs";
import Card from "../../reuseable/card";

const HomeDashboard = () => {
  const location = useLocation();
  const { state } = location;
  return (
    <div>
      <div className="flex justify-center ml-8">
        <div>
          <h3 className="welcomeTitle !font-PJ-semibold">
            Get Started With Venixs
          </h3>
          <p className="welcomeMessage font-regularInter">
            Welcome to Venixs! Let’s get you started with venixs, it easy and
            only takes few minutes{" "}
          </p>
          <Card
            paragraph={"Finish Setting Up Profile"}
            button={"Set Up Profile"}
            cardPics={<img src={user} alt="user" />}
            to="/profiles"
            state={{ ...state }}
            descParagraph={
              " Finalize your profile to streamline your customer support experience. By selecting “Set up Profile” you will be able to Personalize your profile for seamless assistance and a tailored support journey."
            }
          />
          <Card
            paragraph={"Set up chat widget"}
            button={"Set Up Widget"}
            cardPics={<img src={widgetPic} alt="widget" />}
            to={"/configuration/general-configuration"}
            state={{ ...state }}
            descParagraph={
              " Elevate your customer support with a quick and easy setup of the chat widget. Connect instantly and provide top-notch assistance to your customers. Select ‘Set up Widget” to start"
            }
          />
          <Card
            paragraph={"Data Configuration"}
            button={"Configure data"}
            state={{ ...state }}
            cardPics={<img src={configurationPic} alt="configuration" />}
            to={"/configuration/data-configuration"}
            descParagraph={
              " Optimize your customer service with seamless data configuration. Set the stage for efficient support and better insights. Select “Configure data” to commence"
            }
          />
          <Card
            paragraph={"Link Escalation channel"}
            button={"Link channel"}
            cardPics={<img src={linkPic} alt="puzzle" sizes="32" />}
            to={"/configuration/escalation-configuration"}
            state={{ ...state }}
            descParagraph={
              "  Enhance customer service by seamlessly connecting escalation channels. Select “Link Channel” and strengthen your support network for quicker resolutions and superior customer satisfaction."
            }
          />
        </div>
      </div>
    </div>
  );
};

export default HomeDashboard;
