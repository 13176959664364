import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { loginRequest } from "../../../redux/features/auth/authSlice";
import Cookies from "js-cookie";
import global from "../../../redux/constants/global";
import toast from "react-hot-toast";
import logo from "../../../assets/logo-text.png";
import { FaSpinner } from "react-icons/fa";
import AuthHeader from "../../../reuseable/authHeader";

export default function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [viewPassword, setViewPassword] = useState(false);

  const authState = useSelector((state) => state.authReducer);

  let dispatch = useDispatch();
  let authenticated = Cookies.get("authenticated") || "";

  let email_reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,15})+$/;

  let checkEmail = () => {
    if (!email) {
      setEmailError("Email cannot be blank");
      return;
    }
    if (!email_reg.test(email)) {
      setEmailError("Invalid Email Address");
    } else {
      setEmailError("");
    }
  };
  let checkPassword = () => {
    if (!password) {
      setPasswordError("Password cannot be blank");
    } else {
      setPasswordError("");
    }
  };

  useEffect(() => {
    if (authenticated === "1") {
      window.location.href = global.appBaseUrl;
    }
  }, []);

  let attemptlogin = () => {
    let data = {
      username: email,
      password: password,
      fcm_device_token: localStorage.getItem("pm"),
    };
    console.log(data);

    if (!email) {
      toast.error("Email cannot be blank");
      return;
    }
    if (!email_reg.test(email)) {
      setEmailError("Invalid Email Address");
      return;
    }
    if (!password) {
      toast.error("Password cannot be blank");
      return;
    }

    dispatch(loginRequest(data))
      .then((res) => {
        console.log(res);
        if (res?.payload?.status === 200) {
          Cookies.set("authenticated", "1");
          Cookies.set("babtbu", res?.payload?.data?.token?.access_token);
          Cookies.set(
            "orgId",
            res?.payload?.data?.organization?.organisation_id
          );
          localStorage.setItem(
            "tm",
            JSON.stringify(res?.payload?.data?.team_data)
          );
          window.location.href = global.appBaseUrl + "home";
        } else {
          toast.error(res?.payload?.data?.response);
        }
        console.log(res.payload.data);
      })
      .catch((err) => {});
  };

  return (
    <div className="h-screen relative">
      <AuthHeader />
      <div className="auth-page-area flex justify-center items-center">
        <div className="container mx-auto">
          <div className="flex flex-col items-center">
            <div className="w-full text-center max-w-xl py-2">
              <h4 className="font-PJ-bold font-medium text-[32px] leading-[40.32px] ">
                Welcome back
              </h4>
              <p className="font-regularInter font-normal text-base leading-[22px]">
                Input the necessary details{" "}
              </p>
            </div>

            <div className="px-10 font-PJ-medium font-medium mt-5 w-full max-w-xl">
              <div>
                <label className="font-PJ-medium font-medium" htmlFor="email">
                  Email Address
                </label>{" "}
                <br />
                <input
                  type="email"
                  value={email}
                  required
                  className={`w-full border px-4 mt-2 ${
                    emailError ? "input-error" : ""
                  }`}
                  onBlur={checkEmail}
                  onChange={(email) =>
                    setEmail(email.target.value.toLowerCase())
                  }
                  placeholder="name@mail.com"
                />
                {emailError && (
                  <p style={{ color: "red" }} className="p-0 m-0">
                    {emailError}
                  </p>
                )}
              </div>
              <div className="relative font-PJ-medium font-medium mt-6">
                <label
                  className="font-PJ-medium font-medium"
                  htmlFor="password"
                >
                  Password
                </label>{" "}
                <br />
                <div className="input-group relative">
                  <input
                    type={!viewPassword ? "password" : "text"}
                    value={password}
                    className={`w-full border px-4 pr-12 py-2 mt-2 ${
                      passwordError ? "input-error" : ""
                    }`}
                    onBlur={checkPassword}
                    onChange={(password) => setPassword(password.target.value)}
                    placeholder="*********"
                  />
                  <div className="absolute top-0 right-0 mt-[18px] mr-4">
                    {!viewPassword ? (
                      <i
                        className="ri-eye-line ri-lg cursor-pointer"
                        onClick={() => setViewPassword(true)}
                      />
                    ) : (
                      <i
                        className="ri-eye-off-line ri-lg cursor-pointer"
                        onClick={() => setViewPassword(false)}
                      />
                    )}
                  </div>
                </div>
                {passwordError && (
                  <p style={{ color: "red" }} className="p-0 m-0">
                    {passwordError}
                  </p>
                )}
              </div>

              <p className=" text-end mt-4">
                <Link to="/forgotPassword" className="link link-underline ms-2">
                  Reset password
                </Link>
              </p>

              <button
                className="bg-[#101010] text-[#E5C9FF] font-medium font-inter text-base rounded-lg w-full py-3 mt-5"
                onClick={attemptlogin}
              >
                {authState?.isLoading ? (
                  <div className="flex justify-center items-center">
                    <FaSpinner className="animate-spin" />

                    <span className="text-white text-sm ml-2"> Loading...</span>
                  </div>
                ) : (
                  "Continue"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
