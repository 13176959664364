import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import Sidebar from "./components/appWrapper/sidebar";
import LoginPage from "./pages/auth/login";
import ResetPasswordPage from "./pages/auth/resetPassword/reset.jsx";
import SignUpPage from "./pages/auth/signUp";
import ConfigurationsPage from "./pages/configurations";
import CustomersPage from "./pages/customers";
import TeamsPage from "./pages/teams";
import DashboardPage from "./pages/dashboard";
import NotificationsPage from "./pages/notifications";
import ProfilePage from "./pages/profile";
import SubscriptionPage from "./pages/subscription";
import MessagesPage from "./pages/messages";
import VerifyInvitedMemberPage from "./pages/verify";
import TicketingPage from "./pages/ticketing";
import BroadcastPage from "./pages/broadcast";
import HomeDashboard from "./pages/home";
import ProfileSetUp from "./pages/home/profiles";
import BroadcastComposePage from "./pages/broadcast/broadcastComposePage";
import WidgetAppearance from "./pages/home/widget-setup/index.jsx";
import EditProfile from "./pages/home/profiles/editProfile.jsx";
import ProfileData from "./pages/home/profiles/ProfileData.jsx";
import AppWrapperComp from "./components/appWrapper/index.jsx";
import GeneralConfigComp from "./components/configurations/generalConfig/index.jsx";
import DataConfigComp from "./components/configurations/dataConfig/index.jsx";
import SignUpPages from "./pages/auth/signups/index.jsx";
import IntegrationConfigComp from "./components/configurations/integrationConfig/index.jsx";
import EscalationConfigComp from "./components/configurations/escalationConfig/index.jsx";
import MarketPlace from "./pages/marketPlace/index.jsx";
import SettingsPage from "./pages/settings/index.jsx";
import ForgotPasswordPage from "./pages/auth/resetPassword/index.jsx";
import ClarityScript from "./components/ClarityScripts/index.jsx";
import { useEffect } from "react";
// import { getToken, onMessage } from "firebase/messaging";
// import { messaging } from "./firebase-config.js";
import { generateToken, messaging } from "./notifications/firebase.js";
import { onMessage } from "firebase/messaging";
import FAQ from "./pages/faq/faq.jsx";
import Faq from "./pages/faq/faq.jsx";
import FaqComposePage from "./pages/faq/FaqComposePage.jsx";

function App() {
  const location = useLocation();
  const { pathname } = location;

  const splitLocation = pathname.split("/");

  useEffect(() => {
    generateToken();
    onMessage(messaging, (payload) => {
      console.log("Message received. ", payload);
    });
  });

  // useEffect(() => {
  //   const requestPermission = async () => {
  //     try {
  //       const permission = await Notification.requestPermission();
  //       if (permission === "granted") {
  //         console.log("Notification permission granted.");
  //         const token = await getToken(messaging, {
  //           vapidKey:
  //             "BNOgd8aV9DKH7tBMC05jGPe39olfmm_Bhra_g5p9A76yBsyf8LruyeOOGAQiE63rgqP2s_suVHik6crolI2uluw",
  //         });
  //         if (token) {
  //           console.log("FCM Token:", token);
  //           // Send the token to your server or save it in your database
  //         } else {
  //           console.log(
  //             "No registration token available. Request permission to generate one."
  //           );
  //         }
  //       } else {
  //         console.log("Unable to get permission to notify.");
  //       }
  //     } catch (err) {
  //       console.error("An error occurred while retrieving token. ", err);
  //     }
  //   };

  //   requestPermission();

  //   // Handle incoming messages
  //   onMessage(messaging, (payload) => {
  //     console.log("Message received. ", payload);
  //     // Customize notification here
  //     new Notification(payload.notification.title, {
  //       body: payload.notification.body,
  //       icon: payload.notification.icon,
  //     });
  //   });
  // }, []);

  return (
    <>
      <ClarityScript />
      <div className="absolute w-screen h-screen top-0 left-0 bg-white z-[9999] flex flex-col lg:hidden items-center justify-center">
        <h1 className="text-3xl text-center font-PJ-bold font-black">
          Screen size not supported
        </h1>
        <p className="font-bold text-center font-inter">
          Kindly access this website with your Desktop/Tablet
        </p>
      </div>
      <div>
        {["login", "signUp", "forgotPassword", "reset", "verify"].includes(
          splitLocation[1]
        ) ? (
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/signUp" element={<SignUpPages />} />
            <Route path="/forgotPassword" element={<ForgotPasswordPage />} />
            <Route path="/reset/:token" element={<ResetPasswordPage />} />
            <Route path="/verify/:id" element={<VerifyInvitedMemberPage />} />
          </Routes>
        ) : (
          <div className="pages hidden lg:block">
            <div className="xl:flex mb-5 xl:mb-0">
              <AppWrapperComp headerTitle={splitLocation[1]}>
                <div className="container-fluid h-full pb-4 overflow-y-auto xl:pb-0 px-4 mt-5 pt-5 xl:pt-0 xl:mt-0">
                  <Routes>
                    <Route path="/marketPlace" element={<MarketPlace />} />
                    <Route
                      path="/"
                      element={<Navigate to="/home/dashboard" />}
                    />
                    <Route
                      path="/home"
                      element={<Navigate to="/home/getting-started" />}
                    />
                    <Route path="/home/dashboard" element={<DashboardPage />} />
                    <Route
                      path="/configuration"
                      element={
                        <Navigate to="/configuration/general-configuration" />
                      }
                    />
                    <Route
                      path="/configuration/data-configuration"
                      element={<DataConfigComp />}
                    />
                    <Route
                      path="/configuration/developers-integration-configuration"
                      element={<IntegrationConfigComp />}
                    />
                    <Route
                      path="/configuration/general-configuration"
                      element={<GeneralConfigComp />}
                    />
                    <Route
                      path="/configuration/escalation-configuration"
                      element={<EscalationConfigComp />}
                    />
                    <Route path="/messages" element={<MessagesPage />} />
                    <Route path="/ticketing" element={<TicketingPage />} />
                    <Route
                      path="/customers/all-customers"
                      element={<CustomersPage />}
                    />
                    <Route
                      path="/home/getting-started"
                      element={<HomeDashboard />}
                    />
                    <Route path="/teams" element={<TeamsPage />} />
                    <Route
                      path="/subscription"
                      element={<SubscriptionPage />}
                    />
                    <Route path="/broadcast" element={<BroadcastPage />} />
                    <Route path="/profiles" element={<ProfileSetUp />} />
                    <Route path="/edit-profile" element={<EditProfile />} />
                    <Route path="/complete-profile" element={<ProfileData />} />
                    <Route path="/widget" element={<WidgetAppearance />} />
                    <Route
                      path="/broadcast/compose"
                      element={<BroadcastComposePage />}
                    />
                    <Route path="/faq/compose" element={<FaqComposePage />} />
                    <Route
                      path="/notifications/all-notifications"
                      element={<NotificationsPage />}
                    />
                    <Route path="/faq" element={<Faq />} />
                    <Route path="/profile" element={<ProfilePage />} />
                    <Route path="/settings" element={<SettingsPage />} />
                    <Route path="*" element={<Navigate to="/" replace />} />
                  </Routes>
                </div>
              </AppWrapperComp>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default App;
