import { EmptyStateComp } from "../../components/utils";

import React from "react";

const MarketPlace = () => {
  return (
    <div className=" text-center flex flex-col items-center justify-center mt-[10%]  ">
      <div className="">
        <EmptyStateComp />
      </div>
      <div className=" font-PJ-bold text-lg ">COMING SOON</div>
    </div>
  );
};

export default MarketPlace;
