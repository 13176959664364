import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import profile from "../../assets/profile.svg";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { FaChevronRight } from "react-icons/fa";
import { authorizationRedirect } from "../../redux/constants/api";
import { RiNotification2Line } from "react-icons/ri";
import { BsDot } from "react-icons/bs";
import { allNotificationsRequest } from "../../redux/features/notifications/notificationSlice";

const ProfileLink = () => {
  const orgId = Cookies.get("orgId") || "";
  const location = useLocation();
  const { pathname } = location;
  const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState(false);
  const [isNotificationDropdownOpen, setIsNotificationDropdownOpen] =
    useState(false);
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();
  const profileState = useSelector((state) => state.profileReducer);
  const notificationState = useSelector((state) => state.notificationReducer);

  // Fetch notifications on component mount
  useEffect(() => {
    dispatch(allNotificationsRequest());
  }, [dispatch]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsProfileDropdownOpen(false);
        setIsNotificationDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleProfileToggle = () => {
    setIsProfileDropdownOpen(!isProfileDropdownOpen);
    if (isNotificationDropdownOpen) {
      setIsNotificationDropdownOpen(false);
    }
  };

  const handleNotificationToggle = () => {
    setIsNotificationDropdownOpen(!isNotificationDropdownOpen);
    if (isProfileDropdownOpen) {
      setIsProfileDropdownOpen(false);
    }
  };

  const logOut = () => {
    authorizationRedirect();
  };

  // Limit notifications to the latest 10
  const notificationsToShow =
    notificationState?.allNotifs?.data?.slice(-5) || [];

  return (
    <div className="relative inline-block text-left" ref={dropdownRef}>
      <div className="profileLink">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          <div className="relative">
            {notificationsToShow.length > 0 && (
              <span className="absolute top-0 right-0 w-3 h-3 bg-red-500 rounded-full"></span>
            )}
            <RiNotification2Line
              className="w-6 h-6 text-[#434A60] cursor-pointer"
              onClick={handleNotificationToggle}
            />
          </div>
          <div className="flex items-center" onClick={handleProfileToggle}>
            <div className="h-20 w-20 mt-5 rounded-lg overflow-hidden cursor-pointer">
              <img
                src={
                  profileState?.profileInfo?.data?.logo === null
                    ? profile
                    : profileState?.profileInfo?.data?.logo
                }
                className="max-w-full h-auto"
                alt="profile"
              />
            </div>
            <div className="flex gap-3">
              {profileState?.profileInfo?.data?.name}
              <div className="mt-1">
                <FaChevronRight />
              </div>
            </div>
          </div>
        </div>
      </div>

      {isNotificationDropdownOpen && (
        <div
          className="absolute right-0 z-10 mt-2 w-64 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex="-1"
        >
          <div className="py-1" role="none">
            {notificationsToShow.length > 0 ? (
              notificationsToShow.map((notification) => (
                <div
                  key={notification.id}
                  className="text-gray-700 truncate text-md block px-4 py-2 border-b active:border-l-4  last:border-b-0"
                >
                  {notification.message}
                </div>
              ))
            ) : (
              <div className="text-gray-700 text-md block px-4 py-2">
                No notifications
              </div>
            )}
          </div>
        </div>
      )}

      {isProfileDropdownOpen && (
        <div
          className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex="-1"
        >
          <div className="py-1" role="none">
            <a
              href="/profiles"
              className="text-gray-700 text-md block px-4 py-2"
              role="menuitem"
              tabIndex="-1"
              id="menu-item-0"
            >
              <i className="ri-user-line mr-3"></i>
              Profile
            </a>
            <button
              onClick={logOut}
              className="text-red-700 text-md block px-4 py-2"
              role="menuitem"
              tabIndex="-1"
              id="menu-item-1"
            >
              <i className="ri-shut-down-line mr-3"></i>
              Logout
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileLink;
