import React from "react";
import headerLogo from "../../assets/Venixs 2 1 (1).svg";
import { Link, useLocation } from "react-router-dom";

const AuthHeader = () => {
  const location = useLocation();
  const pathname = location.pathname;
  return (
    <div>
      <div className="bg-black text-white flex justify-between items-center p-4  ">
        <img src={headerLogo} alt="Venixs Logo" />
        <div className="flex items-center gap-[18px] ">
          <p className=" font-medium font-inter text-sm">
            {pathname === "/login"
              ? "Don't have an account?"
              : "Already have an account?"}
          </p>
          <button className="">
            {pathname === "/login" ? (
              <Link
                className="border rounded-lg py-3 px-[20px] font-medium font-inter text-sm"
                to="/signUp"
              >
                {" "}
                Sign Up
              </Link>
            ) : (
              <Link
                className="border rounded-lg py-3 px-[20px] font-medium font-inter text-sm"
                to="/login"
              >
                {" "}
                Sign In
              </Link>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AuthHeader;
