import React from "react";

const CardSkeleton = () => {
  return (
    <div className="w-full mb-3">
      <div className=" border rounded-xl h-[111px] justify-between py-7 px-3">
        <div className="flex items-center justify-between">
          <div className="left-1 flex items-start justify-between">
            <span className="skeleton-avatar px-2 py-2 mt-1 border rounded mr-3"></span>
            <div className="skeleton-text">
              <small className="skeleton-small text-gray-500 font-PJ-semibold text-[12px] mb-0"></small>
              <h2 className="skeleton-title text-[26px] font-PJ-bold"></h2>
            </div>
          </div>
          <div className="right-1">
            <span className="skeleton-tag bg-gray-100 px-2 py-1 rounded-full text-gray-800 border flex items-center"></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardSkeleton;
