import React, { useState, useEffect, useRef } from "react";
import NotificationSettings from "./notifications";

import { useSearchParams } from "react-router-dom";
import ProfileSetUp from "../home/profiles/editProfile";
import ForgotPasswordPage from "../auth/resetPassword";

const SettingsPage = () => {
  const isMounted = useRef(false);
  const [activePage, setActivePage] = useState("profile");
  const [searchParams, setSearchParams] = useSearchParams();

  const subPages = ["profile", "notification", "change Password"];

  useEffect(() => {
    if (!isMounted.current) return;
    isMounted.current = true;
    if (!searchParams.size)
      setSearchParams(() => {
        const param = { tab: subPages[0] };
        handleClick(param.tab);
        return param;
      });
  }, []);

  const handleClick = (page) => {
    setActivePage(page);
    setSearchParams({
      tab: page,
    });
  };

  return (
    <div className="!w-full  ">
      <div className="flex   items-center mb-4 mt-4  gap-4">
        <p
          className={`cursor-pointer ${
            activePage === "profile"
              ? "bg-light border-[#EAD6FF] text-primary rounded-[25px] text-inter text-sm leading-[20.3px] tracking-[-2%] px-5 py-2"
              : ""
          }`}
          onClick={() => handleClick("profile")}
        >
          Profile
        </p>
        <p
          className={`cursor-pointer ${
            activePage === "notification"
              ? "bg-light border-[#EAD6FF] text-primary rounded-[25px] text-inter text-sm leading-[20.3px] tracking-[-2%] px-5 py-2"
              : ""
          }`}
          onClick={() => handleClick("notification")}
        >
          Notification
        </p>
        <p
          className={`cursor-pointer ${
            activePage === "change Password"
              ? "bg-light border-[#EAD6FF] text-primary rounded-[25px] text-inter text-sm leading-[20.3px] tracking-[-2%] px-5 py-2"
              : ""
          }`}
          onClick={() => handleClick("change Password")}
        >
          Change Password
        </p>
      </div>
      <hr className="w-full" />

      <div>
        {activePage === "profile" && <ProfileSetUp />}
        {activePage === "notification" && <NotificationSettings />}
        {activePage === "change Password" && <ForgotPasswordPage />}
      </div>
    </div>
  );
};

export default SettingsPage;
