import React, { useState } from "react";
import { allCountriesData } from "../../../components/utils/allCountries";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import "react-phone-number-input/style.css";

import toast from "react-hot-toast";

const SignUpStep1 = ({ onNext }) => {
  const handlePhoneNumberChange = (value) => {};
  const [companyName, setCompanyName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyCountry, setCompanyCountry] = useState("");
  const [companyPhone, setCompanyPhone] = useState("");

  const handleNext = () => {
    // Validate and proceed to next step
    if (!companyName) {
      toast.error("Company name cannot be blank");
      return;
    }
    if (!firstName) {
      toast.error("First name cannot be blank");
      return;
    }
    if (!lastName) {
      toast.error("Last name cannot be blank");
      return;
    }
    if (!companyCountry) {
      toast.error("Company country cannot be blank");
      return;
    }
    if (!companyPhone) {
      toast.error("Company phone cannot be blank");
      return;
    }

    if (
      companyName &&
      companyCountry &&
      companyPhone &&
      lastName &&
      firstName
    ) {
      onNext();
      const data = {
        companyName: companyName,
        companyCountry: companyCountry,
        companyPhone: companyPhone,
        lastName: lastName,
        firstName: firstName,
      };
      localStorage.setItem("sd", JSON.stringify(data));
    }
  };

  return (
    <div className="flex justify-center items-center">
      <div className="w-full  py-2">
        <div className="text-center">
          <p className="font-PJ-medium font-medium text-[32px] leading-[40.32px] ">
            Create an Account
          </p>
          <p className="font-inter font-normal text-base leading-[22px]">
            Please input your details
          </p>
        </div>
        <div className="">
          <div className="">
            <label htmlFor="busName" className="block mb-2">
              Company Name
            </label>
            <input
              type="text"
              id="busName"
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-green-500"
              onChange={(e) => setCompanyName(e.target.value)}
            />
          </div>
          <div className="">
            <label htmlFor="busName" className="block mb-2">
              First Name
            </label>
            <input
              type="text"
              id="busName"
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-green-500"
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
          <div className="">
            <label htmlFor="busName" className="block mb-2">
              Last Name
            </label>
            <input
              type="text"
              id="busName"
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-green-500"
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
          <div className="">
            <label htmlFor="busCountry" className="block mb-2">
              Company Country
            </label>
            <select
              id="busCountry"
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-green-500"
              onChange={(e) => setCompanyCountry(e.target.value)}
            >
              <option value=""> Select Country</option>
              {allCountriesData?.map((val) => (
                <option key={val.code} value={val.name}>
                  {" "}
                  {val.name}
                </option>
              ))}
            </select>
          </div>

          <div className="">
            <label htmlFor="busPhone" className=" block mb-2">
              Company Phone Number
            </label>
            <div className=" ">
              <PhoneInputWithCountrySelect
                className="phoneInput mt-2 w-full px-3  border border-gray-300 rounded-lg focus:outline-none focus:border-green-500"
                type="tel"
                defaultCountry="NG"
                onChange={(value) => setCompanyPhone(value)}
                placeholder="+234 00 000 0000"
                countrySelectProps={{
                  showFlags: true,
                }}
              />
            </div>
          </div>
          <button
            className="bg-[#101010] text-[#E5C9FF] rounded-lg w-full py-3 mt-5 font-inter font-medium leading-[19.36px] text-base "
            onClick={handleNext}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default SignUpStep1;
