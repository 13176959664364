// CustomDropdown.js
import React, { useState, useEffect } from "react";
import { FiChevronDown } from "react-icons/fi";

const CustomDropdown = ({
  options,
  selectedOption,
  onChange,
  placeholder,
  className,
  defaultValue,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentSelectedOption, setCurrentSelectedOption] = useState(
    defaultValue || selectedOption
  );
  useEffect(() => {
    // Update the currentSelectedOption when selectedOption changes
    setCurrentSelectedOption(selectedOption);
  }, [selectedOption]);

  const handleOptionClick = (option) => {
    setCurrentSelectedOption(option);
    onChange(option);
    setIsOpen(false);
  };

  return (
    <div className={`relative ${className}`}>
      <button
        className="flex items-center justify-between w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:border-primary"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>
          {/* Display the label of the currentSelectedOption */}
          {currentSelectedOption ? currentSelectedOption.label : placeholder}
        </span>
        <FiChevronDown className="ml-2" />
      </button>
      {isOpen && (
        <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-lg shadow-lg">
          {options.map((option) => (
            <div
              key={option.value}
              className={`px-4 py-2 cursor-pointer capitalize hover:bg-gray-100 ${option === currentSelectedOption ? "bg-gray-200" : ""
                }`}
              onClick={() => handleOptionClick(option)}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
