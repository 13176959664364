import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";
import { authorizationRedirect, serverCodes } from "../../constants/api";
import global from "../../constants/global";

let accessT = Cookies.get("babtbu") || "";
let orgId = Cookies.get("orgId") || "";

export const allNotificationsRequest = createAsyncThunk(
  "notification/get",
  async () => {
    let config = {
      method: "get",
      url: `${global.apiBaseUrl}get/notifications?organization_id=${orgId}`,
      headers: {
        Authorization: `Bearer ${accessT}`,
        "Content-Type": "application/json",
      },
    };

    let resp = null;

    await axios
      .request(config)
      .then((response) => {
        resp = response;
      })
      .catch((error) => {
        if (
          serverCodes.includes(error?.request?.status) ||
          !error?.request?.status
        ) {
          resp = {
            data: {
              response: "Oops, Something went wrong, please try again later.",
            },
          };
          return;
        } else if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          authorizationRedirect();
        } else {
          resp = error?.response;
        }
      });
    return resp;
  }
);

export const notificationSlice = createSlice({
  name: "notifications",
  initialState: {
    isLoading: false,
    allNotifs: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(allNotificationsRequest.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(allNotificationsRequest.fulfilled, (state, action) => {
      state.allNotifs = action.payload;
      state.isLoading = false;
    });

    builder.addCase(allNotificationsRequest.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export default notificationSlice.reducer;
