import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";
import { serverCodes } from "../../constants/api";
import global from "../../constants/global";

export const loginRequest = createAsyncThunk("auth/login", async (data) => {
  //   const response = await axios.post(global.apiBaseUrl + 'auth/login',data)
  //   return response.data
  let config = {
    method: "post",
    url: global.apiBaseUrl + `company/login`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  let resp = null;

  await axios
    .request(config)
    .then((response) => {
      resp = response;
    })
    .catch((error) => {
      if (
        serverCodes.includes(error?.request?.status) ||
        !error?.request?.status
      ) {
        resp = {
          data: {
            response: "Oops, Something went wrong, please try again later.",
          },
        };
        return;
      } else {
        resp = error?.response;
      }
    });
  return resp;
});

export const signUpRequest = createAsyncThunk("auth/signUp", async (data) => {
  let config = {
    method: "post",
    url: global.apiBaseUrl + "company/create",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  let resp = null;

  await axios
    .request(config)
    .then((response) => {
      resp = response;
    })
    .catch((error) => {
      if (
        serverCodes.includes(error?.request?.status) ||
        !error?.request?.status
      ) {
        resp = {
          data: {
            response: "Oops, Something went wrong, please try again later.",
          },
        };
        return;
      } else {
        resp = error?.response;
      }
    });
  return resp;
});

export const otpConfirm = createAsyncThunk("auth/OTP", async (data) => {
  let config = {
    method: "post",
    url: global.apiBaseUrl + "company/activate",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  let resp = null;

  await axios
    .request(config)
    .then((response) => {
      resp = response;
    })
    .catch((error) => {
      if (
        serverCodes.includes(error?.request?.status) ||
        !error?.request?.status
      ) {
        resp = {
          data: {
            response: "Oops, Something went wrong, please try again later.",
          },
        };
        return;
      } else {
        resp = error?.response;
      }
    });
  return resp;
});

export const forgotPasswordRequest = createAsyncThunk(
  "auth/forgotPassword",
  async (data) => {
    let config = {
      method: "post",
      url: global.apiBaseUrl + "auth/forgot-password",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    let resp = null;

    await axios
      .request(config)
      .then((response) => {
        resp = response;
      })
      .catch((error) => {
        if (
          serverCodes.includes(error?.request?.status) ||
          !error?.request?.status
        ) {
          resp = {
            data: {
              response: "Oops, Something went wrong, please try again later.",
            },
          };
          return;
        } else {
          resp = error?.response;
        }
      });
    return resp;
  }
);

export const resetPasswordRequest = createAsyncThunk(
  "auth/resetPassword",
  async (data) => {
    let config = {
      method: "post",
      url: global.apiBaseUrl + "auth/reset-password",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    let resp = null;

    await axios
      .request(config)
      .then((response) => {
        resp = response;
      })
      .catch((error) => {
        if (
          serverCodes.includes(error?.request?.status) ||
          !error?.request?.status
        ) {
          resp = {
            data: {
              response: "Oops, Something went wrong, please try again later.",
            },
          };
          return;
        } else {
          resp = error?.response;
        }
      });
    return resp;
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    isLoading: false,
    data: null,
    signUpData: null,
    passwordData: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loginRequest.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(signUpRequest.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(forgotPasswordRequest.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(resetPasswordRequest.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(otpConfirm.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(signUpRequest.fulfilled, (state, action) => {
      state.signUpData = action.payload;
      state.isLoading = false;
    });
    builder.addCase(loginRequest.fulfilled, (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    });
    builder.addCase(forgotPasswordRequest.fulfilled, (state, action) => {
      state.passwordData = action.payload;
      state.isLoading = false;
    });
    builder.addCase(resetPasswordRequest.fulfilled, (state, action) => {
      state.passwordData = action.payload;
      state.isLoading = false;
    });
    builder.addCase(otpConfirm.fulfilled, (state, action) => {
      state.passwordData = action.payload;
      state.isLoading = false;
    });

    builder.addCase(signUpRequest.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
    builder.addCase(loginRequest.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
    builder.addCase(forgotPasswordRequest.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
    builder.addCase(resetPasswordRequest.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
    builder.addCase(otpConfirm.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});
export default authSlice.reducer;
