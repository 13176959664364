import React, { useEffect, useRef, useState } from "react";
import Header from "../../../reuseable/header";
import { Link, useLocation } from "react-router-dom";
import profile from "../../../assets/homeIcons/Frame 1000003239.svg";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { profileInfoRequest } from "../../../redux/features/profile/profileSlice";
import toast from "react-hot-toast";
import { MainLoader } from "../../../components/utils";

const ProfileData = () => {
  const isMounted = useRef(false);
  const location = useLocation();
  const { state } = location;
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(true);

  const teamDetails = JSON.parse(localStorage.getItem("tm"));
  const [profile_img, setProfile_img] = useState(null);
  const [firstName, setFirstName] = useState(teamDetails?.name.split(" ")[0]);
  const [lastName, setLastName] = useState(teamDetails?.name.split(" ")[1]);
  const [businessName, setBusinessName] = useState(
    teamDetails?.name.split(" ")
  );
  const [phoneNumber, setPhoneNumber] = useState(teamDetails?.name.split(" "));
  const [country, setCountry] = useState(teamDetails?.country);
  const [businessEmail, setBusinessEmail] = useState(
    teamDetails?.name.split(" ")
  );

  // const profileState = useSelector((state) => state.profileReducer);

  // console.log(profileState?.profileInfo?.data?.name);

  let dispatch = useDispatch();

  let orgId = Cookies.get("orgId") || "";
  // const navigate = useNavigate();

  useEffect(() => {
    if (isMounted.current) return;
    isMounted.current = true;
    getProfileInfo();
  }, []);
  const handlePhoneNumberChange = (value) => {};

  let getProfileInfo = () => {
    dispatch(profileInfoRequest())
      .then((res) => {
        if (res?.payload?.status === 200) {
          setFirstName(res?.payload?.data?.contact_first_name);
          setLastName(res?.payload?.data?.contact_last_name);
          setBusinessName(res?.payload?.data?.name);
          setPhoneNumber(res?.payload?.data?.contact_phone);
          setBusinessEmail(res?.payload?.data?.contact_email);
          setCountry(res?.payload?.data?.country);
          setProfile_img(res?.payload?.data?.logo);
          setLoading(false);
        } else {
          toast.error(res?.payload?.data?.response);
        }
      })
      .catch((err) => {});
  };
  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  return (
    <div className="p-[100px]">
      {loading ? (
        <MainLoader />
      ) : (
        <div className="">
          <div className="  ">
            <div>
              <div className="flex justify-between items-center ">
                <div className=" text-start">
                  <img
                    className="w-[80px] h-[80px] rounded-full "
                    src={profile}
                    alt="profilepic"
                  />
                  <div className="text-start">
                    <h4 className=" font-PJ-semibold font-semibold text-start text-2xl">
                      {businessName}
                    </h4>
                    <p className="font-inter font-medium text-start text-base ">
                      Lagos, {country}
                    </p>
                  </div>
                </div>
                <div>
                  <div>
                    <Link
                      style={{ textDecoration: "none" }}
                      state={{ ...state }}
                      to={"/edit-profile"}
                    >
                      <button className="profileCardButton">
                        Edit Profile
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
              {/* <div>
              <div className="">
                <h4 className=" font-PJ-semibold font-semibold text-2xl">
                  GovaHQ
                </h4>
                <p className="font-inter font-medium text-base ">
                  Lagos, Nigeria
                </p>
              </div>
              
            </div> */}
            </div>

            <div className="mt-[84px] border-b pb-6">
              <h4 className="font-inter text-xl leading-[30px] tracking-[-1.5%] text-primary ">
                Business Infomation
              </h4>
              <p className="font-PJ-light text-base leading-6">
                Take control of your account with our easy to use settings.
              </p>
            </div>

            <div className=" flex justify-between mt-6">
              <div>
                <p className="font-PJ-regular text-base leading-[20.16px] text-[#505050]">
                  {" "}
                  First Name
                </p>
                <h6 className="font-PJ-medium text-lg leading-[22.68px] text-[#000e24]">
                  {" "}
                  {firstName}
                </h6>
              </div>
              <div>
                <p className="font-PJ-regular text-base leading-[20.16px] text-[#505050]">
                  {" "}
                  Last Name
                </p>
                <h6 className="font-PJ-medium text-lg leading-[22.68px] text-[#000e24]">
                  {" "}
                  {lastName}
                </h6>
              </div>
              <div>
                <p className="font-PJ-regular text-base leading-[20.16px] text-[#505050]">
                  {" "}
                  Business Name
                </p>
                <h6 className="font-PJ-medium text-lg leading-[22.68px] text-[#000e24]">
                  {" "}
                  {businessName}
                </h6>
              </div>
              <div>
                <p className="font-PJ-regular text-base leading-[20.16px] text-[#505050]">
                  {" "}
                  Business Email
                </p>
                <h6 className="font-PJ-medium text-lg leading-[22.68px] text-[#000e24]">
                  {" "}
                  {businessEmail}
                </h6>
              </div>
            </div>
            <div className="mt-[96px]">
              <div className="border-b pb-6">
                <h4 className="font-inter text-xl leading-[30px] tracking-[-1.5%] text-primary ">
                  Profile Information
                </h4>
                <p className="font-PJ-light text-base leading-6">
                  Take control of your account with our easy to use settings.
                </p>
              </div>
              <div className="businessdata flex gap-11">
                <div>
                  <p className="font-PJ-regular text-base leading-[20.16px] text-[#505050]">
                    {" "}
                    Country
                  </p>
                  <h6 className="font-PJ-medium text-lg leading-[22.68px] text-[#000e24]">
                    {" "}
                    {country}
                  </h6>
                </div>

                <div>
                  <p className="font-PJ-regular text-base leading-[20.16px] text-[#505050]">
                    {" "}
                    Business Phone Number
                  </p>
                  <h6 className="font-PJ-medium text-lg leading-[22.68px] text-[#000e24]">
                    {" "}
                    {phoneNumber}
                  </h6>
                </div>

                {/* <div>
                <p className="font-PJ-regular text-base leading-[20.16px] text-[#505050]">
                  {" "}
                  Password
                </p>
                <div
                  className=" font-PJ-medium text-lg leading-[22.68px] text-[#000e24]"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <h6 style={{ marginRight: "8px" }}>
                    {showPassword ? "YourPasswordHere" : "************"}
                  </h6>
                  {showPassword ? (
                    <FaEye onClick={togglePasswordVisibility} />
                  ) : (
                    <FaEyeSlash onClick={togglePasswordVisibility} />
                  )}
                </div>
              </div> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileData;
