import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";
import { authorizationRedirect, serverCodes } from "../../constants/api";
import global from "../../constants/global";

let accessT = Cookies.get("babtbu") || "";
let orgId = Cookies.get("orgId") || "";

export const allConversationRequest = createAsyncThunk(
  "conversations/allConversation",
  async () => {
    let config = {
      method: "post",
      url: `${global.apiBaseUrl}get-all-session`,
      headers: {
        Authorization: `Bearer ${accessT}`,
        "Content-Type": "application/json",
      },
      data: {
        organisation_id: orgId,
      },
    };

    let resp = null;

    await axios
      .request(config)
      .then((response) => {
        resp = response;
      })
      .catch((error) => {
        if (
          serverCodes.includes(error?.request?.status) ||
          !error?.request?.status
        ) {
          resp = {
            data: {
              response: "Oops, Something went wrong, please try again later.",
            },
          };
          return;
        } else if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          authorizationRedirect();
        } else {
          resp = error?.response;
        }
      });
    return resp;
  }
);

export const singleConversationRequest = createAsyncThunk(
  "conversations/singleConversation",
  async (data) => {
    let config = {
      method: "post",
      url: `${global.apiBaseUrl}get-single-session`,
      headers: {
        Authorization: `Bearer ${accessT}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    let resp = null;

    await axios
      .request(config)
      .then((response) => {
        resp = response;
      })
      .catch((error) => {
        if (
          serverCodes.includes(error?.request?.status) ||
          !error?.request?.status
        ) {
          resp = {
            data: {
              response: "Oops, Something went wrong, please try again later.",
            },
          };
          return;
        } else if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          authorizationRedirect();
        } else {
          resp = error?.response;
        }
      });
    return resp;
  }
);

export const closeConversationRequest = createAsyncThunk(
  "conversations/closeConversation",
  async (data) => {
    let config = {
      method: "post",
      url: `${global.apiBaseUrl}close-session`,
      headers: {
        Authorization: `Bearer ${accessT}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    let resp = null;

    await axios
      .request(config)
      .then((response) => {
        resp = response;
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          authorizationRedirect();
        } else {
          if (
            serverCodes.includes(error?.request?.status) ||
            !error?.request?.status
          ) {
            resp = {
              data: {
                response: "Oops, Something went wrong, please try again later.",
              },
            };
            return;
          } else if (
            error?.response?.status === 401 ||
            error?.response?.status === 403
          ) {
            authorizationRedirect();
          } else {
            resp = error?.response;
          }
        }
      });
    return resp;
  }
);

export const reopenConversationRequest = createAsyncThunk(
  "conversations/reopenConversation",
  async (data) => {
    let config = {
      method: "post",
      url: `${global.apiBaseUrl}reopen-session`,
      headers: {
        Authorization: `Bearer ${accessT}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    let resp = null;

    await axios
      .request(config)
      .then((response) => {
        resp = response;
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          authorizationRedirect();
        } else {
          if (
            serverCodes.includes(error?.request?.status) ||
            !error?.request?.status
          ) {
            resp = {
              data: {
                response: "Oops, Something went wrong, please try again later.",
              },
            };
            return;
          } else if (
            error?.response?.status === 401 ||
            error?.response?.status === 403
          ) {
            authorizationRedirect();
          } else {
            resp = error?.response;
          }
        }
      });
    return resp;
  }
);

export const switchConvoHandlerRequest = createAsyncThunk(
  "conversations/switchConvoHandler",
  async (data) => {
    let config = {
      method: "post",
      url: `${global.apiBaseUrl}session/stop-bot`,
      headers: {
        Authorization: `Bearer ${accessT}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    let resp = null;

    await axios
      .request(config)
      .then((response) => {
        resp = response;
      })
      .catch((error) => {
        if (
          serverCodes.includes(error?.request?.status) ||
          !error?.request?.status
        ) {
          resp = {
            data: {
              response: "Oops, Something went wrong, please try again later.",
            },
          };
          return;
        } else if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          authorizationRedirect();
        } else {
          resp = error?.response;
        }
      });
    return resp;
  }
);

export const sendManualMessageRequest = createAsyncThunk(
  "conversations/sendManualMessage",
  async (data) => {
    let config = {
      method: "post",
      url: `${global.apiBaseUrl}send-message`,
      headers: {
        Authorization: `Bearer ${accessT}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    let resp = null;

    await axios
      .request(config)
      .then((response) => {
        resp = response?.data;
      })
      .catch((error) => {
        if (
          serverCodes.includes(error?.request?.status) ||
          !error?.request?.status
        ) {
          resp = {
            data: {
              response: "Oops, Something went wrong, please try again later.",
            },
          };
          return;
        } else if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          authorizationRedirect();
        } else {
          resp = error?.response;
        }
      });
    return resp;
  }
);
export const manualFileUpload = createAsyncThunk(
  "admin/fileUpload",
  async (data) => {
    let config = {
      method: "post",
      url: `${global.apiBaseUrl}file-upload`,
      headers: {
        Authorization: `Bearer ${accessT}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    let resp = null;

    await axios
      .request(config)
      .then((response) => {
        resp = response?.data;
      })
      .catch((error) => {
        if (
          serverCodes.includes(error?.request?.status) ||
          !error?.request?.status
        ) {
          resp = {
            data: {
              response: "Oops, Something went wrong, please try again later.",
            },
          };
          return;
        } else if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          authorizationRedirect();
        } else {
          resp = error?.response;
        }
      });
    return resp;
  }
);

export const conversationsSlice = createSlice({
  name: "conversations",
  initialState: {
    isLoading: false,
    allConversation: null,
    singleConversation: null,
    closeResp: null,
    openResp: null,
    convoUpdateIsLoading: false,

    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(allConversationRequest.pending, (state, action) => {
      // if (!state.isLoading) {
      state.isLoading = true;
      // }
    });
    builder.addCase(singleConversationRequest.pending, (state, action) => {
      // if (!state.isLoading) {
      state.isLoading = true;
      // }
    });
    builder.addCase(closeConversationRequest.pending, (state, action) => {
      // if (!state.isLoading) {
      state.convoUpdateIsLoading = true;
      // }
    });
    builder.addCase(reopenConversationRequest.pending, (state, action) => {
      // if (!state.isLoading) {
      state.convoUpdateIsLoading = true;
      // }
    });
    builder.addCase(switchConvoHandlerRequest.pending, (state, action) => {
      // if (!state.isLoading) {
      state.convoUpdateIsLoading = true;
      // }
    });
    builder.addCase(sendManualMessageRequest.pending, (state, action) => {
      // if (!state.isLoading) {
      state.convoUpdateIsLoading = true;
      // }
    });
    builder.addCase(manualFileUpload.pending, (state, action) => {
      // if (!state.isLoading) {
      state.convoUpdateIsLoading = true;
      // }
    });

    builder.addCase(allConversationRequest.fulfilled, (state, action) => {
      // if (state.isLoading ) {
      state.allConversation = action.payload;
      state.isLoading = false;
      // }
    });
    builder.addCase(singleConversationRequest.fulfilled, (state, action) => {
      // if (state.isLoading ) {
      state.singleConversation = action.payload;
      state.isLoading = false;
      // }
    });
    builder.addCase(closeConversationRequest.fulfilled, (state, action) => {
      // if (state.isLoading ) {
      state.data = action.payload;
      state.convoUpdateIsLoading = false;
      // }
    });
    builder.addCase(reopenConversationRequest.fulfilled, (state, action) => {
      // if (state.isLoading ) {
      state.data = action.payload;
      state.convoUpdateIsLoading = false;
      // }
    });
    builder.addCase(switchConvoHandlerRequest.fulfilled, (state, action) => {
      // if (state.isLoading ) {
      state.data = action.payload;
      state.convoUpdateIsLoading = false;
      // }
    });
    builder.addCase(sendManualMessageRequest.fulfilled, (state, action) => {
      // if (state.isLoading ) {
      state.data = action.payload;
      state.convoUpdateIsLoading = false;
      // }
    });
    builder.addCase(manualFileUpload.fulfilled, (state, action) => {
      // if (state.isLoading ) {
      state.data = action.payload;
      state.convoUpdateIsLoading = false;
      // }
    });

    builder.addCase(allConversationRequest.rejected, (state, action) => {
      // if (state.isLoading ) {
      state.error = action.payload;
      state.isLoading = false;
      // }
    });
    builder.addCase(singleConversationRequest.rejected, (state, action) => {
      // if (state.isLoading ) {
      state.isLoading = false;
      state.error = action?.payload;
      // }
    });
    builder.addCase(closeConversationRequest.rejected, (state, action) => {
      // if (state.isLoading ) {
      state.error = action.payload;
      state.convoUpdateIsLoading = false;
      // }
    });
    builder.addCase(reopenConversationRequest.rejected, (state, action) => {
      // if (state.isLoading ) {
      state.error = action.payload;
      state.convoUpdateIsLoading = false;
      // }
    });
    builder.addCase(switchConvoHandlerRequest.rejected, (state, action) => {
      // if (state.isLoading ) {
      state.error = action.payload;
      state.convoUpdateIsLoading = false;
      // }
    });
    builder.addCase(sendManualMessageRequest.rejected, (state, action) => {
      // if (state.isLoading ) {
      state.error = action.payload;
      state.convoUpdateIsLoading = false;
      // }
    });
    builder.addCase(manualFileUpload.rejected, (state, action) => {
      // if (state.isLoading ) {
      state.error = action.payload;
      state.convoUpdateIsLoading = false;
      // }
    });
  },
});

export default conversationsSlice.reducer;
