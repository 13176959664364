import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { forgotPasswordRequest } from "../../../redux/features/auth/authSlice";
import Cookies from "js-cookie";
import toast from "react-hot-toast";
import { FaSpinner } from "react-icons/fa";

export default function ForgotPasswordPage() {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const authState = useSelector((state) => state.authReducer);

  let dispatch = useDispatch();

  let email_reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,15})+$/;

  let checkEmail = () => {
    if (!email) {
      setEmailError("Email cannot be blank");
      return;
    }
    if (!email_reg.test(email)) {
      setEmailError("Invalid Email Address");
    } else {
      setEmailError("");
    }
  };

  let initPasswordReset = () => {
    let data = {
      email: email,
    };

    if (!email) {
      toast.error("Email cannot be blank");
      return;
    }
    if (!email_reg.test(email)) {
      setEmailError("Invalid Email Address");
      return;
    }

    dispatch(forgotPasswordRequest(data))
      .then((res) => {
        ;
        toast.success("A reset link has been sent to your email");
        if (res?.payload?.status === 200) {
          Cookies.set("authenticated", "1");
          Cookies.set("babtbu", res?.payload?.data?.access_token);
          setEmail(res.payload.data.email);
          // Cookies.set('brbtbu', res?.payload?.data?.refresh)

          // window.location.href = global.appBaseUrl
        }
      })
      .catch((err) => {
      });
  };

  return (
    <div className="auth-page-area">
      <div className="container-fluid">
        <div className=" flex justify-center items-center h-screen">
          <div>
            <div className="">
              <h1 className="font-PJ-medium text-[32px] leading-[40.32px] tracking-[-4%] ">
                Reset your password
              </h1>
              <p className="font-PJ-medium text-8 leading-[40.32px] tracking-[-4%]  ">
                Enter your email address to initiate your password reset
              </p>
            </div>

            <input
              type="email"
              value={email}
              required
              className={`border mt-2 p-3 w-full
               form-control ${emailError ? "input-error" : ""}`}
              onBlur={checkEmail}
              onChange={(email) => setEmail(email.target.value.toLowerCase())}
              placeholder="name@mail.com"
            />
            {emailError && (
              <p style={{ color: "#101010" }} className="p-0 ">
                {emailError}
              </p>
            )}

            <div className="mt-2">
              <button
                className="bg-primary w-full font-inter text-base leading-[19.36px] tracking-[-4%] text-light px-10 py-2 rounded-lg"
                onClick={initPasswordReset}
              >
                {authState.isLoading ? (
                  <div className="flex justify-center">
                    <FaSpinner className="animate-spin" />

                    <span className="sr-only"> Loading...</span>
                  </div>
                ) : (
                  "Proceed"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
