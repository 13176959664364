import React, { useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { FiUpload } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  createFaqsRequest,
  updateFaqsRequest,
} from "../../redux/features/faq/faqSlice";
import toast from "react-hot-toast";
import Cookies from "js-cookie";
import CustomCheckboxGroup from "../../reuseable/checkBox/index";
import { BsEye } from "react-icons/bs";
import notfound from "../../assets/state-notfound.svg";
import { FaChevronRight } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import avatar from "../../assets/widgetIcons/portrait-man-cartoon-style.jpg";
// import slide from "../../assets/Faqing/Slide 4_3 - 1.svg";
import sanitizeHtml from "sanitize-html";

const FaqComposePage = () => {
  const location = useLocation();
  const { state } = location;
  const { singleFaqData } = state || {};
  const fileInputRef = useRef(null);
  const [title, setTitle] = useState(singleFaqData?.title || "");
  const [description, setDescription] = useState(
    singleFaqData?.description || ""
  );

  const orgId = Cookies.get("orgId") || "";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const FaqId = singleFaqData?.faq_id;
  const profileState = useSelector((state) => state.profileReducer);
  const profileName = profileState?.profileInfo?.data.contact_first_name;
  const companyName = profileState?.profileInfo?.data.name;

  const sanitizedDescription = sanitizeHtml(description, {
    allowedTags: ["p", "br", "b", "i", "u", "a", "ul", "ol", "li"],
    allowedAttributes: {
      a: ["href", "title"],
    },
  });

  

  const handleIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleSubmit = () => {
    if (!title || !description === 0) {
      toast.error("Please fill in all fields.");
      return;
    }

    const data = {
      organization_id: orgId,
      faq_id: FaqId,
      title: title,
      description: sanitizedDescription,
    };

    const action = singleFaqData ? updateFaqsRequest : createFaqsRequest;

    dispatch(action(data))
      .then((res) => {
        if (res?.payload?.status === 200) {
          toast.success(
            singleFaqData
              ? "Faq updated successfully"
              : "Faq created successfully"
          );
          navigate(-1);
        } else {
          toast.error(res?.payload?.data?.detail || "An error occurred.");
        }
      })
      .catch((err) => {
        toast.error("An error occurred.");
      });
  };

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
      ["link", "image", "video"],
      ["clean"],
    ],
  };

  const downArrow = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M10 7L15 12L10 17"
        stroke="#000000"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );

  const upArrow = (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.7071 14.7071C12.3166 15.0976 11.6834 15.0976 11.2929 14.7071L6.29289 9.70711C5.90237 9.31658 5.90237 8.68342 6.29289 8.29289C6.68342 7.90237 7.31658 7.90237 7.70711 8.29289L12 12.5858L16.2929 8.29289C16.6834 7.90237 17.3166 7.90237 17.7071 8.29289C18.0976 8.68342 18.0976 9.31658 17.7071 9.70711L12.7071 14.7071Z"
        fill="#000000"
      />
    </svg>
  );

  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <div className="px-10 mt-10 ">
      <div className="flex shadow-custom rounded-[20px] bg-[#FDFBFF] p-2 justify-between gap-6 xl:gap-14 2xl:gap-32 3xl:gap-60 xl:mt-6 mb-4 mt-2">
        <div className="bg-white border-2 w-[60%] border-[#E7E6E8] p-6 rounded-[20px]">
          <div>
            <h1 className="font-inter text-xl leading-[24px] tracking-[-4%]">
              {singleFaqData ? "Edit Faq" : "Create Faq"}
            </h1>
          </div>

          <div className="mt-5">
            <p className="font-inter text-sm leading-[21px] tracking-[-4%]">
              Enter Faq Title
            </p>
            <input
              type="text"
              value={title}
              className="w-full border border-[#DCDFE4] pl-4 mt-2 rounded-lg"
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Kindly provide title"
            />
          </div>
          <div className="mt-5">
            <p className="font-inter text-sm leading-[21px] tracking-[-4%]">
              Description
            </p>
            <ReactQuill
              modules={modules}
              value={description}
              className="reactQuill !w-full !h-full mt-2"
              theme="snow"
              onChange={(content) => setDescription(content)}
              placeholder="Write your description"
            />
          </div>
        </div>
        <div className="w-[40%]">
          <div className="flex bg-white justify-between items-center px-10 py-2 rounded-[10px] border-2 w-full">
            <p>Preview</p>
            <BsEye />
          </div>
          {
            // <div className="px-10">
            //   <div className="flex justify-center">
            //     <div className="border mt-5 rounded-lg w-full"></div>
            //   </div>
            //   <div className="flex inAppFaq p-4 justify-between">
            //     <div>
            //       <p>4 days ago</p>
            //       <p>Venixs-Wrapping up the year insight from 2023</p>
            //     </div>
            //     <div
            //       className={`flex justify-center items-center w-4 h-4 rounded-full border`}
            //     >
            //       <FaChevronRight width={2.25} height={4.5} />
            //     </div>
            //   </div>
            // </div>
          }

          {title && (
            <div className="border rounded-lg p-2 mt-4">
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={toggleAccordion}
              >
                <h1 className="font-PJ-medium mt-3 text-[22px] leading-[32px]">
                  {title}
                </h1>
                <div className="flex justify-end">
                  <span className="text-xl">
                    {isOpen ? upArrow : downArrow}
                  </span>
                </div>
              </div>
              {isOpen && (
                <div className="mt-2">
                  <div
                    className="reactQuillPreview text-wrapper"
                    dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="flex justify-end mb-11">
        <div className="flex gap-5">
          <button
            onClick={handleRefresh}
            className="border font-inter text-[15px] text-[#51376A] leading-[26px] py-2 px-[22px] rounded-lg"
          >
            Refresh
          </button>
          <button
            onClick={handleSubmit}
            className="bg-primary font-inter text-[15px] text-light py-2 px-[22px] rounded-lg leading-[26px]"
          >
            {singleFaqData ? " Save changes" : "Create Faq"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default FaqComposePage;
