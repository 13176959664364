import Cookies from "js-cookie";
import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import global from "../../../redux/constants/global";
import {
  allPlansRequest,
  trySubscriptionRequest,
} from "../../../redux/features/subscription/subscriptionSlice";
import { FaBullseye, FaSpinner } from "react-icons/fa";
import { BsCheck, BsCheckCircle } from "react-icons/bs";
import moneybill from "../../../assets/money bill.svg";

export default function SubPlanComp() {
  const isMounted = useRef(false);
  const [planModal, setPlanModal] = useState(false);
  const [planId, setPlanId] = useState("");

  const subscriptionState =
    useSelector((state) => state.subscriptionReducer) || [];
  const profileState = useSelector((state) => state.profileReducer);

  const planFeatures = {
    "h7127h19-5hw8-13g1-932i-1ib99514903i": [
      "Access to starter features",
      "100 conversation sessions (12 dollars per 100 conversations add-on)",
      "Single teammates slot ",
      "1 escalation channel (Slack)",
      "Active widget agent",
      "Unlimited integration channels",
      "2 URL data configurations",
      "Bot-to-human transfer",
    ],
    "b730cc29-10d7-49d1-933e-1ab995149998": [
      "Access to basic features",
      "200 conversation sessions (12 dollars per 100 conversations add-on)",
      "2 teammates slot (20 dollars for extra teammate added)",
      "1 escalation channel (Slack)",
      "Active widget agent",
      "Unlimited integration channels",
      "2 URL data configurations",
      "Bot-to-human transfer",
    ],
    "h29303ew-094e-7i23-2e2q-98ji08289882": [
      "1,000 conversation sessions (24 dollars per 500 conversations add-on)",
      "8 teammates slot (20 dollars for extra teammate added)",
      "Conversation Categorisation",
      "2 escalation channels (Slack, Teams)",
      "Active widget agent",
      "Message broadcasting (widget, email, in-app)",
      "Bot-to-human transfer",
      "Image/ document upload on the chat feature",
      "Unlimited integration channels",
      "Conversation ticketing system",
      "FAQ Auto Generation",
    ],
    "e41hw992-91js-427h-4yw6-31eu8iu28oi9": [
      "5,000 conversation sessions (40 dollars per 1,000 conversations add-on)",
      "12 teammates slot (20 dollars for extra teammate added)",
      "Conversation Categorisation",
      "All escalation channels (Slack, Teams, Whatsapp: 15 escalations, e.t.c)",
      "Active widget agent",
      "Message broadcasting (widget, email, in-app)",
      "Bot-to-human transfer",
      "Image/ document upload on the chat feature",
      "Unlimited integration channels",
      "Unlimited data configurations",
      "Conversation ticketing system",
      "FAQ Auto Generation",
      "Multilingual Feature (coming soon)",
    ],
  };

  const sortedPlan =
    subscriptionState?.plans?.data?.slice().sort((a, b) => a.price - b.price) ||
    [];

  let dispatch = useDispatch();

  // console.log(subscriptionState);

  const filted = subscriptionState?.subHistory?.data?.filter(
    (value, i) => value?.is_active === true
  );

  const currentSub = filted?.length
    ? filted[filted?.length - 1].subscription_plan_id?.name
    : null;

  let orgId = Cookies.get("orgId") || "";

  useEffect(() => {
    if (isMounted.current) return;
    isMounted.current = true;
    getAllPlans();
  }, []);

  let getAllPlans = () => {
    dispatch(allPlansRequest())
      .then((res) => {
        if (res?.payload?.status !== 200) {
          toast.error(res?.payload?.data?.response);
        }
      })
      .catch((err) => {});
  };

  let subscribe = () => {
    let data = {
      organization_id: orgId,
      success_url: global.appBaseUrl + "Subscription?success",
      failure_url: global.appBaseUrl + "Subscription?failed",
      force: false,
      plan_id: planId,
    };

    dispatch(trySubscriptionRequest(data))
      .then((res) => {
        if (res?.payload?.status === 200) {
          window.location.href = res?.payload?.data?.redirect_url;
        } else {
          toast.error(res?.payload?.data?.detail);
        }
      })
      .catch((err) => {});
  };

  return (
    <div className="">
      {planModal && (
        <div className="main-modal flex justify-center items-center">
          <div className="main-modal-content bg-white py-10 px-4  ">
            <div className=" rounded-lg">
              <div className="">
                <div className="flex gap-6">
                  <div>
                    <img src={moneybill} alt="moneyBill" />
                  </div>
                  <div>
                    <h5 className="font-PJ-medium text-2xl leading-9 tracking-[-0.8%]">
                      {subscriptionState?.plans?.data?.map((val, i) => {
                        if (val?.plan_id === planId) {
                          return <p key={i}>Subscribe for {val?.name} plan</p>;
                        }
                      })}
                    </h5>
                    <div className="mt-3 font-PJ-regular text-lg leading-[27px] tracking-[-0.32%] text-[#5f5f5f] ">
                      {subscriptionState?.plans?.data?.map((val, i) => {
                        if (val?.plan_id === planId) {
                          return (
                            <p key={i}>
                              You are about to subscribe to {val?.name} plan{" "}
                              <br /> at the the price of{" "}
                              {profileState?.profileInfo?.data?.currency}{" "}
                              {val?.price.toLocaleString()} per month .
                            </p>
                          );
                        }
                      })}
                    </div>
                  </div>
                </div>

                <hr className="mt-10 mb-4" />
                <div className="flex justify-center gap-6">
                  <button
                    onClick={() => {
                      setPlanId("");
                      setPlanModal(false);
                    }}
                    className=" border border-[#b5b5b5] font-inter text-base leading-[19.36px] tracking-[-4%] rounded-lg w-[186px] py-2"
                  >
                    Cancel
                  </button>

                  <button
                    className="bg-primary py-2 w-[186px] font-inter text-base leading-[19.36px] tracking-[-4%] text-light rounded-lg "
                    onClick={subscribe}
                    disabled={subscriptionState?.updateIsLoading}
                  >
                    {subscriptionState?.updateIsLoading ? (
                      <div className="flex items-center justify-center">
                        <FaSpinner className="animate-spin " />

                        <span className="sr-only"> Loading...</span>
                      </div>
                    ) : (
                      "Subscribe"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="mt-5 py-4">
        <div className="flex justify-between w-full gap-4">
          {sortedPlan?.map((val, i) => (
            <div
              className={`mt-4 border shadow-custom w-full bg-white px-4 py-4 mb-4 rounded-xl ${
                val?.name === currentSub ? "border-[#51376A]" : ""
              }`}
              key={i}
            >
              <div>
                <div className="border-[0.4px] bg-white shadow-md border-[##D9D9D999] border-opacity-[60%] rounded-md px-4 ">
                  <div className="flex justify-between items-center">
                    <h4 className="my-3 font-PJ-medium text-xl leading-[25.2px] text-[#51376A] tracking-[-4%] ">
                      {val?.name} Plan
                    </h4>
                    {val?.name === currentSub && (
                      <BsCheck
                        color="#51376A"
                        className="w-6 h-6 bg-light rounded-full"
                      />
                    )}
                  </div>

                  <h2 className="font-PJ-semibold text-[28px] leading-[35.28px] tracking-[-4%] lg:text-2xl ">
                    {profileState?.profileInfo?.data?.currency}{" "}
                    {val?.price.toLocaleString()}
                    /month
                  </h2>
                  <p className="mb-4 font-PJ-regular text-lg leading-[22.68px] tracking-[-4%]  ">
                    {val?.description}
                  </p>
                  <button
                    className="bg-primary text-light rounded-xl font-inter text-base leading-[19.36px] tracking-[-4%] px-5 py-3 w-full mb-5"
                    onClick={() => {
                      setPlanId(val?.plan_id);
                      setPlanModal(true);
                    }}
                  >
                    {val?.name === currentSub
                      ? "Resubscribe"
                      : " Subscribe Now"}
                  </button>
                </div>
                <div className="mt-4 plan-feature">
                  {planFeatures[val?.plan_id] &&
                    planFeatures[val?.plan_id].map((feature, index) => (
                      <span className="flex gap-4 items-start mt-3" key={index}>
                        <div className="">
                          <BsCheckCircle className="w-5 h-5 text-[#51376A] mt-2" />
                        </div>
                        <p className="font-PJ-regular text-[#0A0014] text-base leading-[26px] tracking-[-4%]  ">
                          {feature}
                        </p>
                      </span>
                    ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
