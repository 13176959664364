import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Router, useLocation, useNavigate } from "react-router-dom";
import verifyTokenSlice, {
  verifyTokenRequest,
} from "../../redux/features/verifyToken/verifyTokenSlice";
import toast from "react-hot-toast";

const VerifyInvitedMemberPage = () => {
  const [isToken, setIsToken] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getpathname = location.pathname;
  const token = getpathname.match(/\/verify\/([^\/]+)/)[1];
 

  useEffect(() => {
    if (token) {
      dispatch(verifyTokenRequest({ token: token }))
        .then((res) => {
          if (res?.payload?.status === 200) {
            setIsToken(true);
            toast.success("Congratulations your invitation is successful");
            navigate("/login");
          } else {
            toast.error("Sorry your invitation is unsuccessful");
            navigate("/login");
            setIsToken(false);
            toast.error(res?.payload?.data?.detail);
          }
        })
        .catch((err) => {
          toast.error("Sorry your invitation is unsuccessful");
          navigate("/login");
          setIsToken(false);
          ;
        });
    }
  }, []);

  return (
    <div>
      {/* {isToken === true ? (
              <p className="">congratulations
              <button>Sign In</button>
              </p>
      ) : (
        <p className="">failed</p>
      )} */}
    </div>
  );
};

export default VerifyInvitedMemberPage;
