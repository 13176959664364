import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";
import { authorizationRedirect, serverCodes } from "../../constants/api";
import global from "../../constants/global";

let accessT = Cookies.get("babtbu") || "";
let orgId = Cookies.get("orgId") || "";

export const dashboardInfoRequest = createAsyncThunk(
  "dashboard/dashboardInfo",
  async () => {
    let config = {
      method: "post",
      url: `${global.apiBaseUrl}dashboard-analytics`,
      headers: {
        Authorization: `Bearer ${accessT}`,
        "Content-Type": "application/json",
      },
      data: { organisation_id: orgId },
    };

    let resp = null;

    await axios
      .request(config)
      .then((response) => {
        resp = response;
      })
      .catch((error) => {
        if (
          serverCodes.includes(error?.request?.status) ||
          !error?.request?.status
        ) {
          resp = {
            data: {
              response: "Oops, Something went wrong, please try again later.",
            },
          };
          return;
        } else if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          authorizationRedirect();
        } else {
          resp = error?.response;
        }
      });
    return resp;
  }
);
export const conversationAnalytics = createAsyncThunk(
  "dashboard/conversation",
  async (data) => {
    let config = {
      method: "post",
      url: `${global.apiBaseUrl}conversation-analytics`,
      headers: {
        Authorization: `Bearer ${accessT}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    let resp = null;

    await axios
      .request(config)
      .then((response) => {
        resp = response;
      })
      .catch((error) => {
        if (
          serverCodes.includes(error?.request?.status) ||
          !error?.request?.status
        ) {
          resp = {
            data: {
              response: "Oops, Something went wrong, please try again later.",
            },
          };
          return;
        } else if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          authorizationRedirect();
        } else {
          resp = error?.response;
        }
      });
    return resp;
  }
);

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    isLoading: false,
    dashboardInfo: null,
    updateIsLoading: false,
    plans: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(dashboardInfoRequest.pending, (state, action) => {
      // if (!state.isLoading) {
      state.isLoading = true;
      // }
    });

    builder.addCase(conversationAnalytics.pending, (state, action) => {
      // if (!state.isLoading) {
      state.isLoading = true;
      // }
    });

    builder.addCase(dashboardInfoRequest.fulfilled, (state, action) => {
      // if (state.isLoading ) {
      state.dashboardInfo = action.payload;
      state.isLoading = false;
      // }
    });

    builder.addCase(conversationAnalytics.fulfilled, (state, action) => {
      // if (state.isLoading ) {
      state.plans = action.payload;
      state.isLoading = false;
      // }
    });

    builder.addCase(dashboardInfoRequest.rejected, (state, action) => {
      // if (state.isLoading ) {
      state.isLoading = false;
      state.error = action.payload;
      // }
    });

    builder.addCase(conversationAnalytics.rejected, (state, action) => {
      // if (state.isLoading ) {
      state.isLoading = false;
      state.error = action.payload;
      // }
    });
  },
});

export default dashboardSlice.reducer;
