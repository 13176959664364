// const apiBaseUrl = "http://3.86.30.151:8080/";
// const apiBaseUrl = "https://api-dev.venixs.com/";

const apiBaseUrl = "https://api.venixs.com/";

// const appBaseUrl = "http://localhost:3000/"
// const appBaseUrl = "https://console.venixs.com/"
const appBaseUrl = window.location.origin + "/";

const global = { apiBaseUrl, appBaseUrl };

export default global;
