import Cookies from "js-cookie";
import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import secureRandomPassword from "secure-random-password";
import {
  allTeamRequest,
  teamAddRequest,
  teamDeleteRequest,
  teamUpdateRequest,
} from "../../redux/features/teams/teamSlice";
import Search from "../../assets/Search.svg";
import edit from "../../assets/edit-02.svg";
import del from "../../assets/trash-2.svg";
import plus from "../../assets/plus.svg";
import moment from "moment";
import ClickOutside, { userRole } from "../../components/utils/helpers";
import { EmptyStateComp, MainLoader } from "../../components/utils";
import { FaPlus, FaSpinner } from "react-icons/fa";
import Table from "../../reuseable/table/Table";
import CustomDropdown from "../../reuseable/dropDown";

export default function TeamsPage() {
  const isMounted = useRef(false);
  const [singleTeamData, setSingleTeamData] = useState({});
  const [loading, setLoading] = useState(false);
  const [adminDropDown, setAdminDropDown] = useState(false);
  const [businessDropDown, setBusinessDropDown] = useState(false);
  const [addTeamModal, setAddTeamModal] = useState(false);
  const [isEdit, setIsEdit] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [password, setPassword] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [allTeamData, setAllTeamData] = useState([]);
  const [ind, setInd] = useState(0);
  const [search, setSearch] = useState("");

  const routeState = useSelector((state) => state.routeReducer);
  console.log(routeState?.profileInfo?.id);

  const handleRoleChange = (e) => {
    setSelectedRole(e.target.value);
  };
  const location = useLocation();
  const { state } = location;

  const isPlanExpired = routeState?.orgSubscription?.is_expired || "";

  const teamData = JSON.parse(localStorage.getItem("tm"));

  const customersState = useSelector((state) => state.customersReducer);

  let email_reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,15})+$/;

  let dispatch = useDispatch();
  let orgId = Cookies.get("orgId") || "";
  const fetchAllTeam = () => {
    setLoading(true);
    if (routeState?.profileInfo?.id) {
      dispatch(
        allTeamRequest({
          organization_id: routeState?.profileInfo?.id,
        })
      )
        .then((res) => {
          setLoading(false);
          if (res?.payload?.status === 200) {
            setAllTeamData(res?.payload?.data);
            console.log("Team Data:", res?.payload?.data);
          } else {
            toast.error(res?.payload?.data?.detail);
          }
        })
        .catch((err) => {});
    }
  };

  useEffect(() => {
    // if (isMounted.current) return;
    // isMounted.current = true;
    // if (routeState?.profileInfo && routeState?.profileInfo?.id) {
    // }
    fetchAllTeam();
  }, [routeState?.profileInfo?.id]);

  // useEffect(() => {
  //   if (isMounted.current) return;
  //   isMounted.current = true;
  //   if (search.length <= 0) {
  //     fetchAllTeam();
  //   } else {
  //     const filteredArr = allTeamData?.filter((item) =>
  //       item.name.toLowerCase().includes(search.toLowerCase())
  //     );
  //     setAllTeamData(filteredArr);
  //   }
  // }, [search]);

  const generatePassword = () => {
    const newPassword = secureRandomPassword.randomPassword({
      length: 12,
      characters:
        secureRandomPassword.lower +
        secureRandomPassword.upper +
        secureRandomPassword.digits +
        secureRandomPassword.symbols,
    });
    setPassword(newPassword);
  };

  let addNewTeam = () => {
    let data = {
      organization_id: routeState?.profileInfo?.id,
      name: fullName,
      email: email,
      role: selectedRole.value,
      password: password,
    };

    if (!fullName) {
      toast.error("Fullname cannot be blank");
      return;
    }
    if (!email) {
      toast.error("Email cannot be blank");
      return;
    }
    if (!email_reg.test(email)) {
      toast.error("Invalid Email Address");
      return;
    }
    if (!selectedRole) {
      toast.error("Invalid Role Selected");
      return;
    }
    if (!password) {
      toast.error("Invalid Password");
      return;
    }

    dispatch(teamAddRequest(data))
      .then((res) => {
        if (res?.payload?.status === 200) {
          toast.success("Team Member Added Successfully");
          setAddTeamModal(false);
          fetchAllTeam();
          setPassword("");
        } else {
          toast.error(res?.payload?.data?.detail);
        }
      })
      .catch((err) => {});
  };

  let updateTeam = () => {
    let data = {
      organization_id: singleTeamData?.organization_id,
      // name: fullName,
      email: email,
      role: selectedRole.value,
    };

    if (!fullName) {
      toast.error("Fullname cannot be blank");
      return;
    }
    if (!email) {
      toast.error("Email cannot be blank");
      return;
    }
    if (!email_reg.test(email)) {
      toast.error("Invalid Email Address");
      return;
    }
    if (!selectedRole) {
      toast.error("Invalid Role Selected");
      return;
    }

    dispatch(teamUpdateRequest(data))
      .then((res) => {
        if (res?.payload?.status === 200) {
          toast.success("Team Member Updated Successfully");
          setAddTeamModal(false);
          fetchAllTeam();
        } else {
          toast.error(res?.payload?.data?.detail);
        }
      })
      .catch((err) => {});
  };

  const node = ClickOutside(() => {
    setBusinessDropDown(false);
    setAdminDropDown(false);
  });

  const hideDeleteConfirmation = () => {
    setShowDeleteModal(false);
  };

  const handleDelete = () => {
    dispatch(teamDeleteRequest({ team_id: singleTeamData?.id }))
      .then((res) => {
        if (res?.payload?.status === 200) {
          toast.success("Team Member Deleted Successfully");
          setShowDeleteModal(false);
          fetchAllTeam();
        } else {
          toast.error(res?.payload?.data?.detail);
        }
      })
      .catch((err) => {});
  };

  const columns = [
    {
      Header: "S/N",
      accessor: "id",
      Cell: (row) => (
        <div className="font-PJ-bold font-medium text-xs text-[#424242]">
          {parseInt(row.cell.row.id) + 1}
        </div>
      ),
    },
    {
      Header: "User",
      accessor: "name",
      Cell: (row) => {
        const firstTitleInitial = row.cell.row.original.name
          .charAt(0)
          .toUpperCase();
        const backgroundColor = row.index % 2 === 0 ? "#FDFFE6" : "#F7E6FF";

        return (
          <div className="flex gap-4 items-center">
            <div
              className={`bg-[#F7E6FF] rounded-full h-8 w-8 text-base flex items-center justify-center text-black font-semiboldInter font-semibold`}
            >
              {firstTitleInitial}
            </div>
            <div>
              <p className="font-PJ-bold font-medium text-[14px] text-[#101010]">
                {row.cell.row.original.name || "Name Not Available"}
              </p>
              <p className="font-inter font-normal mt-[6px] text-[12px] text-[#636363]">
                {row.cell.row.original.email || "Email Not Available"}
              </p>
            </div>
          </div>
        );
      },
    },

    {
      Header: "Date Added",
      accessor: "joined",
      Cell: (row) => (
        <div className="font-inter font-normal text-[14px] text-[#424242] ">
          {moment(row.cell.value).format("MMM DD, yyyy")}
        </div>
      ),
    },
    {
      Header: "Last Active",
      accessor: "join",
      Cell: (row) => (
        <div className="font-inter font-normal text-[14px] text-[#424242] ">
          {row.cell.value || "-"}
        </div>
      ),
    },
    {
      Header: " ",
      accessor: "action",
      Cell: (row) => (
        <div>
          <div
            className="cursor-pointer"
            onClick={() => {
              // businessDropdowns(row.cell.row.original, row.cell.row.id);
              // setBusinessDropDown(true)
              setAdminDropDown(true);
              setInd(row.cell.row.id);
              setSingleTeamData(row.cell.row.original);
            }}
          >
            <i className="ri-more-fill me-2 ri-xl" />
          </div>
          {adminDropDown && (
            <>
              {row.cell.row.id === ind && (
                <div ref={node} className="dropdown-content">
                  <p
                    state={singleTeamData}
                    style={{ textDecorationLine: "none" }}
                    className="editDrop"
                    onClick={() => {
                      setAddTeamModal(true);
                      setIsEdit("edit");
                      setAdminDropDown(false);
                      setFullName(row.cell.row.original.name);
                      setSelectedRole({
                        label: row.cell.row.original.role,
                        value: row.cell.row.original.role,
                      });
                      setEmail(row.cell.row.original.email);
                    }}
                  >
                    <img width={16.11} src={edit} alt="edit" />
                    Edit Team
                  </p>
                  <p
                    className="deleteDrop"
                    onClick={() => {
                      setShowDeleteModal(true);
                      setAdminDropDown(false);
                    }}
                  >
                    <img width={16.11} src={del} alt="edit" />
                    Delete Team
                  </p>
                </div>
              )}
            </>
          )}
        </div>
      ),
    },
  ];

  const column = [
    {
      Header: "S/N",
      accessor: "id",
      Cell: (row) => (
        <div className="font-PJ-bold font-medium text-xs text-[#424242]">
          {parseInt(row.cell.row.id) + 1}
        </div>
      ),
    },
    {
      Header: "User",
      accessor: "name",
      Cell: (row) => {
        const firstTitleInitial = row.cell.row.original.name
          .charAt(0)
          .toUpperCase();
        const backgroundColor = row.index % 2 === 0 ? "#FDFFE6" : "#F7E6FF";

        return (
          <div className="flex gap-4 items-center">
            <div
              className={`bg-[#F7E6FF] rounded-full h-8 w-8 text-base flex items-center justify-center text-black font-semiboldInter font-semibold`}
            >
              {firstTitleInitial}
            </div>
            <div>
              <p className="font-PJ-bold font-medium text-[14px] text-[#101010]">
                {row.cell.row.original.name || "Name Not Available"}
              </p>
              <p className="font-inter font-normal mt-[6px] text-[12px] text-[#636363]">
                {row.cell.row.original.email || "Email Not Available"}
              </p>
            </div>
          </div>
        );
      },
    },

    {
      Header: "Date Added",
      accessor: "joined",
      Cell: (row) => (
        <div className="font-inter font-normal text-[14px] text-[#424242] ">
          {moment(row.cell.value).format("MMM DD, yyyy")}
        </div>
      ),
    },
    {
      Header: "Last Active",
      accessor: "join",
      Cell: (row) => (
        <div className="font-inter font-normal text-[14px] text-[#424242] ">
          {row.cell.value || "-"}
        </div>
      ),
    },
    {
      Header: " ",
      accessor: "action",
      Cell: (row) => (
        <div>
          <div
            className="cursor-pointer"
            onClick={() => {
              setBusinessDropDown(true);
              setInd(row.cell.row.id);
              setSingleTeamData(row.cell.row.original);
            }}
          >
            <i className="ri-more-fill me-2 ri-xl" />
          </div>
          {businessDropDown && (
            <>
              {row.cell.row.id === ind && (
                <div ref={node} className="dropdown-content">
                  <p
                    state={singleTeamData}
                    style={{ textDecorationLine: "none" }}
                    className="editDrop"
                    onClick={() => {
                      setAddTeamModal(true);
                      setIsEdit("edit");
                      setBusinessDropDown(false);
                      setFullName(row.cell.row.original.name);
                      setSelectedRole({
                        label: row.cell.row.original.role,
                        value: row.cell.row.original.role,
                      });
                      setEmail(row.cell.row.original.email);
                    }}
                  >
                    <img width={16.11} src={edit} alt="edit" />
                    Edit Team
                  </p>
                  <p
                    className="deleteDrop"
                    onClick={() => {
                      setShowDeleteModal(true);
                      setBusinessDropDown(false);
                    }}
                  >
                    <img width={16.11} src={del} alt="edit" />
                    Delete Team
                  </p>
                </div>
              )}
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className="px-[24px]">
      <div>
        {addTeamModal && (
          <div className="fixed inset-0 flex items-center justify-center z-[999] bg-gray-900 bg-opacity-50">
            <div className="bg-white rounded-lg p-8 max-w-md w-full relative">
              <div className="">
                <div className="">
                  <div className="flex justify-between ">
                    <div>
                      <h5 className="text-lg font-semibold mb-1">
                        {isEdit === "edit"
                          ? "Edit Profile"
                          : "Add a Team Member"}
                      </h5>
                      {isEdit === "edit" && (
                        <p className="text-sm text-gray-500 mb-4">
                          Make necessary changes to ticket details
                        </p>
                      )}
                    </div>
                    <span
                      className=" cursor-pointer"
                      onClick={() => setAddTeamModal(false)}
                    >
                      <i className="ri-close-line text-gray-500 text-lg" />
                    </span>
                  </div>

                  <div className="mt-3">
                    <div className="mb-4">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        readOnly={isEdit === "edit"}
                        value={fullName}
                        required
                        className="form-input mt-1 block border p-2 w-full"
                        onChange={(e) => setFullName(e.target.value)}
                        placeholder="John Doe"
                      />
                    </div>
                    <div className="mb-4">
                      <label
                        htmlFor="email"
                        className="block text-sm  font-medium text-gray-700"
                      >
                        Email Address
                      </label>
                      <input
                        type="email"
                        value={email}
                        readOnly={isEdit === "edit"}
                        required
                        className="form-input mt-1 border block p-2 w-full"
                        onChange={(e) => setEmail(e.target.value.toLowerCase())}
                        placeholder="name@mail.com"
                      />
                    </div>
                    <div className="mb-4">
                      <label
                        htmlFor="roles"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Roles
                      </label>
                      <CustomDropdown
                        options={[
                          { value: "Admin", label: "Admin" },
                          { value: "Support Agent", label: "Support Agent" },
                        ]}
                        selectedOption={selectedRole}
                        onChange={(option) => {
                          setSelectedRole(option);
                        }}
                        placeholder="Select a Role"
                      />
                      {/* <select
                        value={selectedRole}
                        onChange={handleRoleChange}
                        className="form-select mt-1 p-2 border block w-full"
                      >
                        <option value="">Select a Role</option>
                        <option value="Admin">Admin</option>
                        <option value="Support Agent">Support Agent</option>
                      </select> */}
                    </div>

                    {isEdit === "add" && (
                      <div className="mb-4">
                        <label
                          htmlFor="password"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Password
                        </label>
                        <input
                          type="text"
                          value={password}
                          readOnly
                          required
                          className="form-input border p-2 mt-1 block w-full"
                          onChange={(e) => setPassword(e.target.value)}
                          placeholder="Password"
                        />
                        <p
                          className="text-sm text-primary cursor-pointer mt-2"
                          onClick={generatePassword}
                        >
                          Generate Password
                        </p>
                      </div>
                    )}

                    <button
                      className="bg-primary text-lg font-inter text-light py-3 w-full rounded-md"
                      onClick={isEdit === "edit" ? updateTeam : addNewTeam}
                      disabled={customersState?.updateIsLoading}
                    >
                      {customersState?.updateIsLoading ? (
                        <span className="flex items-center justify-center">
                          <FaSpinner className="animate-spin mr-2" size="sm" />
                          <span>Loading...</span>
                        </span>
                      ) : isEdit === "edit" ? (
                        "Save Changes"
                      ) : (
                        "Send Invite"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {loading ? (
        <MainLoader />
      ) : (
        <>
          {!isPlanExpired ? (
            <div className="teams">
              <div className=" flex justify-between">
                <div className="TeamMember">
                  <h1 className="">Team Members</h1>
                  <p>Invite and Manage your team members</p>
                </div>
                {/* <div className="searchTeam">
              <input
                className=""
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search for a user "
                type="text"
              />
              <img className="searchIcon" src={Search} alt="search" />
            </div> */}
                {teamData?.role === userRole.ADMIN && (
                  <div>
                    {/* {!isPlanExpired === true && ( */}
                    <div className="mb-4">
                      <button
                        onClick={() => {
                          setAddTeamModal(true);
                          setIsEdit("add");
                          setFullName("");
                          setEmail("");
                          setSelectedRole("");
                        }}
                        className="bg-primary text-light font-inter text-lg flex items-center gap-2 px-8 py-3 rounded-lg  "
                      >
                        <FaPlus className="w-3 h-5" />
                        Add team member
                      </button>
                    </div>
                    {/* )} */}
                  </div>
                )}
              </div>
              <hr className="mt-3" />
              <div className="mt-6 flex gap-10 justify-between">
                <div className="w-[260px]">
                  <h1 className="font-PJ-medium mt-10 text-lg leading-[22.68px] tracking-[-4%] ">
                    Admin Users
                  </h1>
                  <p className="font-inter leading-[16.94px] tracking-[-4%] text-sm">
                    Admin can add and remove users and manage organization-level
                    settings
                  </p>
                </div>

                <div className="mt-4 w-[80%]">
                  <Table
                    columns={columns}
                    data={allTeamData.filter((item) => item.role === "Admin")}
                    currentPage={0}
                  />
                </div>
              </div>
              <hr className="mt-10  " />

              <div className="mt-6 flex gap-10 justify-between ">
                <div className=" mt-3  w-[300px]">
                  <h1 className="font-PJ-medium mt-10 text-lg leading-[22.68px] tracking-[-4%] ">
                    Business/ Account Users
                  </h1>
                  <p className="font-inter leading-[16.94px] tracking-[-4%] text-sm">
                    Account users can benefit from the current plan run by the
                    company
                  </p>
                </div>

                <div className="mt-4 w-[90%]">
                  <Table
                    columns={column}
                    data={allTeamData.filter(
                      (item) => item.role === "Support Agent"
                    )}
                    currentPage={0}
                  />
                </div>
              </div>
              {showDeleteModal && (
                <>
                  <div className="overlay"></div>
                  <div className="delete-modal">
                    <div className="delete-modal-content">
                      <div className="flex gap-4 py-5">
                        <div className=" deleteBin">
                          <img src={del} alt="delete" />
                        </div>
                        <div className=" modalBody">
                          <p style={{ fontSize: "17px", fontWeight: "500" }}>
                            This action can not be undone
                          </p>
                          <p style={{ fontSize: "15px" }}>
                            Are you sure you want to delete this user profile?
                          </p>
                        </div>
                      </div>
                      <hr color="#333" />
                      <div className=" mt-2  ">
                        <button
                          style={{ border: "2px" }}
                          className="border rounded-lg"
                          onClick={hideDeleteConfirmation}
                        >
                          Cancel
                        </button>
                        <button
                          className="bg-red-500 text-white"
                          onClick={handleDelete}
                        >
                          Delete Profile
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          ) : (
            <div className="">
              <div className="col-md-7">
                <div className="page-desc mt-4">
                  <h3>Team Members</h3>
                  <p>Invite and Manage your team members</p>
                </div>
              </div>
              <div style={{ marginTop: "150px" }}>
                <EmptyStateComp
                  title={
                    !isPlanExpired === true
                      ? "No Team yet"
                      : "No Active Subscription"
                  }
                  desc={
                    !isPlanExpired === true
                      ? "You have no Team member added yet, your team members will appear here as soon as you add some. "
                      : "You have no active subscription, your team members will appear here as soon as you subscribe. "
                  }
                />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
