import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { FaSpinner } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import ClickOutside from "../../utils/helpers";
import { singleConversationRequest } from "../../../redux/features/conversations/conversationsSlice";
import { useDispatch, useSelector } from "react-redux";
import { allTeamRequest } from "../../../redux/features/teams/teamSlice";
import {
  allTicketsRequest,
  assignTicket,
} from "../../../redux/features/ticketing/ticketingSlice";

export default function TicketingBoardComp(props) {
  const isMounted = useRef(false);
  const [items, setItems] = useState(props.allTickets);
  const [moreOptions, setMoreOptions] = useState(false);
  const [moreOptionsId, setMoreOptionsId] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [search, setSearch] = useState("");
  const [moveTicketModal, setMoveTicketModal] = useState(false);
  const [moveTicketValue, setMoveTicketValue] = useState("");
  const [sessionId, setSessionId] = useState("");
  const [dropOpen, setDropOpen] = useState(false);
  const [ticketId, setTicketId] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");

  const location = useLocation();
  const { state } = location;
  const dispatch = useDispatch();
  let ticketStatus = ["Open", "Ongoing", "Blocked", "Done"];
  const [allTeamData, setAllTeamData] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [ticketAssignee, setTicketAssignee] = useState("");
  const [segmentBgColors, setSegmentBgColors] = useState({
    Open: "",
    Ongoing: "",
    Blocked: "",
    Done: "",
  });

  const [multiChecks, setMultiChecks] = useState([]);

  const [selectedTicketCount, setSelectedTicketCount] = useState(0);

  const handleCheckboxChanges = (ticketId) => {
    const updatedItems = items.map((ticket) =>
      ticket.ticket_id === ticketId
        ? { ...ticket, isChecked: !ticket.isChecked }
        : ticket
    );
    setItems(updatedItems);

    const checkedCount = updatedItems.filter(
      (ticket) => ticket.isChecked
    ).length;
    setSelectedTicketCount(checkedCount);
  };

  const routeState = useSelector((state) => state.routeReducer);

  let handleDragStart = (event, itemId) => {
    event?.dataTransfer?.setData("ticketId", itemId);
  };

  let handleDragOver = (event) => {
    event.preventDefault();
  };

  let getAllTickets = () => {
    dispatch(allTicketsRequest())
      .then((res) => {
        if (res?.payload?.status === 200) {
          return;
        } else {
          toast.error(res?.payload?.data?.response);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (isMounted.current) return;
    isMounted.current = true;
    if (!isDropdownOpen) {
      setSearchQuery("");
      setFilteredOptions(allTeamData);
    }
  }, [isDropdownOpen, allTeamData]);

  let getConvo = () => {
    if (sessionId) {
      let data = {
        session_id: sessionId,
      };
      dispatch(singleConversationRequest(data))
        .then((res) => {
          if (res?.payload?.status === 200) {
            setEmail(res?.payload?.data?.email);
            setName(res?.payload?.data?.name);
          } else {
            toast.error(res?.payload?.data?.message);
          }
        })
        .catch((err) => {});
    }
  };

  const node = ClickOutside(() => {
    setMoveTicketModal(false);
    setDropOpen(false);
    setSearchQuery("");
  });

  const fetchAllTeam = () => {
    setLoading(true);
    if (routeState?.profileInfo?.id) {
      dispatch(allTeamRequest({ organization_id: routeState?.profileInfo?.id }))
        .then((res) => {
          setLoading(false);
          if (res?.payload?.status === 200) {
            setAllTeamData(res?.payload?.data);
            setFilteredOptions(res?.payload?.data);
          } else {
            // toast.error(res?.payload?.data?.message);
          }
        })
        .catch((err) => {});
    }
  };

  useEffect(() => {
    if (isMounted.current) return;
    isMounted.current = true;
    if (routeState?.profileInfo && routeState?.profileInfo?.id) {
      fetchAllTeam();
    }
  }, [routeState]);

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    const filtered = allTeamData.filter(
      (option) =>
        option?.name && option?.name.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredOptions(filtered);
    setIsDropdownOpen(true);
  };

  const handleOptionSelect = (option) => {
    setTicketAssignee(option?.email);
    setSearchQuery(option?.name);
    setIsDropdownOpen(false);
    dispatch(
      assignTicket({
        ticket_id: ticketId,
        assigned_to: option?.email,
      })
    )
      .then((res) => {
        if (res?.payload?.status === 200) {
          toast.success("Ticket assigned successfully");
          getAllTickets();
        } else {
          toast.error(res?.payload?.data?.detail);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (isMounted.current) return;
    isMounted.current = true;
    getConvo();
  }, [sessionId]);

  //

  let handleDrop = (event, targetStatus) => {
    let draggedId = event?.dataTransfer?.getData("ticketId");
    let draggedItem = items.find((item) => item.ticket_id === draggedId);
    let targetIndex = items.findIndex(
      (item) => parseInt(event.target.id) === item.ticket_id
    );

    if (draggedItem) {
      let newItems = [...items];
      newItems = newItems.filter((item) => item.ticket_id !== draggedId);
      newItems.splice(targetIndex, 0, {
        ...draggedItem,
        ticket_status: targetStatus,
        assignee: ticketAssignee,
      });

      setItems(newItems);

      props.updateTicketByMovement({
        ...draggedItem,
        ticket_status: targetStatus,
      });
    }
  };

  let editModal = (ticketData) => {
    let activeTicket = {
      title: ticketData?.title,
      description: ticketData?.description,
      status: ticketData?.ticket_status,
      id: ticketData?.ticket_id,
      session_id: ticketData.session_id,
      assigned_to: {
        email: ticketData?.assigned_to?.email,
        name: ticketData?.assigned_to?.name,
      },
    };
    setTicketAssignee(ticketData?.assigned_to?.email);
    props?.toggleEditModal(activeTicket);
  };
  const truncateTitle = (title) => {
    if (!title) return "";
    const truncatedTitle = title.slice(0, 20);
    if (title.length > 20) {
      return truncatedTitle + " ...";
    } else {
      return truncatedTitle;
    }
  };

  let moveTicket = (ticketData) => {
    if (!moveTicketValue) {
      toast.error("Please select ticket status");
      return;
    }
    props?.updateTicketByMovement({
      ...ticketData,
      ticket_status: moveTicketValue,
    });
  };

  const handleDescriptionClick = (status) => {
    const newBgColors = { ...segmentBgColors };

    newBgColors[status] = newBgColors[status] === "" ? "bg-[#F2E6FF]" : "";

    setSegmentBgColors(newBgColors);
  };

  const handleSelectedItem = (ticket) => {
    props.setMultiChecks((prevSelectedItems) => {
      const isSelected = prevSelectedItems.some(
        (item) => item.ticket_id === ticket.ticket_id
      );

      if (!isSelected) {
        return [...prevSelectedItems, ticket];
      } else {
        return prevSelectedItems.filter(
          (item) => item.ticket_id !== ticket.ticket_id
        );
      }
    });
  };

  return (
    <div className="ticket-board-area h-screen mt-10 relative">
      <div className={`grid grid-cols-4  `} ref={node}>
        {ticketStatus?.map((val) => (
          <div
            className="col-md-6  col-lg-3 pb-5 px-3"
            draggable="true"
            onDragOver={handleDragOver}
            onDrop={(event) => handleDrop(event, val?.toLowerCase())}
            key={val}
          >
            <div
              className={`border-b  mb-6 px-2 !font-inter ${segmentBgColors[val]} `}
            >
              <div className="flex justify-between">
                <h5 className="text-[18px] font-medium text-[#323232] p-[2px]">
                  {val}
                </h5>
                <p className="h-6 w-6 flex justify-center items-center mt-1 text-black rounded-lg bg-[#D9D9D9]">
                  {
                    items?.filter(
                      (ticket) => ticket?.ticket_status === val?.toLowerCase()
                    )?.length
                  }
                </p>
              </div>
            </div>

            <div className=" mb-11 bg-[#F5F3F8] rounded-[14.17px] shadow-inner   p-[17px]">
              {items
                ?.filter((e) => e?.ticket_status === val?.toLowerCase())
                ?.map((ticket) => (
                  <div
                    className={` rounded-[14.17px] bg-white mb-6 cursor-pointer relative`}
                    onDragOver={handleDragOver}
                    key={ticket?.ticket_id}
                    draggable="true"
                    onDragStart={(event) =>
                      handleDragStart(event, ticket?.ticket_id)
                    }
                  >
                    {/* <div className="more-dropdown    "> */}
                    <div className="relative">
                      {/* <IoEllipsisVertical
                        className="z-10 top-[14px] absolute right-4 w-[10px] "
                        onClick={() => {
                          setMoreOptionsId(ticket?.ticket_id);
                          setMoveTicketModal(!moveTicketModal);
                          setMoveTicketValue(val?.toLowerCase());
                          setSessionId(ticket?.session_id);
                        }}
                      /> */}

                      <input
                        type="checkbox"
                        className="z-10 top-[14px] absolute right-4 cursor-pointer "
                        id={`ticket_${ticket?.ticket_id}`}
                        onChange={() => handleSelectedItem(ticket)}
                        checked={props.multiChecks.some(
                          (selectedItem) =>
                            selectedItem.ticket_id === ticket.ticket_id
                        )}
                      />

                      {moveTicketModal &&
                        moreOptionsId === ticket?.ticket_id && (
                          <div
                            ref={node}
                            className="more-options mt-14 z-20 rounded-sm shadow-lg py-4"
                            style={{
                              left: `${
                                ticketStatus[ticketStatus.length - 1] === val
                                  ? "0px"
                                  : "0px"
                              }`,
                            }}
                          >
                            {sessionId && (
                              <div className="border-bottom">
                                <div>
                                  <h4 className="font-PJ-medium text-[12px]   ">
                                    Attached Conversation
                                  </h4>
                                  <div className="flex font-inter items-center gap-2">
                                    <p className="text-sm">Name:</p>
                                    <p className=" text-xs">{name}</p>
                                  </div>
                                  <div className="flex font-inter items-center gap-2">
                                    <p className="text-sm">Email:</p>
                                    <p className="text-xs">{email}</p>
                                  </div>
                                </div>
                                <div className="mb-2">
                                  <Link
                                    state={{
                                      ...state,
                                      session_id: sessionId,
                                    }}
                                    to={`/Messages?sessionId=${sessionId}`}
                                    className="text-light py-1 px-2 rounded-[3px] mt-1 bg-primary font-PJ-semibold text-xs"
                                  >
                                    Go to Conversation
                                  </Link>
                                </div>
                              </div>
                            )}

                            <div className="mt-1 font-PJ-semibold text-xs leading-[18px] tracking-[-4%]">
                              Move Ticket to
                            </div>
                            <p className="font-inter leading-[12px] tracking-[-4%] text-[8px]">
                              You can only select one at a time
                            </p>

                            <div className="grid grid-cols-2 font-inter text-base ">
                              <div className="flex py-2 items-center me-3">
                                {moveTicketValue === "open" ? (
                                  <i
                                    className="ri-checkbox-line ri-xl mr-2"
                                    onClick={() => setMoveTicketValue("")}
                                  />
                                ) : (
                                  <i
                                    className="ri-checkbox-blank-line ri-xl mr-2"
                                    onClick={() => setMoveTicketValue("open")}
                                  />
                                )}
                                <p className="mb-0">Open</p>
                              </div>

                              <div className="flex py-2 items-center me-3">
                                {moveTicketValue === "ongoing" ? (
                                  <i
                                    className="ri-checkbox-line ri-xl mr-2"
                                    onClick={() => setMoveTicketValue("")}
                                  />
                                ) : (
                                  <i
                                    className="ri-checkbox-blank-line ri-xl mr-2"
                                    onClick={() =>
                                      setMoveTicketValue("ongoing")
                                    }
                                  />
                                )}
                                <p className="mb-0">Ongoing</p>
                              </div>

                              <div className="flex py-2 items-center me-3">
                                {moveTicketValue === "done" ? (
                                  <i
                                    className="ri-checkbox-line ri-xl mr-2"
                                    onClick={() => setMoveTicketValue("")}
                                  />
                                ) : (
                                  <i
                                    className="ri-checkbox-blank-line ri-xl mr-2"
                                    onClick={() => setMoveTicketValue("done")}
                                  />
                                )}
                                <p className="mb-0">Done</p>
                              </div>

                              <div className="flex py-2 items-center me-3">
                                {moveTicketValue === "blocked" ? (
                                  <i
                                    className="ri-checkbox-line ri-xl mr-2"
                                    onClick={() => setMoveTicketValue("")}
                                  />
                                ) : (
                                  <i
                                    className="ri-checkbox-blank-line ri-xl mr-2"
                                    onClick={() =>
                                      setMoveTicketValue("blocked")
                                    }
                                  />
                                )}
                                <p className="mb-0">Blocked</p>
                              </div>
                            </div>
                            <div className="flex justify-center">
                              <button
                                className="bg-primary text-light py-1 px-8 font-inter text-sm rounded mt-3"
                                onClick={() => moveTicket(ticket)}
                              >
                                {props?.updateTicketIsLoading ? (
                                  <div>
                                    <FaSpinner className="animate-spin" />
                                    <span className="sr-only"> Loading...</span>
                                  </div>
                                ) : (
                                  "Move Ticket"
                                )}
                              </button>
                            </div>
                          </div>
                        )}
                    </div>
                    <div className="shadow-[1px_1px_10px_rgba(0,0,0,0.08)] rounded-[14.17px] bg-white">
                      <div onClick={() => editModal(ticket)}>
                        <div className="px-[17.01px] pt-[17.01px]"></div>
                        <div className=" px-[17.01px] pt-[17.01px]   ">
                          <div>
                            <div className=" ">
                              <h5 className=" one-line-ellipsis leading-[15.7px] text-[10px]  !font-PJ-semibold capitalize">
                                {ticket?.title}
                              </h5>
                            </div>
                          </div>
                          <div>
                            <div className=" w-full z-[999]">
                              <p className="two-line-ellipsis  !font-PJ-medium text-[10px] leading-[15.7px] mt-[6px]  pt-4 text-[#212636] ">
                                {ticket?.description}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="2xl:flex  px-[17.01px] rounded-b-[14.17px]  gap-2 pb-[17.01px]  ">
                        <div
                          className={`relative ${
                            ticket?.assigned_to?.name ? "bg-[#F2F1F4]" : ""
                          }  rounded-[5.67px] py-[2.83px] px-6 2xl:w-[50%]  mt-[6px] flex items-center `}
                        >
                          <div className=" flex justify-between items-center ">
                            {ticket.assigned_to.name && (
                              <div className="">
                                <p className="text-[9px] font-inter leading-[12.75px] tracking-[0.11px] ">
                                  {ticket?.assigned_to?.name}
                                </p>
                              </div>
                            )}
                            <div>
                              <div className=" top-0 2xl:top-[2.5px] cursor-pointer left-1 absolute">
                                <span
                                  onClick={() => {
                                    setDropOpen(!dropOpen);
                                    setTicketId(ticket?.ticket_id);
                                  }}
                                  className="h-[14px] w-[14px] bg-light border-white border-[0.53px] rounded-full text-[5.25px]  flex justify-center items-center font-semibold font-inter"
                                >
                                  {searchQuery && ticket?.ticket_id === ticketId
                                    ? `${searchQuery
                                        ?.split(" ")[0]
                                        ?.charAt(0)
                                        .toUpperCase()}${
                                        searchQuery?.split(" ")[1]
                                          ? searchQuery
                                              ?.split(" ")[1]
                                              ?.charAt(0)
                                              .toUpperCase()
                                          : ""
                                      }`
                                    : ticket?.assigned_to?.name
                                    ? `${ticket?.assigned_to?.name
                                        ?.split(" ")[0]
                                        ?.charAt(0)
                                        .toUpperCase()}${
                                        ticket?.assigned_to?.name?.split(" ")[1]
                                          ? ticket?.assigned_to?.name
                                              ?.split(" ")[1]
                                              ?.charAt(0)
                                              .toUpperCase()
                                          : ""
                                      }`
                                    : "?"}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div>
                            {dropOpen && ticket?.ticket_id === ticketId && (
                              <div ref={node}>
                                <div className="mt-1 border px-4 py-2 absolute right-54 shadow-sm z-[999] bg-white rounded-md">
                                  <div className="">
                                    <div className="flex border-b">
                                      <input
                                        className=""
                                        type="text"
                                        // value={search}
                                        onChange={handleSearch}
                                        onFocus={() => setIsDropdownOpen(true)}
                                        onBlur={() => setIsDropdownOpen(false)}
                                        placeholder="Search User"
                                      />
                                      <span className="search-icon mt-4">
                                        <i className="ri ri-search-line " />
                                      </span>
                                    </div>
                                    {filteredOptions?.map((option, index) => (
                                      <div
                                        key={index}
                                        onClick={() =>
                                          handleOptionSelect(option)
                                        }
                                        style={{
                                          padding: "4px 2px 2px 2px",
                                          cursor: "pointer",
                                          borderBottom:
                                            index !== filteredOptions.length - 1
                                              ? "1px solid #eee"
                                              : "none",
                                        }}
                                      >
                                        <div
                                          className="dropdownlist"
                                          style={{
                                            display: "flex",
                                            gap: "12px",
                                          }}
                                        >
                                          <span className=" bg-primary text-light w-10 h-10 rounded-full text-center flex justify-center items-center font-PJ-semibold text-sm">
                                            {option?.name
                                              ?.split(" ")[0]
                                              ?.charAt(0)
                                              ?.toUpperCase()}
                                            {option?.name?.split(" ")[1]
                                              ? option?.name
                                                  .split(" ")[1]
                                                  .charAt(0)
                                                  .toUpperCase()
                                              : ""}
                                          </span>
                                          <div style={{ fontSize: "14px" }}>
                                            <div
                                              style={{
                                                fontWeight: "bold",
                                              }}
                                            >
                                              {option.name}
                                            </div>
                                            <div>{option.email}</div>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className=" justify-between items-center 2xl:w-[50%]">
                          <div
                            className="!text-[10px] font-inter  p-1 rounded-lg bg-[#F2F1F4] mt-[6px] leading-[14.75px]  text-[#212636]"
                            onClick={() =>
                              handleDescriptionClick(val?.toLowerCase())
                            }
                          >
                            <p className=" one-line-ellipsis">
                              ID:{ticket?.ticket_id}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
