import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "remixicon/fonts/remixicon.css";
import { Provider } from "react-redux";
import store from "./redux/store";
import { Toaster } from "react-hot-toast";
import { FaCheckCircle } from "react-icons/fa";
// import * as serviceWorkerRegistration from "./serviceWorkerRegistration";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <div>
          <Toaster
            containerStyle={{
              animation: "slideInRight 5.8s ease",
            }}
            reverseOrder={false}
            toastOptions={{
              position: "top-right",
              duration: 4000,
              style: {
                background: "#333",
                color: "#fff",
                minWidth: "250px",
                height: "70px",
                boxShadow: "none",
              },
              success: {
                duration: 4000,

                icon: (
                  <div className="bg-[#DFF0DC] flex justify-center items-center rounded-full w-7 h-7">
                    <FaCheckCircle className="bg-[#DFF0DC] text-[#168607] w-4 h-4 rounded-full" />
                  </div>
                ),
              },
            }}
          />
          <App />
        </div>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);
// serviceWorkerRegistration.register();

reportWebVitals();
