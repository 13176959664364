import React, { useEffect, useState } from "react";
import { useLocation, Link, useSearchParams } from "react-router-dom";

const Breadcrumb = () => {
  const location = useLocation();
  const exceptions = ["teams", "marketplace",""];
  const { state, pathname } = location;

  const [paths, setPaths] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [tab, setTab] = useState("");

  useEffect(() => {
    setTab(searchParams.get("tab"));
  }, [searchParams]);

  useEffect(() => {
    if (pathname) {
      const _paths = pathname.split("/");
      _paths.shift();
      setPaths(_paths);
    }
  }, [pathname]);

  return (
    <>
      <nav aria-label="mt-3">
        <ol className="flex items-center">
          {paths.map(
            (path, i) =>
              !exceptions.includes(path) && (
                <li
                  key={i}
                  className={`font-inter mt-2 text-[13px] font-medium ${
                    i === 0 ? "text-[#777777]" : "*:text-black "
                  }`}
                >
                  <Link
                    className="capitalize"
                    to={i ? pathname : path}
                    state={{ ...state }}
                  >
                    {!!i && <span className="ms-1"> &gt; </span>}
                    {path.replaceAll("-", " ") || "Dashboard"}
                  </Link>

                  {tab && tab.length && (
                    <Link
                      className="capitalize text-black"
                      to={i ? pathname : tab}
                      state={{ ...state }}
                    >
                      <span className="ms-1"> &gt; </span>

                      {tab.replaceAll("-", " ")}
                    </Link>
                  )}
                </li>
              )
          )}
        </ol>
      </nav>
    </>
  );
};

export default Breadcrumb;
