import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { allEscalationConfigRequest } from "../../../redux/features/configurations/escalationConfig/escalationConfigSlice";
import Escard from "../../../reuseable/escard";
import slack from "../../../assets/configuration/Slack.svg";
import whatsApp from "../../../assets/configuration/Slack-1.svg";
import teams from "../../../assets/configuration/teams.svg";
import jira from "../../../assets/configuration/jira.svg";
import email from "../../../assets/configuration/email.png"; // Add your email icon here
import { MainLoader } from "../../utils";

export default function EscalationConfigComp() {
  const [page, setPage] = useState("slack");
  const [token, setToken] = useState("");
  const [channelId, setChannelId] = useState("");
  const [loading, setLoading] = useState(false);
  const [checkedStates, setCheckedStates] = useState({});

  const escalationConfigState = useSelector(
    (state) => state.escalationConfigReducer
  );

  let dispatch = useDispatch();
  let orgId = Cookies.get("orgId") || "";

  useEffect(() => {
    getSlackEscalations();
  }, []);

  let getSlackEscalations = () => {
    setLoading(true);

    let data = {
      organization_id: orgId,
      target: "slack",
    };
    dispatch(allEscalationConfigRequest(data))
      .then((res) => {
        setLoading(false);

        if (res?.payload?.status === 200) {
          setChannelId(res?.payload?.data?.target_config?.channel_id);
          setToken(res?.payload?.data?.target_config?.token);
          return;
        } else {
          toast.error(res?.payload?.data?.response);
        }
      })
      .catch((err) => {});
  };

  const comingSoonEscalations = [
    {
      paragraph: "Teams",
      desclink: "Learn more about Teams configurations here",
      cardPics: teams,
    },
    {
      paragraph: "Whatsapp",
      desclink: "Learn more about Whatsapp configurations here",
      cardPics: whatsApp,
    },
    {
      paragraph: "Jira",
      desclink: "Learn more about jira configurations here",
      cardPics: jira,
    },
  ];

  const currentEscalations = [
    {
      target: "Slack",
      desclink: "Learn more about Slack configurations here",
      cardPics: slack,
      button: "Unlink Slack",
      active: true,
    },
    {
      target: "Email",
      desclink: "Learn more about Email configurations here",
      cardPics: email,
      button: "Unlink Email",
      active: true,
    },
    ...(Array.isArray(escalationConfigState?.allConfig?.data)
      ? escalationConfigState.allConfig.data
      : []),
  ];

  const handleToggle = (index) => {
    setCheckedStates((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  return (
    <div className="px-5 py-5">
      {loading ? (
        <MainLoader />
      ) : (
        <div className="mb-12">
          <div>
            <h1 className="font-PJ-bold text-[28px]">
              Escalation Configurations
            </h1>
            <p className="font-inter mb-[43px] text-[#111111] text-base text-[#111111CC] opacity-[80%] leading-[24px] tracking-[2%] text-[16px]">
              Supercharge your workflow and link your escalation channel
            </p>
          </div>

          <div className="grid lg:grid-cols-2 md:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-3 gap-x-[16px] gap-y-5">
            {currentEscalations.map((cs, index) => (
              <Escard
                paragraph={cs?.target}
                desclink={cs?.desclink}
                cardPics={cs?.cardPics}
                button={cs?.button}
                to={"#"}
                key={index}
                checked={checkedStates[index] || false}
                setChecked={() => handleToggle(index)}
                isActive={cs?.active}
                onToogle={(on) => console.log(on)}
              />
            ))}

            {comingSoonEscalations.map((cs, index) => (
              <Escard
                paragraph={cs.paragraph}
                desclink={cs.desclink}
                cardPics={cs.cardPics}
                hideButtonArrow={true}
                button={"Coming Soon"}
                key={index + currentEscalations.length}
                checked={true}
                setChecked={() => {}}
                onToogle={(on) => console.log(on)}
                isActive={false}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
