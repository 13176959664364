import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";
import { authorizationRedirect, serverCodes } from "../../constants/api";
import global from "../../constants/global";

let accessT = Cookies.get("babtbu") || "";
let orgId = Cookies.get("orgId") || "";

export const allCustomersRequest = createAsyncThunk(
  "customers/allCustomers",
  async () => {
    let config = {
      method: "post",
      url: `${global.apiBaseUrl}customers/get`,
      headers: {
        Authorization: `Bearer ${accessT}`,
        "Content-Type": "application/json",
      },
      data: {
        organisation_id: orgId,
      },
    };

    let resp = null;

    await axios
      .request(config)
      .then((response) => {
        resp = response;
      })
      .catch((error) => {
        if (
          serverCodes.includes(error?.request?.status) ||
          !error?.request?.status
        ) {
          resp = {
            data: {
              response: "Oops, Something went wrong, please try again later.",
            },
          };
          return;
        } else if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          authorizationRedirect();
        } else {
          resp = error?.response;
        }
      });
    return resp;
  }
);

export const createCustomerRequest = createAsyncThunk(
  "customers/create",
  async (data) => {
    let config = {
      method: "post",
      url: `${global.apiBaseUrl}customers/create`,
      headers: {
        Authorization: `Bearer ${accessT}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    let resp = null;

    await axios
      .request(config)
      .then((response) => {
        resp = response;
      })
      .catch((error) => {
        if (
          serverCodes.includes(error?.request?.status) ||
          !error?.request?.status
        ) {
          resp = {
            data: {
              response: "Oops, Something went wrong, please try again later.",
            },
          };
          return;
        } else if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          authorizationRedirect();
        } else {
          resp = error?.response;
        }
      });
    return resp;
  }
);

export const customersSlice = createSlice({
  name: "customers",
  initialState: {
    isLoading: false,
    allCustomer: null,
    updateCustomer: null,
    updateIsLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(allCustomersRequest.pending, (state, action) => {
      // if (!state.isLoading) {
      state.isLoading = true;
      // }
    });
    builder.addCase(createCustomerRequest.pending, (state, action) => {
      // if (!state.isLoading) {
      state.updateIsLoading = true;
      // }
    });

    builder.addCase(allCustomersRequest.fulfilled, (state, action) => {
      // if (state.isLoading ) {
      state.allCustomer = action?.payload;
      state.isLoading = false;
      // }
    });
    builder.addCase(createCustomerRequest.fulfilled, (state, action) => {
      // if (state.isLoading ) {
      state.updateCustomer = action?.payload;
      state.updateIsLoading = false;
      // }
    });

    builder.addCase(allCustomersRequest.rejected, (state, action) => {
      // if (state.isLoading ) {
      state.error = action?.payload;
      state.isLoading = false;
      // }
    });
    builder.addCase(createCustomerRequest.rejected, (state, action) => {
      // if (state.isLoading ) {
      state.updateIsLoading = false;
      state.error = action?.payload;
      // }
    });
  },
});

export default customersSlice.reducer;
