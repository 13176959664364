import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  allDataConfigRequest,
  dataUploadRequest,
  fileUploadRequest,
} from "../../../redux/features/configurations/dataConfig/dataConfigSlice";
import {
  ActiveTag,
  ActiveTags,
  InactiveTag,
  InactiveTags,
  MainLoader,
} from "../../utils";
import { BsUpload } from "react-icons/bs";
import ClickOutside from "../../utils/helpers";

import edit from "../../../assets/edit-02.svg";
import del from "../../../assets/trash-2.svg";
import { Link } from "react-router-dom";
import emptystate from "../../../assets/Cybersecurity and digital data protection by password.svg";
import Header from "../../../reuseable/header";
import { FaChevronDown, FaSpinner, FaUpload } from "react-icons/fa";
import Table from "../../../reuseable/table/Table";
import { LuUpload } from "react-icons/lu";
import { hr } from "date-fns/locale";
import moment from "moment";
import CustomDropdown from "../../../reuseable/dropDown";
import Pagination from "../../../reuseable/Pagination";

export default function DataConfigComp() {
  const [configModal, setConfigModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [docType, setDocType] = useState("");
  const [doc, setDoc] = useState(null);
  const [url, setUrl] = useState("");
  const [possibleQuestion, setPossibleQuestion] = useState("");
  const [possibleAnswer, setPossibleAnswer] = useState("");
  const [isEdit, setIsEdit] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedRole, setSelectedRole] = useState("");
  const [allConfigurationData, setAllConfigurationData] = useState([]);
  const [ind, setInd] = useState(0);
  const [singleConfigurationData, setSingleConfigurationData] = useState({});
  const [search, setSearch] = useState("");
  const [dataConfigureDropdown, setdataConfigureDropdown] = useState(false);
  const [addTeamModal, setAddTeamModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const itemsPerPage = 10;

  const dataConfigState = useSelector((state) => state.dataConfigReducer);

  let dispatch = useDispatch();

  let orgId = Cookies.get("orgId") || "";

  useEffect(() => {
    getAllDataConfigs();
  }, []);

  let getAllDataConfigs = () => {
    setLoading(true);
    let data = {
      organization_id: orgId,
    };

    dispatch(allDataConfigRequest(data))
      .then((res) => {
        //
        setLoading(false);
        if (res?.payload?.status === 200) {
          return;
        } else {
          toast.error(res?.payload?.data?.response);
        }
      })
      .catch((err) => {});
  };

  let uploadFileData = () => {
    let fileData = new FormData();
    fileData.append("files", doc);

    if (!doc) {
      toast.error("Please upload a documentation file");
      return;
    }

    dispatch(fileUploadRequest(fileData))
      .then((res) => {
        if (res?.payload?.status === 200) {
          fileDataConfigUpload(res?.payload?.file_paths);
          return;
        } else {
          toast.error(res?.payload?.data?.response);
        }
      })
      .catch((err) => {});
  };

  let fileDataConfigUpload = (filePath) => {
    let data = {
      organization_id: orgId,
      name: `FileConnector-${doc?.name?.replaceAll(".txt", "")}-${
        doc?.lastModified
      }`,
      source: "file",
      input_type: "load_state",
      connector_specific_config: {
        // file_locations:["/home/file_connector_storage/1797570c-023d-40e3-9be0-4fd305a6e6ae/test_prembly2 2.txt"]
        file_locations: filePath,
      },
      refresh_freq: null,
      disabled: false,
    };

    dispatch(dataUploadRequest(data))
      .then((res) => {
        if (res?.payload?.status === 200) {
          toast.success("File Successfully Uploaded");
          setConfigModal(false);
          setDoc(null);
          setUrl("");
          setPossibleQuestion("");
          setPossibleAnswer("");
          getAllDataConfigs();
        } else {
          toast.error(res?.payload?.data?.response);
        }
      })
      .catch((err) => {});
  };

  let urlDataConfigUpload = () => {
    let data = {
      organization_id: orgId,
      name: `WebConnector-${url}`,
      source: "web",
      input_type: "load_state",
      connector_specific_config: {
        base_url: url,
      },
      refresh_freq: 86400,
      disabled: false,
    };

    dispatch(dataUploadRequest(data))
      .then((res) => {
        //
        if (res?.payload?.status === 200) {
          toast.success("URL Successfully Uploaded");
          setConfigModal(false);
          setDoc(null);
          setUrl("");
          setPossibleQuestion("");
          setPossibleAnswer("");
          getAllDataConfigs();
        } else {
          toast.error(res?.payload?.data?.response);
        }
      })
      .catch((err) => {});
  };

  let textDataConfigUpload = () => {
    let data = {
      organization_id: orgId,
      name: `WebConnector-${possibleQuestion}`,
      source: "text",
      input_type: "load_state",
      connector_specific_config: {
        question: possibleQuestion,
        answer: possibleAnswer,
      },
      refresh_freq: 86400,
      disabled: false,
    };

    dispatch(dataUploadRequest(data))
      .then((res) => {
        if (res?.payload?.status === 200) {
          toast.success("Data Successfully Added");
          setConfigModal(false);
          setDoc(null);
          setUrl("");
          setPossibleQuestion("");
          setPossibleAnswer("");
          getAllDataConfigs();
        } else {
          toast.error(res?.payload?.data?.response);
          toast.error(res?.payload?.data?.detail);
        }
      })
      .catch((err) => {});
  };

  const dataConfigureDropdowns = (data, index) => {
    setdataConfigureDropdown(true);
    setSingleConfigurationData(data);
    if (data && isEdit === "edit") {
      setEditMode(true);
    } else {
      setEditMode(false);
    }
  };

  const dropdownOptions = [
    { value: "file", label: "Documentation File" },
    { value: "url", label: "Documentation URL" },
    { value: "manual", label: "Manual" },
  ];

  const handleEdit = (data) => {
    setSingleConfigurationData(data);
    setConfigModal(true);
    setDocType({
      label:
        data.source === "text"
          ? "Manual"
          : data.source === "web"
          ? "Documentation URL"
          : "Documentation File",
      value:
        data.source === "text"
          ? "manual"
          : data.source === "web"
          ? "url"
          : "file",
    });
    // setDoc(data.doc);
    setUrl(data.connector_specific_config.base_url);
    setPossibleQuestion(data.connector_specific_config.question);
    setPossibleAnswer(data.connector_specific_config.answer);

    // setIsEdit(true);
  };

  const showDeleteConfirmation = () => {
    setShowDeleteModal(true);
    setdataConfigureDropdown(false);
  };

  const hideDeleteConfirmation = () => {
    setShowDeleteModal(false);
  };

  const handleDelete = () => {
    // dispatch(
    //   deleteConfigurationRequest({
    //     Configuration_id: singleConfigurationData?.Configuration_id,
    //   })
    // )
    // .then((res) => {
    //   setShowDeleteModal(false);
    //   ;
    //   if (res?.payload?.status === 200) {
    //     toast.success("Configuration Data Deleted Successfully");
    //     getAllDataConfigs();
    //   } else {
    //     toast.error(res?.payload?.data?.detail);
    //   }
    // })
    // .catch((err) => {
    //   ;
    // });
  };
  const node = ClickOutside(() => {
    setdataConfigureDropdown(false);
  });

  useEffect(() => {
    setTotalPages(
      Math.ceil(dataConfigState?.allConfig?.data?.length / itemsPerPage)
    );
  }, [dataConfigState?.allConfig?.data?.length]);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  const columns = [
    {
      Header: "S/N",
      accessor: "id",
      Cell: (row) => (
        <div className="font-PJ-bold font-medium text-[12px] text-[#424242]">
          {currentPage > 0
            ? itemsPerPage * currentPage + parseInt(row.cell.row.id) + 1
            : parseInt(row.cell.row.id) + 1}
        </div>
      ),
    },
    {
      Header: "Name",
      accessor: "name",
      Cell: (row) => (
        <div className="font-PJ-bold font-medium text-[14px] text-[#101010]">
          {row.cell.value}
        </div>
      ),
    },
    {
      Header: "Status",
      accessor: "disabled",
      Cell: (row) => (
        <div className="font-PJ-bold font-medium text-[12px]">
          {row.cell.value ? <InactiveTags /> : <ActiveTags />}
        </div>
      ),
    },
    {
      Header: "Time Uploaded",
      accessor: "time_created",
      Cell: (row) => (
        <div className="font-inter font-normal text-[14px] text-[#424242] ">
          {moment(row.cell.value).format("MMM DD, yyyy") || "Not Available "}
        </div>
      ),
    },
    {
      Header: "Source",
      accessor: "source",
      Cell: (row) => (
        <div className="font-PJ-bold font-medium text-[12px] text-[#404040] ">
          {row.cell.value}
        </div>
      ),
    },
    {
      Header: " ",
      accessor: "action",
      Cell: (row) => (
        <div>
          <div
            className="dropdown-trigger"
            onClick={() => {
              setdataConfigureDropdown(true);
              setInd(row.cell.row.original.id);
            }}
          >
            <i className="ri-more-fill me-2 ri-xl" />
          </div>
          {row.cell.row.original.id === ind && dataConfigureDropdown && (
            <div ref={node} className="dropdown-content">
              <button
                className="editDrop"
                onClick={() => {
                  handleEdit(row.cell.row.original);
                  setdataConfigureDropdown(false);
                }}
              >
                <img src={edit} alt="edit" />
                Edit Configuration
              </button>
              <p className="deleteDrop" onClick={showDeleteConfirmation}>
                <img src={del} alt="edit" />
                Delete Configuration
              </p>
            </div>
          )}
          {/* Show delete modal */}
          {showDeleteModal && (
            <>
              <div className="overlay fixed inset-0 bg-black opacity-50"></div>
              <div className="delete-modal fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg shadow-lg p-4">
                <div className="delete-modal-content py-5">
                  <div className="flex gap-4 items-center">
                    <div className="deleteBin">
                      <img src={del} alt="delete" />
                    </div>
                    <div className="modalBody">
                      <p className="font-semibold text-lg">
                        This action cannot be undone
                      </p>
                      <p className="text-base">
                        Are you sure you want to delete this Configuration?
                      </p>
                    </div>
                  </div>
                  <hr className="my-4 border-gray-400" />
                  <div className="delete-modal-buttons flex justify-end">
                    <button
                      className="mr-2 px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
                      onClick={hideDeleteConfirmation}
                    >
                      Cancel
                    </button>
                    <button
                      className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                      onClick={handleDelete}
                    >
                      Delete Configuration
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className="">
      {configModal && (
        <div className="fixed inset-0 z-[999] flex items-center justify-center bg-black backdrop-blur-xs bg-opacity-50">
          <div className="bg-white  rounded-2xl w-[30%] shadow-xl ">
            <div className="px-6 py-3">
              <div className="flex justify-between items-center">
                <h5 className="text-xl font-PJ-medium font-medium text-[#101010] leading-[25.2px] p-2">
                  Configure Data
                </h5>
                <span
                  onClick={() => setConfigModal(false)}
                  className="text-gray-500 cursor-pointer"
                >
                  <i className="ri-close-line" />
                </span>
              </div>
              <hr />

              <div className="mt-3">
                <div>
                  <label htmlFor="dataType">
                    <p className="block px-2 mt-3 mb-3 font-inter font-medium text-lg leading-[21.78px] text-[#323232]">
                      Documentation Type
                    </p>
                  </label>
                  <div className="relative z-[1000]">
                    <CustomDropdown
                      options={dropdownOptions}
                      selectedOption={docType.label}
                      onChange={(option) => setDocType(option.value)}
                      placeholder="Select Documentation Type"
                      defaultValue={dropdownOptions.find(
                        (option) => option.value === docType
                      )}
                    />
                  </div>
                </div>

                {docType === "file" && (
                  <div className="">
                    <label htmlFor="documentFile">
                      {" "}
                      <p className="block mt-8 mb-1 font-inter font-medium text-lg leading-[21.78px] text-[#323232] ">
                        Documentation File
                      </p>
                    </label>
                    {/* <p className="font-medium font-inter text-sm leading-[21px] mb-[18px] ">
                      This is a placeholder for body text
                    </p> */}
                    {!doc ? (
                      <div className="file-input-area border border-[#B5B5B5] rounded-sm">
                        <div className="bg-white p-6">
                          <label htmlFor="fileInput" className="cursor-pointer">
                            <input
                              id="fileInput"
                              className="hidden"
                              type="file"
                              accept=".txt"
                              onChange={(doc) => {
                                setDoc(doc.target.files[0]);
                                const fileInputLabel =
                                  document.getElementById("fileInputLabel");
                                if (fileInputLabel) {
                                  fileInputLabel.style.display = "none";
                                }
                              }}
                            />
                            <div className=" bg-[#010101] h-11 w-11 flex justify-center mb-[10px] align-middle rounded-full border-[#010101]">
                              <LuUpload className="text-[#E5C9FF] w-[15px] h-[15px]" />
                            </div>
                          </label>
                          <div className="font-medium text-4 leading-[19.36px] text-[#8B8B8B]">
                            <p className="">Maximum file size: 2MB</p>
                            <p className="">Supported file types: (.txt).</p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="">
                        <div className=" py-1">
                          <div className=" justify-between ">
                            <div className="">
                              <p className="p-0">
                                Document {doc?.name?.substr(-4)}
                              </p>
                            </div>
                            <div className="">
                              <div className="flex justify-end align-center">
                                <i
                                  className=" ri-close-circle-fill ri-lg"
                                  style={{
                                    cursor: "pointer",
                                    marginTop: "10px",
                                    color: "#E95470",
                                  }}
                                  onClick={() => {
                                    setDoc("");
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {(docType === "url" ||
                  singleConfigurationData.source === "web") && (
                  <div className="px-2">
                    <label htmlFor="documentUrl">
                      <p className="block mt-3 mb-1 font-inter font-medium text-lg leading-[21.78px] text-[#323232]  ">
                        Documentation URL{" "}
                      </p>
                    </label>
                    {/* <p className="font-medium font-inter text-sm leading-[21px] mb-[18px] ">
                      This is a placeholder for body text
                    </p> */}
                    <input
                      type="url"
                      className="form-control border border-[#B5B5B5] mb-8 w-full pl-5"
                      onChange={(e) => setUrl(e.target.value)}
                      placeholder="https://"
                      required
                      value={url}
                    />
                  </div>
                )}
                {(docType === "manual" ||
                  singleConfigurationData.source === "text") && (
                  <div className="mt-4 px-2">
                    <h6 className="block mt-3  mb-1 font-inter font-medium text-lg leading-[21.78px] text-[#323232]  ">
                      Manual
                    </h6>
                    {/* <p className="font-medium font-inter text-sm leading-[21px] mb-[18px] ">
                      This is a placeholder for body text
                    </p> */}
                    <div className="">
                      <p className="">Possible Question</p>
                      <textarea
                        value={possibleQuestion}
                        className="form-control w-full mt-2 mb-4 rounded-lg pl-5 pt-5 border border-[#B5B5B5]"
                        onChange={(e) => setPossibleQuestion(e.target.value)}
                        rows="3"
                        placeholder="what is your monthly pricing?"
                      ></textarea>
                    </div>
                    <div className="">
                      <p>Possible Answer</p>
                      <textarea
                        value={possibleAnswer}
                        className="form-control w-full mt-2 rounded-lg pl-5 pt-5 border border-[#B5B5B5]"
                        rows="3"
                        onChange={(e) => setPossibleAnswer(e.target.value)}
                        placeholder="we price you based on what you are doing?"
                      ></textarea>
                    </div>
                  </div>
                )}
                <hr className="mt-4 !px-0" />
                {docType && (
                  <button
                    className=" bg-[#010101] p-2 flex justify-center items-center rounded-lg text-[#E5C9FF] w-[30%] font-inter font-medium text-base   mt-5"
                    onClick={
                      docType === "file"
                        ? uploadFileData
                        : docType === "url"
                        ? urlDataConfigUpload
                        : docType === "manual"
                        ? textDataConfigUpload
                        : ""
                    }
                  >
                    {dataConfigState.updateIsLoading ? (
                      <div>
                        <FaSpinner className="animate-spin" />

                        <span className="sr-only"> Loading...</span>
                      </div>
                    ) : (
                      `${
                        docType === "file"
                          ? "Add File"
                          : docType === "url" ||
                            singleConfigurationData.source === "web"
                          ? "Add URL"
                          : docType === "manual" ||
                            singleConfigurationData.source === "text"
                          ? "Add Data"
                          : ""
                      }`
                    )}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        // </div>
      )}
      {loading ? (
        <MainLoader />
      ) : (
        <>
          <div className="mt-4 px-4">
            <div className="">
              <div className="flex justify-between">
                <div className=" ">
                  <div className=" my-4">
                    <h3 className="font-PJ-medium font-medium text-xl leading-[25px] text-[#101010] ">
                      Data Configuration
                    </h3>
                    <p className="m-0 font-inter font-normal text-sm leading-[21px] ">
                      Feed Your AI System using Documentation File,
                      Documentation URL, or Manually
                    </p>
                  </div>
                </div>
                <div className=" ">
                  {dataConfigState?.allConfig?.data?.length > 0 && (
                    <div className="flex justify-between gap-10">
                      <button
                        className=" configButton "
                        onClick={() => {
                          setConfigModal(true);
                          setDocType("");
                          setSingleConfigurationData({});
                          setUrl("");
                          setPossibleQuestion("");
                          setPossibleAnswer("");
                        }}
                      >
                        <BsUpload size={20} /> Upload Data
                      </button>
                    </div>
                  )}
                </div>
              </div>

              <div className="mt-4">
                {dataConfigState?.allConfig?.data?.length > 0 ? (
                  <div className="">
                    <div className="w-full ">
                      <Table
                        columns={columns}
                        data={dataConfigState?.allConfig?.data}
                        currentPage={currentPage}
                        itemPerPage={itemsPerPage}
                      />
                    </div>
                    <div className="py-2 flex justify-end items-end">
                      {dataConfigState?.allConfig?.data?.length > 0 && (
                        <Pagination
                          onChange={handlePageChange}
                          pageCount={totalPages}
                          initialPage={currentPage}
                        />
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="flex justify-center mb-11 ">
                    <div className="flex justify-center items-center flex-col text-center">
                      <img sizes={270} src={emptystate} alt="No data" />
                      <p className="font-normal leading-[27px] text-lg mb-5 w-[368px]">
                        You currently have no data. Click on “Upload data” to
                        add data configuration
                      </p>
                      <button
                        className=" configButton"
                        onClick={() => setConfigModal(true)}
                      >
                        Upload Data
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
