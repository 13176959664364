import React, { useState } from "react";
import { atomOneDark, CopyBlock } from "react-code-blocks";
import { BsClipboard, BsCopy, BsWordpress } from "react-icons/bs";
import { RiFlutterFill, RiJavascriptFill } from "react-icons/ri";
import { useSelector } from "react-redux";

export default function IntegrationConfigComp() {
  const [page, setPage] = useState("js");
  const [copied, setCopied] = useState(false);

  const profileState = useSelector((state) => state.profileReducer);

  let integrationData = `<script type="text/javascript">
		(function(w, d, v, s, x, t, f, c) {
              w[v] = w[v] || { sync: true, signature: x, localization: 'all' };
              t = d.createElement(s); t.async = 1; t.src = 'https://lib.venixs.com/widget.js';
              f = d.getElementsByTagName(s)[0], c = () => f.before(t);
              (() => { (d.readyState === "complete") ? c.call() : w.addEventListener('load', c, false); })();
          })(window, document, "venixsVariables", "script", "${profileState?.profileInfo?.data?.public_key}");
</script>`;

  let integrationFlutterData1 = `
  
  final Venixs venixSdk = Venixs.instance;

    Future<void> main() async {
      WidgetsFlutterBinding.ensureInitialized();
      SystemChrome.setPreferredOrientations([DeviceOrientation.portraitUp])
          .then((_) async {
            await Venixs.initializeInterface(publicKey: '${profileState?.profileInfo?.data?.public_key}');
            runApp(const MyApp());
          },
      );
    }

   
`;
  let integrationFlutterData2 = `
    venixSdk.initiate(
      context,
      email: 'example@email.com',
      firstName: 'John',
      lastName: 'Doe',
      onCancel: (response) => print(response),
      onError: (error) => print(error),
    ),
`;

  return (
    <div className="mt-11  ">
      <div className="">
        <div className="">
          <div className="">
            <div className="">
              <ul className="flex gap-4 cursor-pointer ">
                <li
                  className={`${
                    page === "js"
                      ? "active rounded-[25px] font-inter font-medium   flex justify-center items-center px-4 bg-[#EAD6FF]"
                      : "border-[#51376A] border  font-inter font-medium text-sm px-4 text-center flex items-center rounded-[25px] text-[#51376A]"
                  } font-inter font-medium h-11 shadow-lg `}
                  onClick={() => setPage("js")}
                >
                  <p className="flex gap-2 items-center">
                    Javascript <RiJavascriptFill size={20} />{" "}
                  </p>
                </li>
                <li
                  className={`${
                    page === "flutter"
                      ? "active rounded-[25px] font-inter font-medium   flex justify-center items-center px-4 bg-[#EAD6FF]"
                      : "border-[#51376A] border  font-inter font-medium text-sm px-4 text-center flex items-center rounded-[25px] text-[#51376A]"
                  } font-inter font-medium h-11 shadow-lg `}
                  onClick={() => setPage("flutter")}
                >
                  <p className="flex justify-between gap-2 items-center">
                    Flutter <RiFlutterFill />
                  </p>
                </li>
                <li
                  className={`${
                    page === "wordPress"
                      ? "active rounded-[25px] font-inter font-medium   flex justify-center items-center px-4 bg-[#EAD6FF]"
                      : "border-[#51376A] border  font-inter font-medium text-sm px-4 text-center flex items-center rounded-[25px] text-[#51376A]"
                  } font-inter font-medium h-11 shadow-lg `}
                  onClick={() => setPage("wordPress")}
                >
                  <p className="flex justify-between gap-2 items-center">
                    Word Press <BsWordpress />
                  </p>
                </li>
                <li
                  className={`${
                    page === "reactNative"
                      ? "active rounded-[25px] font-inter font-medium   flex justify-center items-center px-4 bg-[#EAD6FF]"
                      : "border-[#51376A] border  font-inter font-medium text-sm px-4 text-center flex items-center rounded-[25px] text-[#51376A]"
                  } font-inter font-medium h-11 shadow-lg `}
                  onClick={() => setPage("reactNative")}
                >
                  <p>
                    React Native <span>(coming soon )</span>{" "}
                  </p>
                </li>
              </ul>
            </div>
            <hr className="mb-7 mt-7" />
          </div>
        </div>

        <div className="">
          <div className="">
            <div className="">
              {page === "js" && (
                <div className="shadow-inner border p-4 rounded-md ">
                  <div className=" text-center mb-10">
                    <h3 className="font-PJ-semibold font-semibold text-2xl">
                      Javascript Integration
                    </h3>
                    <p className="font-regularInter font-normal text-base">
                      Copy and paste below Javascript snippet into your code (preferably footer) 
                    </p>
                  </div>
                  <div className="mb-11 shadow-custom">
                    <CopyBlock
                      text={integrationData}
                      language={"javascript"}
                      theme={atomOneDark}
                      showLineNumbers={true}
                      wraplines={true}
                      codeBlock={true}
                    />
                  </div>
                </div>
              )}
              {page === "flutter" && (
                <div className="shadow-inner border p-4 rounded-md ">
                  <div className=" text-center mb-10">
                    <h3 className="font-PJ-semibold font-semibold text-2xl">
                      Flutter Integration
                    </h3>
                    <p className="font-regularInter font-normal text-base">
                      Integrate Your Flutter Widget
                    </p>
                  </div>
                  <div className="mb-11">
                    <p>
                      1. Add flutter package plugin This will add a line like
                      this to your package's pubspec.yaml (and run an
                      implicit flutter pub get):
                    </p>
                    <div className="relative">
                      <p className="bg-light font-inter text-base leading-[20px] tracking-[-4%]  px-4 py-4 rounded-lg shadow-inner mt-4 w-[50%] relative mb-4">
                        dependencies: venixs_sdk: ^0.0.1
                        <button
                          className="absolute top-0 right-0 mt-2 mr-2"
                          onClick={() => {
                            navigator.clipboard.writeText(
                              "dependencies: venixs_sdk: ^0.0.1"
                            );
                            setCopied(true);
                          }}
                        >
                          <BsCopy />
                        </button>
                        {copied && (
                          <p className="absolute top-0 right-10 mt-2">
                            Copied!
                          </p>
                        )}
                      </p>
                    </div>
                    <p className="font-inter  leading-[20px] tracking-[-4%]  text-base mb-4">
                      2. To initiate the plugin, call the plugin instance in
                      your main.dart
                    </p>
                    <CopyBlock
                      text={integrationFlutterData1}
                      language={"Dart"}
                      theme={atomOneDark}
                      showLineNumbers={true}
                      wraplines={true}
                      codeBlock={true}
                    />

                    <p className="font-inter  leading-[20px] tracking-[-4%]  text-base mb-4 mt-4">
                      3. Call the Venixs Chat View method and pass the necessary
                      data to it where it is needed
                    </p>

                    <CopyBlock
                      text={integrationFlutterData2}
                      language={"Dart"}
                      theme={atomOneDark}
                      showLineNumbers={true}
                      wraplines={true}
                      codeBlock={true}
                    />
                  </div>
                </div>
              )}
              {page === "wordPress" && (
                <div className="shadow-inner border p-4 rounded-md">
                  <div className="text-center mb-10">
                    <h3 className="font-PJ-semibold font-semibold text-2xl">
                      Word Press Integration
                    </h3>
                    <p className="font-regularInter font-normal text-base">
                      Integrate Your Word Press Widget
                    </p>
                  </div>
                  <div className="mb-11">
                    <h3 className="font-regularInter font-normal text-base">
                      MINIMUM REQUIREMENTS
                    </h3>
                    <p className="mt-3">1. A Venixs account</p>

                    <h3 className="font-inter mt-6 text-base mb-2">
                      2. AUTOMATIC INSTALLATION
                    </h3>
                    <p className="font-inter text-base mb-6">
                      Automatic installation is the most convenient option
                      because WordPress handles file transfers, and you don’t
                      have to leave your web browser. Here’s how to do an
                      automatic install of the Venixs SDK WordPress plugin:
                    </p>
                    <p className="font-inter text-base mb-6">
                      - Log in to your WordPress dashboard. Navigate to the
                      Plugins menu and Click Add New. Enter “Venixs plugin” in
                      the search field and click Search Plugins. Once you’ve
                      discovered our chat SDK plugin, you can learn more about
                      it by viewing its point release, rating, and description.
                      You can install it simply by clicking “Install Now.”
                    </p>

                    <h3 className="font-inter mt-6 text-base mb-2">
                      3. MANUAL INSTALLATION
                    </h3>
                    <p className="font-inter text-base mb-6">
                      Manual installation entails downloading our chat plugin
                      file and uploading it to your web server using your
                      preferred FTP server. The WordPress codex has instructions
                      for doing this. Go ahead and paste the form shortcode on
                      any page or widget.
                    </p>

                    <h3 className="font-inter mt-6 text-base mb-2">
                      4.CALLING THE PLUGIN
                    </h3>
                    <p className="font-inter text-base mb-6">
                      You don’t need to do much to have your favorite chat SDK
                      on your WordPress app, you only need to install and
                      activate the plugin. A new admin sidebar will be added to
                      your admin area called “Venixs”, you should click on it
                      and navigate to configuration, put in your chat public
                      key, and save. That's all.
                    </p>
                    <p className="font-inter text-base mb-6">
                      Below is your chat public key:
                    </p>
                    <div className="relative">
                      <div className="bg-light font-inter text-base leading-[20px] tracking-[-4%] px-4 py-4 rounded-lg shadow-inner mt-4 w-[50%] relative mb-6">
                        <p className="text-gray-500">Chat public key:</p>
                        <p>{profileState?.profileInfo?.data?.public_key}</p>
                        <button
                          className="absolute top-0 right-0 mt-2 mr-2"
                          onClick={() => {
                            navigator.clipboard.writeText(
                              profileState?.profileInfo?.data?.public_key
                            );
                            setCopied(true);
                          }}
                        >
                          <BsCopy />
                        </button>
                        {copied && (
                          <p className="absolute top-0 right-10 mt-2">
                            Copied!
                          </p>
                        )}
                      </div>
                    </div>
                    <h3 className="font-inter mt-6 text-base mb-2">
                      - Updating
                    </h3>
                    <p className="font-inter text-base mb-4">
                      Automatic updates should work flawlessly; however, ensure
                      you backup your site just in case.
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
