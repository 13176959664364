import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  profileInfoRequest,
  updateProfileInfoRequest,
} from "../../redux/features/profile/profileSlice";
import { passwordChangeRequest } from "../../redux/features/passwordChange/passwordSlice";
import { FaSpinner } from "react-icons/fa";

export default function ProfilePage() {
  const teamDetails = JSON.parse(localStorage.getItem("tm"));
  const [preview_img, setPreview_img] = useState("");
  const [addCusModal, setAddCusModal] = useState(false);
  const [profile_img, setProfile_img] = useState(null);
  const [firstName, setFirstName] = useState(teamDetails?.name.split(" ")[0]);
  const [lastName, setLastName] = useState(teamDetails?.name.split(" ")[1]);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const profileState = useSelector((state) => state.profileReducer);

  let dispatch = useDispatch();
  let orgId = Cookies.get("orgId") || "";

  useEffect(() => {
    getProfileInfo();
  }, []);

  let getProfileInfo = () => {
    dispatch(profileInfoRequest())
      .then((res) => {
        // 
        if (res?.payload?.status === 200) {
          // setFirstName(res?.payload?.data?.contact_first_name)
          // setLastName(res?.payload?.data?.contact_last_name)
        } else {
          toast.error(res?.payload?.data?.response);
        }
      })
      .catch((err) => {
        ;
      });
  };

  let updateProfileInfo = () => {
    let data = new FormData();

    data.append("organization_id", orgId);
    data.append("contact_first_name", firstName);
    data.append("contact_last_name", lastName);
    data.append("image", profile_img);
    dispatch(updateProfileInfoRequest(data))
      .then((res) => {
        ;
        if (res?.payload?.status === 200) {
          toast.success("Profile updated Successfully");
          getProfileInfo();
        } else {
          toast.error(res?.payload?.data?.response);
        }
      })
      .catch((err) => {
        ;
      });
  };

  let changePassword = () => {
    let data = {
      old_password: oldPassword,
      new_password: newPassword,
    };

    if (!oldPassword) {
      toast.error("Input cannot be blank");
      return;
    }
    if (!newPassword) {
      toast.error("Input cannot be blank");
      return;
    }

    dispatch(passwordChangeRequest(data))
      .then((res) => {
        if (res?.payload?.status === 200) {
          toast.success("Password Updated Successfully");
          setAddCusModal(false);
        } else {
          toast.error(res?.payload?.data?.detail);
        }
      })
      .catch((err) => {
        ;
      });
  };

  let imageHandler = (img) => {
    var file = img.target.files[0];
    setProfile_img(img.target.files[0]);
    let reader = new FileReader();

    if (img.target.files.length > 0) {
      setPreview_img(URL.createObjectURL(img.target.files[0]));
      reader.readAsDataURL(file);
      // reader.onload = () => {
      //     setProfile_img(reader.result)
      // };
    }
  };

  // console.log(profileState?.profileInfo)

  return (
    <div className="profile-info-area">
      <div className="page-desc mt-4">
        <h3>Profile</h3>
        <p>View and edit your Information here </p>
      </div>

      <div className="card mt-5 px-md-4 py-4 border-0 shadow">
        <div className="card-body">
          <div className="page-desc">
            <h3>Profile Information</h3>
          </div>
          <div className="mt-3">
            <div className="row">
              <div className="col-md-6">
                <label htmlFor="firstName">First Name</label>
                <input
                  type="text"
                  value={firstName}
                  defaultValue={teamDetails?.name.split(" ")[0]}
                  className="form-control"
                  placeholder="John"
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="lastName">Last Name</label>
                <input
                  type="text"
                  value={lastName}
                  defaultValue={teamDetails?.name.split(" ")[1]}
                  className="form-control"
                  placeholder="Doe"
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="busName">Business Name</label>
                <input
                  type="text"
                  value={profileState?.profileInfo?.data?.name}
                  className="form-control"
                  disabled
                  placeholder="Venixs"
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="busEmail">Business email</label>
                <input
                  type="email"
                  value={profileState?.profileInfo?.data?.contact_email}
                  className="form-control"
                  disabled
                  placeholder="John@company.com"
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="busPhoneNumber">Business Phone Number</label>
                <input
                  type="tel"
                  value={profileState?.profileInfo?.data?.contact_phone}
                  className="form-control"
                  disabled
                  placeholder="08012345678"
                />
              </div>

              <div className="">
                <button
                  className="btn btn-green my-5 py-3 px-5"
                  onClick={updateProfileInfo}
                >
                  {profileState.updateIsLoading ? (
                    <div>
                      <FaSpinner
                        className="animate-spin"
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />

                      <span className="sr-only"> Loading...</span>
                    </div>
                  ) : (
                    `Update Profile`
                  )}
                </button>
                <button
                  className="btn btn-green my-5 py-3 px-5 ms-5"
                  onClick={() => setAddCusModal(true)}
                >
                  <span className="sr-only">Change Password</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {addCusModal && (
        <div className="main-modal">
          <div className="main-modal-content shadow card col-md-6 col-lg-4 mx-auto">
            <span onClick={() => setAddCusModal(false)}>
              <i className="ri-close-line close-modal" />
            </span>
            <div className="card-body">
              <div className="main-modal-body">
                <h5>Change your Password</h5>
                <p>
                  Please create a password that will be accessible to only you
                </p>

                <div className="mt-3">
                  <div className="">
                    <label htmlFor="name">Input Old Password</label>
                    <input
                      type="password"
                      value={oldPassword}
                      required
                      className="form-control"
                      onChange={(e) => setOldPassword(e.target.value)}
                      placeholder="Old Password"
                    />
                  </div>
                  <div className="">
                    <label htmlFor="password">Input New Password</label>
                    <input
                      type="password"
                      value={newPassword}
                      required
                      className="form-control"
                      onChange={(e) => setNewPassword(e.target.value)}
                      placeholder="New Password"
                    />
                  </div>
                  <button
                    className="btn btn-green py-3 mt-5 w-100"
                    onClick={changePassword}
                  >
                    Update Password
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
