import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allTeamRequest } from "../../../redux/features/teams/teamSlice";
import toast from "react-hot-toast";
import { BsArrowDown, BsCaretDown } from "react-icons/bs";
import { FaAngleDown, FaCaretDown } from "react-icons/fa";
import { TbCaretDown } from "react-icons/tb";
import { IoCaretDown } from "react-icons/io5";

const CustomSelect = ({ placeholder, type, onChange }) => {
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [options, setOptions] = useState([]);
  const dispatch = useDispatch();
  const routeState = useSelector((state) => state.routeReducer);

  const fetchAllTeam = async () => {
    setLoading(true);
    if (routeState?.profileInfo?.id) {
      try {
        const res = await dispatch(
          allTeamRequest({
            organization_id: routeState?.profileInfo?.id,
          })
        );

        setLoading(false);
        if (res?.payload?.status === 200) {
          const teamData = res?.payload?.data;
          const agentOptions = teamData.map((team) => ({
            value: team.id,
            label: team.name,
            email: team.email,
          }));
          setOptions(agentOptions);
        } else {
          toast.error(res?.payload?.data?.detail);
        }
      } catch (err) {
        setLoading(false);
        toast.error("Failed to fetch teams.");
      }
    }
  };

  useEffect(() => {
    if (type === "agent" && routeState?.profileInfo?.id) {
      fetchAllTeam();
    } else if (type === "month") {
      setOptions([
        { value: 1, label: "January" },
        { value: 2, label: "February" },
        { value: 3, label: "March" },
        { value: 4, label: "April" },
        { value: 5, label: "May" },
        { value: 6, label: "June" },
        { value: 7, label: "July" },
        { value: 8, label: "August" },
        { value: 9, label: "September" },
        { value: 10, label: "October" },
        { value: 11, label: "November" },
        { value: 12, label: "December" },
      ]);
    } else if (type === "year") {
      setOptions([
        { value: 2023, label: "2023" },
        { value: 2024, label: "2024" },
      ]);
    }
  }, [routeState?.profileInfo?.id, type]);

  const handleSelect = (option) => {
    setSelectedOption(option);
    onChange(option);
    setIsOpen(false);
  };

  return (
    <div className="relative w-full">
      <div
        className="border rounded-lg p-2 bg-white cursor-pointer flex justify-between items-center"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>{selectedOption ? selectedOption.label : placeholder}</span>
        <FaAngleDown />
      </div>
      {isOpen && (
        <div className="absolute w-full mt-1 bg-white border rounded-lg shadow-lg z-10 max-h-60 overflow-y-auto">
          {loading ? (
            <div className="p-2 text-center">Loading...</div>
          ) : (
            options.map((option) => (
              <div
                key={option.value}
                className="p-2 cursor-pointer hover:bg-gray-100"
                onClick={() => handleSelect(option)}
              >
                {option.label}
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
