import React from "react";
import Sidebar from "./sidebar";
import Header from "../../reuseable/header";

export default function AppWrapperComp({ children, headerTitle }) {
  return (
    <div className="flex w-full">
      <Sidebar />
      <div className="flex flex-col lg:ml-[280px] w-full h-[100vh] max-h-[100vh] overflow-y-hidden">
        <div className=" top-0 z-[999]">
          <Header headerTitle={headerTitle} />
          {/* <Header headerTitle={headerTitle} /> */}
        </div>
        <div className="relative overflow-y-hidden w-full h-full bg-[#FCF7FF]">
          {children}
        </div>
      </div>
    </div>
  );
}
