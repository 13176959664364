import { useEffect, useRef } from "react";

const ClickOutside = (handler) => {
  const ref = useRef();
  useEffect(() => {
    const clickHandler = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        handler();
      }
    };
    document.addEventListener("mousedown", clickHandler);
    return () => {
      document.removeEventListener("mousedown", clickHandler);
    };
  }, []);
  return ref;
};

export const subscriptionPlans = {
  BASIC: "Basic",
  FREETRIAL: "FREE-TRIAL",
  PROFESSIONAL: "Professional",
  ADVANCED: "Advance",
};

export const userRole = {
  ADMIN: "Admin",
  SUPPORT: "Support Agent",
};

export const calculateTotalAmount = (unitCost, quantity) => {
  return unitCost * quantity;
};
export default ClickOutside;
