/* eslint-disable react-hooks/rules-of-hooks */
import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  loginRequest,
  otpConfirm,
} from "../../../redux/features/auth/authSlice";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { FaSpinner } from "react-icons/fa";
import Cookies from "js-cookie";

const SignUpStep3 = ({ onPrevious, details }) => {
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [loading, setLoading] = useState(false);

  const refs = Array(6)
    .fill()
    .map(() => useRef());
  const mergeOtp = otp.join("");
  const handlePrevious = () => {
    onPrevious();
  };

  console.log(details);

  const navigate = useNavigate();

  let orgId = Cookies.get("orgId") || "";

  const dispatch = useDispatch();

  const verificationEmail = localStorage.getItem("email");
  const organizationId = localStorage.getItem("signOrgId");

  const handleOtpChange = (index, event) => {
    const newOtp = [...otp];
    newOtp[index] = event.target.value;
    setOtp(newOtp);
    if (event.target.value !== "" && index < newOtp.length - 1) {
      refs[index + 1].current.focus();
    }
  };

  const loginEndPoint = () => {
    let data = {
      username: details.email,
      password: details.password,
    };
    dispatch(loginRequest(data))
      .then((res) => {
        if (res?.payload?.status === 200) {
          Cookies.set("authenticated", "1");
          Cookies.set("babtbu", res?.payload?.data?.token?.access_token);
          Cookies.set(
            "orgId",
            res?.payload?.data?.organization?.organisation_id
          );
          localStorage.setItem(
            "tm",
            JSON.stringify(res?.payload?.data?.team_data)
          );
          window.location.href = global.appBaseUrl + "home";
        } else {
          toast.error(res?.payload?.data?.detail);
        }
      })
      .catch((err) => {});
  };

  let otpConfirmation = (e) => {
    e.preventDefault();
    let data = {
      token: mergeOtp,
      organization_id: organizationId,
    };

    if (!mergeOtp) {
      toast.error("Token is not valid");
      return;
    }

    dispatch(otpConfirm(data))
      .then((res) => {
        if (res?.payload?.status === 200) {
          loginEndPoint();
          localStorage.removeItem("email");
          toast.success("Account successfully created");
        } else {
          setLoading(false);
          toast.error(
            res?.payload?.data?.detail?.detail || "An error occurred"
          );
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error("An error occurred while confirming OTP");
      });
  };

  return (
    <div className="">
      <div className="container mx-auto">
        <div className="flex justify-center">
          <div className="w-full max-w-md py-2">
            <div className="text-center mt-11">
              <p className="font-PJ-medium font-medium text-[32px] leading-[40.32px]">
                Verify Email Address
              </p>
              <p className="font-inter font-normal text-base leading-[22px] mt-4">
                Enter the code sent to {verificationEmail}.{" "}
                <h4
                  className="font-inter font-normal text-base leading-[22px] mt-4"
                  onClick={handlePrevious}
                >
                  Change Email
                </h4>
              </p>
            </div>
            <form>
              <div className="mt-4">
                <div className="flex justify-center gap-6 mb-8">
                  <input
                    type="text"
                    onChange={(e) => handleOtpChange(0, e)}
                    ref={refs[0]}
                    maxLength={1}
                    name="otp"
                    className="code-box mx-1 p-2 text-center border border-gray-300 rounded-lg w-12"
                  />
                  <input
                    type="text"
                    onChange={(e) => handleOtpChange(1, e)}
                    ref={refs[1]}
                    maxLength={1}
                    name="otp1"
                    className="code-box mx-1 p-2 text-center border border-gray-300 rounded-lg w-12"
                  />
                  <input
                    type="text"
                    onChange={(e) => handleOtpChange(2, e)}
                    ref={refs[2]}
                    maxLength={1}
                    name="otp2"
                    className="code-box mx-1 p-2 text-center border border-gray-300 rounded-lg w-12"
                  />
                  <input
                    type="text"
                    onChange={(e) => handleOtpChange(3, e)}
                    ref={refs[3]}
                    maxLength={1}
                    name="otp3"
                    className="code-box mx-1 p-2 text-center border border-gray-300 rounded-lg w-12"
                  />
                  <input
                    type="text"
                    onChange={(e) => handleOtpChange(4, e)}
                    ref={refs[4]}
                    maxLength={1}
                    name="otp4"
                    className="code-box mx-1 p-2 text-center border border-gray-300 rounded-lg w-12"
                  />
                  <input
                    type="text"
                    onChange={(e) => handleOtpChange(5, e)}
                    ref={refs[5]}
                    maxLength={1}
                    name="otp5"
                    className="code-box mx-1 p-2 text-center border border-gray-300 rounded-lg w-12"
                  />
                </div>
                <div className="flex justify-center w-full">
                  <button
                    className="bg-[#101010] w-full text-[#E5C9FF] flex justify-center items-center rounded-lg px-6 py-3 font-inter font-medium leading-[19.36px] text-base"
                    onClick={otpConfirmation}
                  >
                    {loading ? (
                      <FaSpinner className="animate-spin" />
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpStep3;
