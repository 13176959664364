import React from "react";
import { useTable } from "react-table";
const Table = ({
  columns,
  data,
  itemPerPage,
  currentPage,
  tableClass,
  tHeadClass,
  tBodyClass,
  tdClass,
  thClass,
  tableBodyTrClass,
  tableHeadTrClass,
}) => {
  const itemsPerPage = itemPerPage ? itemPerPage : 10;
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const subset = data?.slice(startIndex, endIndex);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: subset });
  return (
    <div className=" border-[0.4px] border-[#E0E0E0] border-opacity-[60%] mb-11 mt-5 rounded-t-xl relative shadow-custom bg-white py-4 ">
      <table className={`w-full   ${tableClass}`} {...getTableProps()}>
        <thead className={`whitespace-nowrap   ${tHeadClass}`}>
          {headerGroups.map((headerGroup, index) => (
            <tr
              className={`  ${tableHeadTrClass}`}
              {...headerGroup.getHeaderGroupProps()}
              key={index}
            >
              {headerGroup.headers.map((column, index) => (
                <th
                  className={`font-PJ-medium px-6  text-base text-start ml-6 rounded py-3 ${thClass}`}
                  {...column.getHeaderProps()}
                  key={index}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {rows.length > 0 ? (
          <tbody className={` ${tBodyClass}`} {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <tr
                  className={` bg-white  ${tableBodyTrClass}`}
                  {...row.getRowProps()}
                  key={index}
                >
                  {row.cells.map((cell, index) => {
                    return (
                      <td
                        className={`  capitalize px-6 py-6 border-t-[1px]  ${tdClass}`}
                        {...cell.getCellProps()}
                        key={index}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        ) : (
          <td
            className={`font-normal  capitalize text-gray-600  text-[14px] p-2 border-b-[1px]  ${tdClass}`}
          >
            No Data Found
          </td>
        )}
      </table>
    </div>
  );
};
export default Table;
