// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDa2LiLOp5EDN0_5lqlOkTT9auJ63e8Oko",
  authDomain: "venixs-720c9.firebaseapp.com",
  projectId: "venixs-720c9",
  storageBucket: "venixs-720c9.appspot.com",
  messagingSenderId: "112814001475",
  appId: "1:112814001475:web:ef52c4aadf39a3b0ce73f6",
  measurementId: "G-EJZ4PZ0QJV",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export const generateToken = async () => {
  try {
    const permission = await Notification.requestPermission();

    if (permission === "granted") {
      const token = await getToken(messaging, {
        vapidKey:
          "BNOgd8aV9DKH7tBMC05jGPe39olfmm_Bhra_g5p9A76yBsyf8LruyeOOGAQiE63rgqP2s_suVHik6crolI2uluw",
      });

      if (token) {
        localStorage.setItem("pm", token);
        // console.log("FCM token:", token);
      } else {
        console.error("Failed to get FCM token.");
      }
    } else {
      console.warn("Notification permissions not granted.");
    }
  } catch (error) {
    console.error(
      "An error occurred while requesting notification permission or getting token:",
      error
    );
  }
};

generateToken();
