import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";
import { authorizationRedirect, serverCodes } from "../../constants/api";
import global from "../../constants/global";

let accessT = Cookies.get("babtbu") || "";
let orgId = Cookies.get("orgId") || "";

export const allTicketsRequest = createAsyncThunk(
  "ticketing/allTickets",
  async () => {
    let config = {
      method: "post",
      url: `${global.apiBaseUrl}tickets/get`,
      headers: {
        Authorization: `Bearer ${accessT}`,
        "Content-Type": "application/json",
      },
      data: {
        organisation_id: orgId,
      },
    };

    let resp = null;

    await axios
      .request(config)
      .then((response) => {
        resp = response;
      })
      .catch((error) => {
        if (
          serverCodes.includes(error?.request?.status) ||
          !error?.request?.status
        ) {
          resp = {
            data: {
              response: "Oops, Something went wrong, please try again later.",
            },
          };
          return;
        } else if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          authorizationRedirect();
        } else {
          resp = error?.response;
        }
      });
    return resp;
  }
);

export const createTicketRequest = createAsyncThunk(
  "ticketing/createTicket",
  async (data) => {
    let config = {
      method: "post",
      url: `${global.apiBaseUrl}tickets/create`,
      headers: {
        Authorization: `Bearer ${accessT}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    let resp = null;

    await axios
      .request(config)
      .then((response) => {
        resp = response;
      })
      .catch((error) => {
        if (
          serverCodes.includes(error?.request?.status) ||
          !error?.request?.status
        ) {
          resp = {
            data: {
              response: "Oops, Something went wrong, please try again later.",
            },
          };
          return;
        } else if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          authorizationRedirect();
        } else {
          resp = error?.response;
        }
      });
    return resp;
  }
);

export const updateTicketRequest = createAsyncThunk(
  "ticketing/updateTicket",
  async (data) => {
    let config = {
      method: "post",
      url: `${global.apiBaseUrl}tickets/update`,
      headers: {
        Authorization: `Bearer ${accessT}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    let resp = null;

    await axios
      .request(config)
      .then((response) => {
        resp = response?.data;
      })
      .catch((error) => {
        if (
          serverCodes.includes(error?.request?.status) ||
          !error?.request?.status
        ) {
          resp = {
            data: {
              response: "Oops, Something went wrong, please try again later.",
            },
          };
          return;
        } else if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          authorizationRedirect();
        } else {
          resp = error?.response;
        }
      });
    return resp;
  }
);

export const assignTicket = createAsyncThunk("tickets/assign", async (data) => {
  let config = {
    method: "post",
    url: `${global.apiBaseUrl}tickets/assign`,
    headers: {
      Authorization: `Bearer ${accessT}`,
      "Content-Type": "application/json",
    },
    data: data,
  };

  let resp = null;

  await axios
    .request(config)
    .then((response) => {
      resp = response;
    })
    .catch((error) => {
      if (
        serverCodes.includes(error?.request?.status) ||
        !error?.request?.status
      ) {
        resp = {
          data: {
            response: "Oops, Something went wrong, please try again later.",
          },
        };
        return;
      } else if (
        error?.response?.status === 401 ||
        error?.response?.status === 403
      ) {
        authorizationRedirect();
      } else {
        resp = error?.response;
      }
    });
  return resp;
});

export const getAssignedTicket = createAsyncThunk(
  "tickets/assigned-ticket",
  async (data) => {
    let config = {
      method: "post",
      url: `${global.apiBaseUrl}tickets/get/assigned_to`,
      headers: {
        Authorization: `Bearer ${accessT}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    let resp = null;

    await axios
      .request(config)
      .then((response) => {
        resp = response;
      })
      .catch((error) => {
        if (
          serverCodes.includes(error?.request?.status) ||
          !error?.request?.status
        ) {
          resp = {
            data: {
              response: "Oops, Something went wrong, please try again later.",
            },
          };
          return;
        } else if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          authorizationRedirect();
        } else {
          resp = error?.response;
        }
      });
    return resp;
  }
);

export const ticketingSlice = createSlice({
  name: "conversations",
  initialState: {
    isLoading: false,
    allTickets: null,
    createResp: null,
    updateResp: null,
    assignResp: null,
    assignedResp: null,
    ticketUpdateIsLoading: false,

    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(allTicketsRequest.pending, (state, action) => {
      // if (!state.isLoading) {
      state.isLoading = true;
      // }
    });
    builder.addCase(createTicketRequest.pending, (state, action) => {
      // if (!state.isLoading) {
      state.ticketUpdateIsLoading = true;
      // }
    });
    builder.addCase(updateTicketRequest.pending, (state, action) => {
      // if (!state.isLoading) {
      state.ticketUpdateIsLoading = true;
      // }
    });
    builder.addCase(assignTicket.pending, (state, action) => {
      // if (!state.isLoading) {
      state.assignResp = true;
      // }
    });
    builder.addCase(getAssignedTicket.pending, (state, action) => {
      // if (!state.isLoading) {
      state.assignedResp = true;
      // }
    });

    builder.addCase(allTicketsRequest.fulfilled, (state, action) => {
      // if (state.isLoading ) {
      state.allTickets = action.payload;
      state.isLoading = false;
      // }
    });
    builder.addCase(createTicketRequest.fulfilled, (state, action) => {
      // if (state.isLoading ) {
      state.createResp = action.payload;
      state.ticketUpdateIsLoading = false;
      // }
    });
    builder.addCase(updateTicketRequest.fulfilled, (state, action) => {
      // if (state.isLoading ) {
      state.updateResp = action.payload;
      state.ticketUpdateIsLoading = false;
      // }
    });
    builder.addCase(assignTicket.fulfilled, (state, action) => {
      state.assignResp = action.payload;
      //    state.ticketUpdateIsLoading = false;
    });
    builder.addCase(getAssignedTicket.fulfilled, (state, action) => {
      state.assignedResp = action.payload;
      //    state.ticketUpdateIsLoading = false;
    });

    builder.addCase(allTicketsRequest.rejected, (state, action) => {
      // if (state.isLoading ) {
      state.error = action.payload;
      state.isLoading = false;
      // }
    });
    builder.addCase(createTicketRequest.rejected, (state, action) => {
      // if (state.isLoading ) {
      state.ticketUpdateIsLoading = false;
      state.error = action?.payload;
      // }
    });
    builder.addCase(updateTicketRequest.rejected, (state, action) => {
      // if (state.isLoading ) {
      state.error = action.payload;
      state.ticketUpdateIsLoading = false;
      // }
    });
    builder.addCase(assignTicket.rejected, (state, action) => {
      // if (state.isLoading ) {
      state.error = action.payload;
      state.ticketUpdateIsLoading = false;
      // }
    });
    builder.addCase(getAssignedTicket.rejected, (state, action) => {
      // if (state.isLoading ) {
      state.error = action.payload;
      state.ticketUpdateIsLoading = false;
      // }
    });
  },
});

export default ticketingSlice.reducer;
