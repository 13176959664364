import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'js-cookie'
import global from '../../constants/global'

// let accessT = Cookies.get("babtbu") || ""

export const AIChatRequest = createAsyncThunk('AI/chat', async (data) => {
    // const response = await axios.post(`${global.apiBaseUrl}chat`, {})
    // return response.data
    let config = {
        method: 'post',
        url:`${global.apiBaseUrl}chat`,
        headers: { 
            // 'Authorization': `Bearer ${accessT}`,
            'Content-Type': 'application/json'
        },
        data:data
    };

    let resp = null

    await axios.request(config).then((response) => {
        resp = response?.data
    })
    .catch((error) => {
        resp = error?.response
    })
    return resp
})


export const AIChatSlice = createSlice({
    name: 'chat',
    initialState: {
        isLoading: false,
        data: null,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(AIChatRequest.pending, (state, action) => {
            state.isLoading = true
        })


        builder.addCase(AIChatRequest.fulfilled, (state, action) => {
            state.data = action.payload
            state.isLoading = false
        })


        builder.addCase(AIChatRequest.rejected, (state, action) => {
            state.isLoading = false
            state.error = action?.payload
        })
    },
})




export default AIChatSlice.reducer 