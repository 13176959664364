import Cookies from "js-cookie";
import { BsArrowRight } from "react-icons/bs";
import { GoArrowSwitch } from "react-icons/go";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { escalationConfigCreateRequest } from "../../redux/features/configurations/escalationConfig/escalationConfigSlice";

const Escard = ({
  paragraph,
  button,
  desclink,
  to,
  cardPics,
  hideButtonArrow,
  isActive,
  onToogle,
  checked,
  setChecked
}) => {
  const [openCardModal, setOpenCardModal] = useState(false);
  const [channelId, setChannelId] = useState("");
  const [generatedToken, setGeneratedToken] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  let dispatch = useDispatch();
  let orgId = Cookies.get("orgId") || "";

  const handleSlackLink = () => {
    setChecked(!checked);
    onToogle(checked);
  };

  const handleToggle = () => {
    if (isActive) {
      unLink();
      return;
    }

    setChecked((prevChecked) => !prevChecked);
    setOpenCardModal((prev) => !prev);
  };

  let updateSlackEscalation = () => {
    setIsProcessing(true);
    let data = {
      organization_id: orgId,
      target: "slack",
      target_config: {
        channel_id: channelId,
        token: generatedToken,
      },
      active: true,
    };
    dispatch(escalationConfigCreateRequest(data))
      .then((res) => {
        setIsProcessing(false);
        if (res?.payload?.status === 200) {
          toast.success("Request was successful");
          setOpenCardModal(false);
          return;
        } else {
          toast.error(
            res?.payload?.data?.response || "Failed to update Slack escalation"
          );
        }
      })
      .catch((err) => {
        console.error("Update Slack Escalation Error:", err);
        toast.error("An error occurred while updating Slack escalation");
      });
  };

  let unLink = () => {
    // un-link logic here
  };

  return (
    <>
      <div className="border-[0.8px] border-[#E0E0E0] shadow-custom bg-white border-opacity-[60%] rounded-xl p-4">
        <div className="  ">
          <div className=" flex justify-between ">
            <div className="">
              <img className="w-[48px] h-[48px]" src={cardPics} alt="Img" />
            </div>
            <div
              className={`flex justify-center items-center px-2 !py-[1px] rounded-[24px] ${isActive ? "bg-[#F9F3FF]" : "bg-[#F3F2F4]"
                }`}
            >
              <Link to={to} onClick={handleToggle}>
                <button
                  className={`!px-5 !py-1 flex gap-2 items-center !font-semiboldInter ${isActive ? "!text-[#51376A]" : "!text-[#5B5462]"
                    }`}
                  disabled={!to}
                >
                  {isActive ? "Un-Link channel" : "Coming Soon"}
                  {isActive && <BsArrowRight />}
                </button>
              </Link>
            </div>
          </div>
          <div className="overflow-hidden mt-4 !truncate w-auto !text-start">
            <p className="!font-PJ-semibold !text-[20px] text-[#101928] leading-[24px] tracking-[-3%] ">
              {paragraph}
            </p>
            <a
              href="./"
              className="!text-[12px] !text-[#1e1e1e] opacity-[80%] tracking-[17.4%] underline !font-inter"
            >
              {desclink}
            </a>
          </div>
          <hr className="mt-4" />
        </div>
        <div className=" flex justify-between items-center mt-4">
          <div className="  ">
            <button className="flex border rounded-[10px]  items-center gap-2 px-4 py-2 ">
              <GoArrowSwitch />
              Connections
            </button>
          </div>
          <div
            className={`w-[40px] h-[21px] rounded-[20px] relative border-2 cursor-pointer ${checked
                ? "bg-[#d2d0d0] border-[#d2d0d0] "
                : "bg-purple-800 border-purple-800"
              }`}
            onClick={handleSlackLink}
          >
            <div
              className={`w-[19px] h-[19px] rounded-full absolute top-[-1.0px] left-[-1px]  transform transition-transform transition-background ${checked ? "bg-[#ffffff]" : "translate-x-full bg-white"
                }`}
            ></div>
          </div>
        </div>
      </div>

      {openCardModal && (
        <div className="fixed inset-0 z-[999] flex items-center justify-center bg-black backdrop-blur-sm bg-opacity-50">
          <div className="bg-white rounded-2xl overflow-hidden  shadow-xl w-full max-w-md">
            <div className="px-6 py-3">
              <div className="flex justify-between items-center">
                <div className="!mb-[43px]">
                  <h5 className="text-xl font-PJ-medium font-medium text-[#101010] leading-[25.2px] px-2">
                    Slack Escalations
                  </h5>
                  <p className="px-2 ">Kindly provide the necessary details</p>
                </div>
                <span
                  className="text-gray-500 cursor-pointer"
                  onClick={() => setOpenCardModal(false)}
                >
                  <i className="ri-close-line" />
                </span>
              </div>

              <hr className="my-4" />

              <div className="mt-3">
                <div>
                  <label htmlFor="dataType">
                    <p className="block mt-3  font-inter font-medium text-lg leading-[21.78px] text-[#323232]  ">
                      Channel ID
                    </p>
                  </label>
                  <input
                    type="text"
                    value={channelId}
                    onChange={(e) => setChannelId(e.target.value)}
                    className="form-control border border-[#B5B5B5] w-full pl-5"
                    placeholder="Channel ID"
                  />
                </div>

                <div className="mt-2">
                  <label htmlFor="documentUrl">
                    <p className="block mt-3 mb-3 font-inter font-medium text-lg leading-[21.78px] text-[#323232]  ">
                      Generated Slack Token
                    </p>
                  </label>
                  <input
                    type="url"
                    value={generatedToken}
                    onChange={(e) => setGeneratedToken(e.target.value)}
                    className="form-control border border-[#B5B5B5] mb-8 w-full pl-5"
                    placeholder="Slack Token"
                  />
                </div>

                <hr />

                <button
                  className="configButton my-5 mb-3"
                  onClick={updateSlackEscalation}
                  disabled={!channelId || !generatedToken || isProcessing}
                >
                  Update Configurations
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Escard;
