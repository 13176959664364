import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";
import { authorizationRedirect, serverCodes } from "../../constants/api";
import global from "../../constants/global";

let accessT = Cookies.get("babtbu") || "";
let orgId = Cookies.get("orgId") || "";

export const profileInfoRequest = createAsyncThunk("profile/get", async () => {
  let config = {
    method: "post",
    url: `${global.apiBaseUrl}get/organization?organization_id=${orgId}`,
    headers: {
      Authorization: `Bearer ${accessT}`,
      "Content-Type": "application/json",
    },
    // data: { organization_id: orgId}
  };

  let resp = null;

  await axios
    .request(config)
    .then((response) => {
      resp = response;
    })
    .catch((error) => {
      if (
        serverCodes.includes(error?.request?.status) ||
        !error?.request?.status
      ) {
        resp = {
          data: {
            response: "Oops, Something went wrong, please try again later.",
          },
        };
        return;
      } else if (
        error?.response?.status === 401 ||
        error?.response?.status === 403
      ) {
        authorizationRedirect();
      } else {
        resp = error?.response;
      }
    });
  return resp;
});

export const updateProfileInfoRequest = createAsyncThunk(
  "profile/update",
  async (data) => {
    let config = {
      method: "post",
      url: `${global.apiBaseUrl}organization/profile/update`,
      headers: {
        Authorization: `Bearer ${accessT}`,
        "Content-Type": "multipart/form-data",
      },
      data: data,
    };

    let resp = null;

    await axios
      .request(config)
      .then((response) => {
        resp = response;
      })
      .catch((error) => {
        if (
          serverCodes.includes(error?.request?.status) ||
          !error?.request?.status
        ) {
          resp = {
            data: {
              response: "Oops, Something went wrong, please try again later.",
            },
          };
          return;
        } else if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          authorizationRedirect();
        } else {
          resp = error?.response;
        }
      });
    return resp;
  }
);

export const profileSlice = createSlice({
  name: "Profile",
  initialState: {
    isLoading: false,
    profileInfo: null,
    data: null,
    updateIsLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(profileInfoRequest.pending, (state, action) => {
      // if (!state.isLoading) {
      state.isLoading = true;
      // }
    });
    builder.addCase(updateProfileInfoRequest.pending, (state, action) => {
      // if (!state.isLoading) {
      state.updateIsLoading = true;
      // }
    });

    builder.addCase(profileInfoRequest.fulfilled, (state, action) => {
      // if (state.isLoading ) {
      state.profileInfo = action.payload;
      state.isLoading = false;
      // }
    });
    builder.addCase(updateProfileInfoRequest.fulfilled, (state, action) => {
      // if (state.isLoading ) {
      state.data = action.payload;
      state.updateIsLoading = false;
      // }
    });

    builder.addCase(profileInfoRequest.rejected, (state, action) => {
      // if (state.isLoading ) {
      state.isLoading = false;
      state.error = action.payload;
      // }
    });
    builder.addCase(updateProfileInfoRequest.rejected, (state, action) => {
      // if (state.isLoading ) {
      state.error = action.payload;
      state.updateIsLoading = false;
      // }
    });
  },
});

export default profileSlice.reducer;
