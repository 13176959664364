import React, { useState, useEffect } from "react";
import { BsCreditCard, BsPlus } from "react-icons/bs";
import { RiDeleteBinFill } from "react-icons/ri";
import {
  orgCardsRemoveRequest,
  orgCardsRequest,
  trySubscriptionRequest,
} from "../../../redux/features/subscription/subscriptionSlice";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { EmptyStateComp, MainLoader } from "../../utils";
import Cookies from "js-cookie";
import CustomPagination from "../../customPagination/CustomPagination";

const LinkedCardsManager = () => {
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const cardsPerPage = 5;
  const dispatch = useDispatch();
  const orgId = Cookies.get("orgId") || "";

  useEffect(() => {
    if (orgId) {
      cardRequest();
    } else {
      toast.error("Organization ID is missing.");
    }
  }, [orgId]);

  const cardRequest = () => {
    setLoading(true);
    dispatch(orgCardsRequest())
      .then((res) => {
        if (res?.payload?.status === 200) {
          const fetchedCards = res.payload.data;
          const mappedCards = fetchedCards.map((card) => ({
            id: card.card_id, // Ensure card_id is mapped
            number: card.cardholder_number
              ? card.cardholder_number.replace(/\d(?=\d{4})/g, "*")
              : `**** **** **** ${card.last_four}`,
            expiry: `${card.expiration_month}/${card.expiration_year}`,
            name: card.cardholder_name || "Unknown",
            type: card.card_type ? card.card_type.toLowerCase() : "unknown",
            bank: card.bank_name || "N/A",
            isDefault: false,
          }));
          setCards(mappedCards);
        } else {
          toast.error(res?.payload?.data?.response || "Failed to fetch cards.");
        }
      })
      .catch(() => {
        toast.error("Failed to fetch subscription cards.");
      })
      .finally(() => setLoading(false));
  };

  const deleteCard = (cardId) => {
    if (!cardId) {
      toast.error("Invalid card ID.");
      return;
    }

    const data = { organization_id: orgId, card_id: cardId };
    console.log("Deleting card with data:", data); // Log the data for debugging
    dispatch(orgCardsRemoveRequest(data))
      .then((res) => {
        if (res?.payload?.status === 200) {
          toast.success("Card removed successfully");
          setCards((prevCards) =>
            prevCards.filter((card) => card.id !== cardId)
          );
        } else {
          console.error("Delete response:", res);
          toast.error(res?.payload?.data?.response || "Failed to remove card.");
        }
      })
      .catch((error) => {
        console.error("Error while deleting card:", error);
        toast.error("Failed to remove card.");
      });
  };

  // const setDefaultCard = (id) => {
  //   const updatedCards = cards.map((card) => ({
  //     ...card,
  //     isDefault: card.id === id,
  //   }));
  //   const sortedCards = updatedCards.sort((a, b) => (b.isDefault ? 1 : -1));
  //   setCards(sortedCards);
  // };

  const addCard = () => {
    const data = {
      organization_id: orgId,
      success_url: global.appBaseUrl + "Subscription?success",
      failure_url: global.appBaseUrl + "Subscription?failed",
      force: false,
    };
    dispatch(trySubscriptionRequest(data))
      .then((res) => {
        if (res?.payload?.status === 200) {
          window.location.href = res.payload.data.redirect_url;
        } else {
          toast.error(res?.payload?.data?.detail || "Failed to add card.");
        }
      })
      .catch(() => {
        toast.error("Failed to initiate card addition.");
      });
  };

  // Pagination calculations
  const indexOfLastCard = (currentPage + 1) * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const currentCards = cards.slice(indexOfFirstCard, indexOfLastCard);
  const totalPages = Math.ceil(cards.length / cardsPerPage);

  // Handle page change from Pagination component
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div>
      <h3 className="font-PJ-medium text-xl leading-[25.2px] mt-2 tracking-[-4%] text-primary">
        Linked Cards
      </h3>

      <div className="mx-auto p-6">
        {loading ? (
          <MainLoader />
        ) : cards.length === 0 ? (
          <EmptyStateComp
            title="No Linked Cards"
            desc="You don’t have any linked cards yet."
          />
        ) : (
          <>
            <p className="text-sm font-inter text-gray-600 mb-4">
              Default Card: Scheduled payments will be automatically deducted
              from your card.
            </p>

            {currentCards.map((card) => (
              <div
                key={card.id}
                className="mb-4 p-6 border rounded-lg shadow-md flex items-center justify-between bg-gray-50"
              >
                <div className="flex items-center">
                  <BsCreditCard
                    className={`text-2xl ${
                      card.type === "visa"
                        ? "text-blue-600"
                        : card.type === "mastercard"
                        ? "text-red-500"
                        : card.type === "verve"
                        ? "text-green-500"
                        : "text-gray-500"
                    }`}
                  />
                  <div className="ml-4">
                    <p className="font-medium text-gray-900">{card.number}</p>
                    <p className="text-sm text-gray-600">
                      {card.expiry} • {card.name} • {card.bank}
                    </p>
                  </div>
                </div>
                <div>
                  <button onClick={() => deleteCard(card.id)}>
                    <RiDeleteBinFill className="text-red-600 w-6 h-6 ml-2 mt-2" />
                  </button>
                </div>
              </div>
            ))}

            <CustomPagination
              pageCount={totalPages}
              onChange={handlePageChange}
              initialPage={currentPage}
            />

            <button
              onClick={addCard}
              className="px-4 py-2 bg-primary text-light rounded-lg hover:bg-purple-700 flex items-center justify-center mt-4"
            >
              <BsPlus size={16} className="mr-1" /> Add New Card
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default LinkedCardsManager;
