import moment from "moment";
import React, { useEffect, useRef } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { EmptyStateComp } from "../../components/utils";
import { allNotificationsRequest } from "../../redux/features/notifications/notificationSlice";
import cusAvatar from "../../assets/cus-avatar.png";
import { BsCheckCircleFill, BsXCircleFill } from "react-icons/bs";
import { RiCloseFill } from "react-icons/ri";

export default function NotificationsPage() {
  const isMounted = useRef(false);
  const notificationState = useSelector((state) => state.notificationReducer);

  let dispatch = useDispatch();
  useEffect(() => {
    if (isMounted.current) return;
    isMounted.current = true;
    getAllNotifications();
  }, []);

  let getAllNotifications = () => {
    dispatch(allNotificationsRequest())
      .then((res) => {
        if (res?.payload?.status === 200) {
          return;
        } else {
          toast.error(res?.payload?.data?.response);
        }
      })
      .catch((err) => {});
  };

  // const handleNotificationClose = (notificationId) => {
  //   // Dispatch an action to update the notification's checked field
  //   // and remove it from the state
  //   dispatch(markNotificationAsChecked(notificationId));
  // };

  return (
    <div className="notification-page-area">
      <div className="mt-5">
        {notificationState?.allNotifs?.data?.length > 0 &&
          notificationState?.allNotifs?.data?.map((val, index) => (
            <div className={"card border-b !rounded-none w-full"} key={val.id}>
              <div className="card-body py-6">
                <div className="flex justify-between">
                  <div className="flex">
                    {val.sent ? (
                      <div className="">
                        <div className="flext items-center justify-center p-2 h-fit w-fit bg-green-100 rounded-circle me-5">
                          <BsCheckCircleFill color="green" />
                        </div>
                      </div>
                    ) : (
                      <div className="flex items-center justify-center p-2 h-fit w-fit bg-red-100 rounded-full me-5">
                        <BsXCircleFill color="red" />
                      </div>
                    )}

                    <div className="">
                      <div className="flex items-center">
                        <span className="text-xl leading-[30px] tracking-tighter font-PJ-medium">
                          {val?.notification_type}
                        </span>
                        <span className="mx-4 scale-150"> &bull; </span>
                        <small className=" font-inter mt-1 tracking-tighter text-lg text-[#323232]">
                          {moment(val?.time_created).fromNow() ||
                            "Not Available"}
                        </small>
                      </div>

                      <div className="notification-chat mt-3">
                        <div>
                          <p className="m-0 text-lg tracking-tighter leading-[27px] font-inter font-medium text-[#5B5462]">
                            {val?.message}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="p-3 scale-[1.2]"
                    // onClick={() => handleNotificationClose(val.id)}
                  >
                    <RiCloseFill />
                  </div>
                </div>
              </div>
            </div>
          ))}
        {notificationState?.allNotifs?.data?.length <= 0 && (
          <div style={{ marginTop: "120px" }}>
            <EmptyStateComp
              title="Empty Notification"
              desc="Sorry, you currently don't have any notification"
            />
          </div>
        )}
      </div>
    </div>
  );
}
