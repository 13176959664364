import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import global from '../../constants/global'



export const allContentByCategoryRequest = createAsyncThunk('content/AllContentCategory', async (data) => {
    const response = await axios.get(`${global.apiBaseUrl}content/${data?.page}/all`, {})
    return response.data
})

export const viewContentRequest = createAsyncThunk('content/viewContent', async (data) => {
    const response = await axios.get(`${global.apiBaseUrl}content/view/${data}`, {})
    return response.data
})

export const subsectorsContentRequest = createAsyncThunk('content/subsectors', async (data) => {
    const response = await axios.get(`${global.apiBaseUrl}subsectors/${data?.page}`, {})
    return response.data
})

export const allContentBySubsectorsRequest = createAsyncThunk('content/allContentBySubsectors', async (data) => {
    const response = await axios.get(`${global.apiBaseUrl}content/${data?.page}/${data?.subsector}`, {})
    return response.data
})

export const allFilterValueRequest = createAsyncThunk('content/allFilterValue', async () => {
    const response = await axios.get(`${global.apiBaseUrl}allkeys`, {})
    return response.data
})

export const filterContentByValueRequest = createAsyncThunk('content/filterContentByValue', async (data) => {
    const response = await axios.get(`${global.apiBaseUrl}contents/filter?country${data?.country &&('='+data?.country)}&sector${data?.sector && ('='+data?.sector)}&sub_sector${data?.subsector && ('='+data?.subsector)}`, {})
    return response.data
})



export const allContentByCategorySlice = createSlice({
    name: 'allContentByCategory',
    initialState: {
        isLoading: false,
        data: null,
        error: null,
        // viewDataIsLoading: false,
        viewData:null,
        // viewDataError: null,
        // isLoading: false,
        subsectors:null,
        allValues:null,
        // error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(allContentByCategoryRequest.pending, (state, action) => {
            // if (!state.isLoading) {
            state.isLoading = true
            // }
        })
        builder.addCase(viewContentRequest.pending, (state, action) => {
            // if (!state.isLoading) {
            state.isLoading = true
            // }
        })


        builder.addCase(allContentByCategoryRequest.fulfilled, (state, action) => {
            // if (state.isLoading ) {
            // console.log(action.payload)
            state.data = action.payload
            state.isLoading = false
            // }
        })
        builder.addCase(viewContentRequest.fulfilled, (state, action) => {
            // if (state.isLoading ) {
            state.viewData = action.payload
            state.isLoading = false
            // }
        })
        builder.addCase(subsectorsContentRequest.fulfilled, (state, action) => {
            // if (state.isLoading ) {
            state.subsectors = action.payload
            state.isLoading = false
            // }
        })
        builder.addCase(allContentBySubsectorsRequest.fulfilled, (state, action) => {
            // if (state.isLoading ) {
            // console.log(action.payload)
            state.data = action.payload
            state.isLoading = false
            // }
        })
        builder.addCase(allFilterValueRequest.fulfilled, (state, action) => {
            state.allValues = action.payload
            state.isLoading = false
        })
        builder.addCase(filterContentByValueRequest.fulfilled, (state, action) => {
            state.data = action.payload
            state.isLoading = false
        })


        builder.addCase(allContentByCategoryRequest.rejected, (state, action) => {
            // console.log(action.payload)
            // if (state.isLoading ) {
            state.isLoading = false
            state.error = "nothing here"
            // }
        })
        builder.addCase(viewContentRequest.rejected, (state, action) => {
            // console.log(action.payload)
            // if (state.isLoading ) {
            state.isLoading = false
            state.error = "nothing here"
            // }
        })
    },
})




export default allContentByCategorySlice.reducer 