import emptyStateIcon from "../../assets/cuate.svg";
import listIcon from "../../assets/list.svg";

export function ActiveTag() {
  return <span className="success-tag">Active</span>;
}
export function ActiveTags() {
  return (
    <span className="border-2 border-green-200 rounded-[4px] p-2 text-[#006b00] font-PJ-medium font-medium text-xs leading-[15.12px] tracking-[-4%] ">
      Active
    </span>
  );
}
export function InactiveTag() {
  return <span className="failed-tag">Inactive</span>;
}
export function InactiveTags() {
  return (
    <span className="border-2 border-[#FF8A8A] rounded-[4px] p-2 text-[#410000] font-PJ-medium font-medium text-xs leading-[15.12px] tracking-[-4%]">
      Inactive
    </span>
  );
}
export function ClosedTag() {
  return <span className="failed-tag">Closed</span>;
}
export function PendingTag() {
  return (
    <span className="border-2 border-[#f5b285] rounded-[4px] p-2 text-[#B2431A] font-PJ-medium font-medium text-xs leading-[15.12px] tracking-[-4%]">
      PENDING
    </span>
  );
}
export function FailedTag() {
  return (
    <span className="border-2  border-[#FF8A8A] rounded-[4px] p-2 text-[#410000] font-PJ-medium font-medium text-xs leading-[15.12px] tracking-[-4%]">
      FAILED
    </span>
  );
}
export function VisitingTag() {
  return <span className="pending-tag">Visiting</span>;
}
export function PaidTag() {
  return (
    <span className="border-2 border-green-200 rounded-[4px] p-2 text-[#006b00] font-PJ-medium font-medium text-xs leading-[15.12px] tracking-[-4%] ">
      PAID
    </span>
  );
}

export function EmptyStateComp(props) {
  // getEmptystate 
  return (
    <div className="flex justify-center h-full items-center">
      <div className="w-[368px]">
        <div className="flex justify-center items-center">
          <img src={emptyStateIcon} alt="" width="200px" />
        </div>
        <p className="font-regularInter font-medium text-lg mt-8 text-center">
          {props?.desc}
        </p>
        {props?.ctaValue && (
          <div className="flex justify-center items-center my-5">
            <button
              onClick={props?.ctaAction}
              className="bg-primary text-light rounded-lg  px-5 py-3"
            >
              {props?.ctaValue}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export function MainLoader() {
  return (
    <div
      className="flex items-center justify-center"
      style={{ minHeight: "75vh" }}
    >
      <div className="text-center ">
        <div className="lds-dual-ring" />
        <p style={{ fontSize: "1.5rem", color: "#6261c4" }}> Loading ...</p>
      </div>
    </div>
  );
}
