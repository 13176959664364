import Cookies from "js-cookie";
import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Search from "../../assets/Search.svg";
import edit from "../../assets/edit-02.svg";
import del from "../../assets/trash-2.svg";
import moment from "moment";
import ClickOutside, {
  subscriptionPlans,
  userRole,
} from "../../components/utils/helpers";

import { EmptyStateComp, MainLoader } from "../../components/utils";
import Table from "../../reuseable/table/Table";
import CustomPagination from "../../components/customPagination/CustomPagination";
import Pagination from "../../reuseable/Pagination";
import { FaLink } from "react-icons/fa";
import {
  allFaqsRequest,
  deleteFaqsRequest,
} from "../../redux/features/faq/faqSlice";

export default function Faq() {
  const isMounted = useRef(false);
  const [loading, setLoading] = useState(false);
  const [adminDropDown, setAdminDropDown] = useState(false);
  const [businessDropDown, setBusinessDropDown] = useState(false);
  const [addTeamModal, setAddTeamModal] = useState(false);
  const [isEdit, setIsEdit] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedRole, setSelectedRole] = useState("");
  const [allFaqData, setAllFaqData] = useState([]);
  const [ind, setInd] = useState(0);
  const [singleFaqData, setSingleFaqData] = useState(0);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const itemsPerPage = 10;
  const routeState = useSelector((state) => state.routeReducer);

  const handleRoleChange = (e) => {
    setSelectedRole(e.target.value);
  };

  const location = useLocation();
  const { state } = location;
  const { orgSubscription, planName, menu, profileInfo } = routeState || {};

  const isPlanActive = orgSubscription?.is_active || "";
  const navigate = useNavigate();

  const teamData = JSON.parse(localStorage.getItem("tm"));

  const customersState = useSelector((state) => state.customersReducer);

  let dispatch = useDispatch();
  let orgId = Cookies.get("orgId") || "";

  const fetchAllFaq = () => {
    setLoading(true);
    dispatch(allFaqsRequest({ organization_id: orgId }))
      .then((res) => {
        setLoading(false);
        if (res?.payload?.status === 200) {
          setAllFaqData(res?.payload?.data);
        } else {
          toast.error(res?.payload?.data?.detail);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (isMounted.current) return;
    isMounted.current = true;
    fetchAllFaq();
  }, [state]);

  useEffect(() => {
    if (isMounted.current) return;
    isMounted.current = true;
    if (search.length <= 0) {
      fetchAllFaq();
    } else {
      const filteredArr = allFaqData?.filter((data) =>
        data?.title?.toLowerCase().includes(search.toLowerCase())
      );
      setAllFaqData(filteredArr);
    }
  }, [search]);

  const truncateWithEllipsis = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    } else {
      return text.slice(0, maxLength) + "...";
    }
  };

  const node = ClickOutside(() => {
    setAdminDropDown(false);
    setBusinessDropDown(false);
  });

  const businessDropdowns = (data, index) => {
    setBusinessDropDown(true);
    setSingleFaqData(data);
    if (data && isEdit === "edit") {
      setEditMode(true);
    } else {
      setEditMode(false);
    }
  };

  console.log(singleFaqData);

  const showDeleteConfirmation = () => {
    setShowDeleteModal(true);
  };

  const hideDeleteConfirmation = () => {
    setShowDeleteModal(false);
  };

  const handleDelete = () => {
    dispatch(
      deleteFaqsRequest({
        faq_id: singleFaqData?.faq_id,
      })
    )
      .then((res) => {
        if (res?.payload?.status === 200) {
          toast.success("Faq Deleted Successfully");
          setShowDeleteModal(false);
          fetchAllFaq();
        } else {
          toast.error(res?.payload?.data?.detail);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    setTotalPages(Math.ceil(allFaqData?.length / itemsPerPage));
  }, [allFaqData?.length]);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  // const copyFaqLink = (FaqId) => {
  //   const link = `https://venixs.com/article?id=${FaqId}&title=${singleFaqData?.title}`;
  //   navigator.clipboard
  //     .writeText(link)
  //     .then(() => {
  //       toast.success("Faq link copied to clipboard!");
  //     })
  //     .catch((err) => {
  //       toast.error("Failed to copy the link.");
  //     });
  // };

  console.log(adminDropDown, businessDropDown);

  const columns = [
    {
      Header: "S/N",
      accessor: "id",
      Cell: (row) => (
        <div className="font-PJ-bold  font-medium text-xs text-[#424242]">
          {currentPage > 0
            ? itemsPerPage * currentPage + parseInt(row.cell.row.id) + 1
            : parseInt(row.cell.row.id) + 1}
        </div>
      ),
    },
    {
      Header: "Title",
      accessor: "title",
      Cell: (row) => (
        <div className="font-PJMedium whitespace-nowrap font-medium text-lg">
          {truncateWithEllipsis(row.cell.value, 20)}
        </div>
      ),
    },

    {
      Header: "Description",
      accessor: "description",
      Cell: (row) => (
        <div className="font-inter font-normal whitespace-nowrap text-[14px] text-[#424242] ">
          {truncateWithEllipsis(row.cell.value.replace(/<[^>]*>/g, ""), 35)}
        </div>
      ),
    },
    // {
    //   Header: "Faq Type",
    //   accessor: "Faq_category",
    //   Cell: (row) => (
    //     <div className="font-inter font-normal text-[14px] text-center text-[#424242] ">
    //       {row.cell.value}
    //     </div>
    //   ),
    // },

    {
      Header: "Date Posted",
      accessor: "time_created",
      Cell: (row) => (
        <div className="font-inter whitespace-nowrap font-normal text-[14px] text-[#424242] ">
          {" "}
          {moment(row.cell.value).format("MMM DD, yyyy")}
        </div>
      ),
    },
    {
      Header: " ",
      accessor: "action",
      Cell: (row) => (
        <div>
          <div
            className="dropdown-trigger"
            onClick={() => {
              businessDropdowns(row.cell.row.original, true);
              setInd(row.cell.row.id);
            }}
          >
            <i className="ri-more-fill me-2 ri-xl" />
          </div>
          {row.cell.row.id === ind && businessDropDown && (
            <div ref={node} className="dropdown-content">
              <Link
                state={{
                  orgSubscription,
                  planName,
                  menu,
                  profileInfo,
                  singleFaqData,
                }}
                style={{ textDecorationLine: "none" }}
                to={"/Faq/compose"}
                className="editDrop hover:bg-gray-100"
                onClick={() => {
                  setAddTeamModal(true);
                  setIsEdit("edit");
                }}
              >
                <img src={edit} width={16.6} alt="edit" />
                Edit Faq
              </Link>
              <p
                className="deleteDrop hover:bg-red-50"
                onClick={showDeleteConfirmation}
              >
                <img src={del} width={16.6} alt="edit" />
                Delete Faq
              </p>
              {/* <p
                className="flex gap-[10px] items-center p-2 text-blue-500 cursor-pointer font-PJ-medium text-[12px] leading-6 tracking-[-0.051px] hover:bg-blue-50 "
                onClick={() =>
                  copyFaqLink(row.cell.row.original.Faq_id)
                }
              >
                <FaLink className=" w-[16.6px] text-blue-500 items-center  " />
                Faq Link
              </p> */}
            </div>
          )}
          {showDeleteModal && (
            <>
              <div className="overlay fixed inset-0 bg-gray-300 opacity-50"></div>
              <div className="delete-modal fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg shadow-lg p-4">
                <div className="delete-modal-content">
                  <div className="flex gap-4 items-center">
                    <div className="deleteBin">
                      <img src={del} alt="delete" />
                    </div>
                    <div className="modalBody">
                      <p className="font-semibold text-lg">
                        This action cannot be undone
                      </p>
                      <p className="text-base">
                        Are you sure you want to delete this user profile?
                      </p>
                    </div>
                  </div>
                  <hr className="my-4 border-gray-400" />
                  <div className="delete-modal-buttons flex justify-end">
                    <button
                      className="mr-2 px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
                      onClick={hideDeleteConfirmation}
                    >
                      Cancel
                    </button>
                    <button
                      className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                      onClick={handleDelete}
                    >
                      Delete Profile
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className="2xl:px-[20px] xl:px-[10px] lg:px-[10px]  ">
      {loading ? (
        <MainLoader />
      ) : (
        <>
          {(planName === subscriptionPlans.PROFESSIONAL ||
            planName === subscriptionPlans.ADVANCED) &&
          isPlanActive === true ? (
            <div className="teams">
              <div className="">
                <div className=" ">
                  {teamData?.role === userRole.ADMIN && (
                    <div className="">
                      <div className=" flex justify-end">
                        <button
                          onClick={() => {
                            navigate("/faq/compose", {
                              state: {
                                orgSubscription,
                                planName,
                                menu,
                                profileInfo,
                              },
                            });
                            setIsEdit("add");
                          }}
                          className=" bg-primary mb-5 text-light px-3 py-3 rounded-lg font-inter text-lg "
                        >
                          Create Faq
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className=" ">
                <div className="">
                  <div className="">
                    <div className=" mb-11">
                      <Table
                        columns={columns}
                        data={allFaqData}
                        currentPage={currentPage}
                        itemPerPage={itemsPerPage}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {showDeleteModal && (
                <>
                  <div className="overlay"></div>
                  <div className="delete-modal">
                    <div className="delete-modal-content">
                      <div className="flex gap-4 ">
                        <div className=" deleteBin">
                          <img src={del} alt="delete" />
                        </div>
                        <div className=" modalBody">
                          <p style={{ fontSize: "17px", fontWeight: "500" }}>
                            This action can not be undone
                          </p>
                          <p style={{ fontSize: "15px" }}>
                            Are you sure you want to delete this Faq?{" "}
                          </p>
                        </div>
                      </div>
                      <hr color="#333" />
                      <div className="delete-modal-buttons">
                        <button
                          className="deleteButton1"
                          onClick={hideDeleteConfirmation}
                        >
                          Cancel
                        </button>
                        <button
                          className="deleteButton2"
                          onClick={handleDelete}
                        >
                          Delete Faq
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          ) : (
            <div className="">
              <div style={{ marginTop: "150px" }}>
                <EmptyStateComp
                  title={
                    isPlanActive === true
                      ? "No Faq yet"
                      : "No Active Subscription"
                  }
                  desc={
                    isPlanActive === true
                      ? "You have no Faq added yet, your Faq will appear here as soon as you add some. "
                      : "You have no active subscription, your Faq will appear here as soon as you subscribe. "
                  }
                />
              </div>
            </div>
          )}

          <div className="py-2 flex justify-end items-end">
            {allFaqData?.length > 0 && (
              <Pagination
                onChange={handlePageChange}
                pageCount={totalPages}
                initialPage={currentPage}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
}
