import React from "react";

const GraphSkeleton = () => {
  return (
    <div className="border mb-11 mt-5 rounded-t-xl relative bg-white py-4">
      <table className={`w-full`}>
        <thead className={`whitespace-nowrap`}>
          <tr>
            <th
              className={`font-PJ-medium h-[50px] w-[50px] px-6 text-base text-start ml-6 rounded py-3`}
            >
              <div className="skeleton-squared h-6 bg-gray-300 rounded"></div>
            </th>
            <th
              className={`font-PJ-medium h-[50px] w-[150px] px-6 text-base text-start ml-6 rounded py-3`}
            >
              <div className="skeleton-squared h-6 bg-gray-300 rounded"></div>
            </th>
            <th
              className={`font-PJ-medium  h-[50px] w-[200px] px-6 text-base text-start ml-6 rounded py-3`}
            >
              <div className="skeleton-squared h-6 bg-gray-300 rounded"></div>
            </th>
            <th
              className={`font-PJ-medium h-[50px]  w-[200px] px-6 text-base text-start ml-6 rounded py-3`}
            >
              <div className="skeleton-squared h-6 bg-gray-300 rounded"></div>
            </th>
            <th
              className={`font-PJ-medium h-[50px] w-[200px] px-6 text-base text-start ml-6 rounded py-3`}
            >
              <div className="skeleton-squared h-6 bg-gray-300 rounded"></div>
            </th>
            <th
              className={`font-PJ-medium h-[50px] w-[200px] px-6 text-base text-start ml-6 rounded py-3`}
            >
              {/* <div className="skeleton-squared h-6 bg-gray-300 rounded"></div> */}
            </th>
          </tr>
        </thead>
        <tbody>
          {[...Array(7)].map((_, index) => (
            <tr key={index} className={`even:bg-[#fafafa] bg-white`}>
              <td className={`capitalize px-6 py-6 border-t-[1px]`}>
                <div className="skeleton-rect h-6 bg-gray-300 rounded animate-pulse"></div>
              </td>
              <td className={`capitalize px-6 py-6 border-t-[1px]`}>
                <div className="skeleton-rect h-6 bg-gray-300 rounded animate-pulse"></div>
              </td>
              <td className={`capitalize px-6 py-6 border-t-[1px]`}>
                <div className="skeleton-rect h-6 bg-gray-300 rounded animate-pulse"></div>
              </td>
              <td className={`capitalize px-6 py-6 border-t-[1px]`}>
                <div className="skeleton-rect h-6 bg-gray-300 rounded animate-pulse"></div>
              </td>
              <td className={`capitalize px-6 py-6 border-t-[1px]`}>
                <div className="skeleton-rect h-6 bg-gray-300 rounded animate-pulse"></div>
              </td>
              <td className={`capitalize px-6 py-6 border-t-[1px]`}>
                <div className="skeleton-rect h-6 bg-gray-300 rounded animate-pulse"></div>
              </td>{" "}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default GraphSkeleton;
