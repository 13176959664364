import { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import UserComp from "../user";
import SidebarLogo from "../../../assets/Venixs 2 1 (1).svg";
import { useDispatch } from "react-redux";
import { profileInfoRequest } from "../../../redux/features/profile/profileSlice";
import toast from "react-hot-toast";
import { orgSubscriptionRequest } from "../../../redux/features/subscription/subscriptionSlice";
import Cookies from "js-cookie";
import { subscriptionPlans } from "../../utils/helpers";
import home from "../../../assets/sideBarIcons/category.svg";
import message from "../../../assets/sideBarIcons/message-text.svg";
import customer from "../../../assets/sideBarIcons/users-01.svg";
import ticket from "../../../assets/sideBarIcons/ticket.svg";
import config from "../../../assets/sideBarIcons/gridicons_customize.svg";
import teams from "../../../assets/sideBarIcons/fluent_people-community-16-regular.svg";
import subscription from "../../../assets/sideBarIcons/streamline_subscription-cashflow.svg";
import broadcast from "../../../assets/sideBarIcons/ri_broadcast-fill.svg";
import marketplace from "../../../assets/sideBarIcons/mdi_shop-outline.svg";
import settings from "../../../assets/sideBarIcons/settings-01.svg";
import notification from "../../../assets/sideBarIcons/notification-bing.svg";
import faq from "../../../assets/sideBarIcons/faq-svgrepo-com.svg";
import { FaAngleDown } from "react-icons/fa";
import {
  setIsExpired,
  setOrgSubscription,
  setPlanName,
  setProfileInfo,
} from "../../../redux/features/route/routeSlice";

export default function Sidebar() {
  const isMounted = useRef(false);
  let [openSubMenu, setOpenSubMenu] = useState(false);
  const [orgSubscription, setOrgSubscriptions] = useState(null);
  const [profileInfo, setProfileInfos] = useState(null);
  const [planName, setPlanNames] = useState("");
  const [subMenuId, setSubMenuId] = useState("");
  const [icon, setIcon] = useState(false);

  let orgId = Cookies.get("orgId") || "";

  let dispatch = useDispatch();
  let location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const subscribeMenu = ["Ticketing", "Broadcasting"];

  const SIDE_MENUS = [
    {
      id: 0,
      header: "MAIN",
      children: [
        {
          id: 1,
          name: "Home",
          icon: home,
          route: "/",
          subMenuIcon: <FaAngleDown />,
          isSubMenu: true,
          routeName: "home",
          subMenu: [
            {
              route: "/home/getting-started",
              name: "Getting Started",
              routeName: "getting-started",
            },
            {
              route: "/home/dashboard",
              name: "Dashboard",
              routeName: "home",
            },
          ],
        },
        {
          id: 2,
          name: "Messages",
          route: "/messages",
          icon: message,
          routeName: "messages",
        },
        {
          id: 3,
          name: "Customers",
          route: "customers/all-customers",
          icon: customer,
          routeName: "customers",
        },
      ],
    },
    {
      id: 4,
      header: "PROPERTIES",
      children: [
        {
          id: 5,
          name: "Ticketing",
          icon: ticket,
          route: "/ticketing",
          routeName: "ticketing",
        },
        {
          id: 6,
          name: "Subscription",
          icon: subscription,
          route: "/subscription",
          routeName: "subscription",
        },
        {
          id: 7,
          name: "Marketplace",
          icon: marketplace,
          route: "/marketplace",
          routeName: "marketplace",
        },
        {
          id: 8,
          name: "Broadcasting",
          icon: broadcast,
          route: "/broadcast",
          routeName: "broadcast",
        },
        {
          id: 9,
          name: "Configurations",
          icon: config,
          route: "/configuration",
          subMenuIcon: <FaAngleDown />,
          isSubMenu: true,
          routeName: "configuration",
          subMenu: [
            {
              route: "/configuration/general-configuration",
              name: "General Configuration",
              routeName: "configuration",
            },
            {
              route: "/configuration/data-configuration",
              name: "Data Configuration",
              routeName: "configuration",
            },
            {
              route: "/configuration/escalation-configuration",
              name: "Escalation Configuration",
              routeName: "configuration",
            },
            {
              route: "/configuration/developers-integration-configuration",
              name: "Developers Integration",
              routeName: "configuration",
            },
          ],
        },
      ],
    },
    {
      id: 10,
      header: "OTHERS",
      children: [
        {
          name: "Teams",
          route: "/teams",
          icon: teams,
          routeName: "teams",
        },
        {
          name: "Faq",
          route: "/faq",
          icon: faq,
          routeName: "faq",
        },
        // {
        //   name: "Notifications",
        //   route: "notifications/all-notifications",
        //   icon: notification,
        //   routeName: "notifications",
        // },
        {
          name: "Settings",
          route: "/settings",
          icon: settings,
          routeName: "settings",
        },
      ],
    },
  ];

  let getProfileInfo = () => {
    dispatch(profileInfoRequest())
      .then((res) => {
        setProfileInfos(res?.payload?.data);
        dispatch(setProfileInfo(res?.payload?.data));
        if (res?.payload?.status !== 200) {
          toast.error(res?.payload?.data?.response);
        }
      })
      .catch((err) => {});
  };

  let getOrgSubscription = () => {
    let data = {
      organization_id: orgId,
    };

    dispatch(orgSubscriptionRequest(data))
      .then((res) => {
        if (res?.payload?.status === 200) {
          const arr = res.payload.data.filter(
            (item) => item.is_active === true
          );
          dispatch(setOrgSubscription(arr[0]));
          dispatch(setIsExpired(arr.length <= 0 ? true : false));
          dispatch(setPlanName(arr[0].subscription_plan_id?.name));
          setPlanNames(arr[0].subscription_plan_id?.name);
          // setOrgSubscriptions(arr[0]);
          // dispatch(setOrgSubscription(arr[0]));
        } else {
          toast.error(res?.payload?.data?.response);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    // if (isMounted.current) return
    // isMounted.current = true
    getProfileInfo();
    getOrgSubscription();
  }, [isMounted]);

  return (
    <div className="sidebar-sm fixed-top">
      <div className="sidebar-area px-4">
        <div className="sideBars !w-full">
          <div className="header py-2 md:py-5 mx-auto text-center">
            <Link to="/" className="link">
              <img
                style={{
                  width: "141px",
                  height: "48px",
                }}
                src={SidebarLogo}
                className="pt-2"
                width="45px"
                alt=""
              />
            </Link>
            <div className="lg:hidden">
              <UserComp />
            </div>
          </div>

          <div className="navigation w-full mr-4 mt-4 hidden !bg-black lg:block">
            <ul>
              {SIDE_MENUS.map((item, ind) => (
                <div key={ind}>
                  <p
                    className={`${
                      ind > 0 ? "mt-18" : ""
                    } pl-3 font-Inter font-normal text-[11px] text-white opacity-80`}
                  >
                    {item.header}
                  </p>
                  {item?.children?.map((menu, index) => (
                    <ul key={index}>
                      {planName === subscriptionPlans.ADVANCED ||
                      planName === subscriptionPlans.PROFESSIONAL ? (
                        <Link
                          to={{ pathname: menu?.route }}
                          state={{
                            orgSubscription,
                            planName,
                            menu: menu?.name,
                            route: menu?.route,
                            profileInfo,
                          }}
                          className="link"
                        >
                          <li
                            onClick={() => {
                              menu?.isSubMenu
                                ? setOpenSubMenu(true)
                                : setOpenSubMenu(false);
                              setSubMenuId(menu.id);
                            }}
                            className={`flex items-center h-10 p-[10px] shadow-custom-inset font-PJMedium text-[14px] leading-[24px] tracking-[-0.2px] rounded-lg ${
                              (splitLocation[1] || "home") === menu.routeName
                                ? "bg-custom-gradient shadow-custom-inset text-light !rounded-lg"
                                : "text-gray-400 hover:bg-custom-gradient hover:text-white !rounded-lg"
                            }`}
                          >
                            <img
                              src={menu.icon}
                              alt={menu.name}
                              className="mr-2 !w-[18px] !h-[18px] "
                            />
                            <span className="flex-1">{menu.name}</span>
                            {menu.subMenuIcon && (
                              <span className="ml-2">{menu.subMenuIcon}</span>
                            )}
                          </li>
                        </Link>
                      ) : (
                        !subscribeMenu.includes(menu.name) && (
                          <Link
                            to={{ pathname: menu?.route }}
                            state={{
                              orgSubscription,
                              planName,
                              menu: menu?.name,
                              route: menu?.route,
                              profileInfo,
                            }}
                            className="link"
                          >
                            <li
                              onClick={() => {
                                menu?.isSubMenu
                                  ? setOpenSubMenu(true)
                                  : setOpenSubMenu(false);
                                setSubMenuId(menu.id);
                              }}
                              className={`flex items-center h-10  p-[10px] font-PJMedium text-[13px] leading-[24px] tracking-[-0.2px] rounded-lg ${
                                (splitLocation[1] || "home") === menu.routeName
                                  ? "bg-custom-gradient shadow-custom-inset text-white"
                                  : "text-gray-400 hover:bg-custom-gradient hover:text-white !hover:rounded-lg"
                              }`}
                            >
                              <img
                                src={menu.icon}
                                alt={menu.name}
                                className="mr-2 w-[18px] h-[18px]  "
                              />
                              <span className="flex-1">{menu.name}</span>
                              {menu.subMenuIcon && (
                                <span className="ml-2">{menu.subMenuIcon}</span>
                              )}
                            </li>
                          </Link>
                        )
                      )}

                      {openSubMenu && menu.id === subMenuId && (
                        <div className="pl-6">
                          {menu?.subMenu?.map((data, ind) => (
                            <div
                              key={ind}
                              className="sub-menu border-l border-l-[#434A60]"
                            >
                              <Link
                                state={{
                                  orgSubscription,
                                  planName,
                                  menu: menu?.name,
                                  routeName: data?.name,
                                  route: data?.route,
                                  profileInfo,
                                }}
                                to={data?.route}
                                className={`sub-menu-child ml-4 pl-4 h-10   block shadow-custom-inset p-1 font-PJMedium text-[13px] leading-[24px] tracking-[-0.2px] rounded-lg ${
                                  pathname === `${data?.route}`
                                    ? "bg-custom-gradient shadow-custom-inset-xl  text-white sub"
                                    : "text-gray-400 hover:bg-custom-gradient hover:text-white hover:rounded-lg"
                                }`}
                              >
                                {data?.name}
                              </Link>
                            </div>
                          ))}
                        </div>
                      )}
                    </ul>
                  ))}
                </div>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
