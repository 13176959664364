import React, { useState, useEffect } from "react";
import SignUpStep1 from "./step1";
import SignUpStep2 from "./step2";
import SignUpStep3 from "./step3";
import AuthHeader from "../../../reuseable/authHeader";

const SignUpPages = () => {
  const [step, setStep] = useState(1);
  const [isVisible, setIsVisible] = useState(true);
  const [details, setDetails] = useState({});

  useEffect(() => {
    if (step === 1) window.scrollTo(0, 0);
  }, [step]);

  const handleNextStep = () => {
    setIsVisible(false);
    setTimeout(() => {
      setStep(step + 1);
      setIsVisible(true);
    }, 500);
  };

  const handlePreviousStep = () => {
    setIsVisible(false);
    setTimeout(() => {
      setStep(step - 1);
      setIsVisible(true);
    }, 500);
  };

  return (
    <div className="auth-page-area ">
      <AuthHeader />
      <div className="flex justify-center items-center h-full">
        <div className="w-full  max-w-md">
          <div className="mx-auto">
            {isVisible && (
              <>
                {step === 1 && <SignUpStep1 onNext={handleNextStep} />}
                {step === 2 && (
                  <SignUpStep2
                    setDetails={setDetails}
                    onNext={handleNextStep}
                    onPrevious={handlePreviousStep}
                  />
                )}
                {step === 3 && (
                  <SignUpStep3
                    details={details}
                    onPrevious={handlePreviousStep}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpPages;
