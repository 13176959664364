import React, { useState } from "react";
import Header from "../../../reuseable/header";
import { BsBrushFill, BsDot, BsThreeDots } from "react-icons/bs";
import {
  FaCheck,
  FaChevronDown,
  FaChevronRight,
  FaGlobeAfrica,
  FaGreaterThan,
  FaTimes,
  FaTimesCircle,
} from "react-icons/fa";
import { TbExclamationCircle, TbHomeCancel } from "react-icons/tb";
import { IoClose, IoPricetag } from "react-icons/io5";
import logo from "../../../assets/Venixs 2 1 (1).svg";
import people from "../../../assets/widgetIcons/Frame 1000006239.png";
import send from "../../../assets/widgetIcons/Icon.svg";
import skeleton from "../../../assets/widgetIcons/Frame 1000006186.png";
import avatar from "../../../assets/widgetIcons/Ellipse 10.svg";
import home from "../../../assets/widgetIcons/home.svg";
import message from "../../../assets/widgetIcons/message-text.svg";
import faq from "../../../assets/widgetIcons/faq.svg";
import CustomDropdown from "../../../reuseable/dropDown";

const WidgetAppearance = () => {
  const [step, setStep] = useState(1);
  const [checked, setChecked] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [language, setLanguage] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedColor, setSelectedColor] = useState(null);
  const [blackCardColor, setBlackCardColor] = useState("#000");

  const colors = [
    "#c2daf1",
    "#000", // green
    "#DED848", // blue
    "#229ACE", // yellow
    "#DA3D3D", // magenta
    "#D9D9D9", // cyan
    "#800080", // purple
    "#ff7f50", // coral
    "#ffa500", // orange
    "#ff6347", // tomato
    "#ff4500", // orangered
    "#ffd700", // gold
    "#ffa07a", // lightcoral
    "#20b2aa", // lightseagreen
    "#ff69b4", // hotpink
    "#8a2be2", // blueviolet
    "#00ced1", // darkturquoise
    "#7fffd4", // aquamarine
    "#008080", // teal
    "#8b0000", // darkred
  ];

  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [availableLanguages, setAvailableLanguages] = useState([
    { value: "English", label: "English" },
    { value: "French", label: "French" },
    { value: "Spanish", label: "Spanish" },
  ]);

  const handleLanguageSelect = (language) => {
    setSelectedLanguages([...selectedLanguages, language]);
    setAvailableLanguages(
      availableLanguages.filter((lang) => lang.value !== language.value)
    );
  };

  const handleRemoveLanguage = (language) => {
    setSelectedLanguages(
      selectedLanguages.filter((lang) => lang.value !== language.value)
    );
    setAvailableLanguages([...availableLanguages, language]);
  };
  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  const visibleColors = colors.slice(0, 5);

  const handleDropdownClick = () => {
    setShowDropdown(!showDropdown);
  };

  const handleStepChange = (newStep) => {
    setStep(newStep);
  };

  const handleToggle = () => {
    setChecked((prevChecked) => !prevChecked);
  };

  const handleColorSelection = (color) => {
    setSelectedColor(color);
    setBlackCardColor(color);
  };
  const [alignment, setAlignment] = useState("left");

  const handleAlignmentChange = (event) => {
    setAlignment(event.target.value);
  };

  return (
    <div>
      <Header headerTitle={"Appearance"} />
      <div className="widgetMain ">
        <div className="leftWidget">
          <div className={`accordion-step ${step === 1 ? "active" : ""}`}>
            <div
              className="accordion-header"
              onClick={() => handleStepChange(1)}
            >
              <div
                style={{ display: "flex ", justifyContent: "space-between" }}
              >
                <div className="flex gap-2 align-content-center">
                  <BsBrushFill size={18} style={{ marginTop: "2px" }} />{" "}
                  <h4>Widget Appearance</h4>
                </div>
                <div>
                  <FaChevronDown />
                </div>
              </div>
            </div>
            {step === 1 && (
              <>
                <div className="accordion-content">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div>
                      <div className=" accordionContent  ">
                        <div>
                          <h6>Background Color</h6>
                        </div>
                        <div style={{ position: "relative" }}>
                          <div style={{ display: "flex", gap: "24px" }}>
                            {visibleColors.map((color, index) => (
                              <div
                                key={index}
                                style={{
                                  position: "relative",
                                  width: "48px",
                                  height: "48px",
                                  backgroundColor: color,
                                  borderRadius: "50%",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleColorSelection(color)}
                              >
                                {selectedColor === color && (
                                  <FaCheck
                                    style={{
                                      position: "absolute",
                                      top: "50%",
                                      left: "50%",
                                      transform: "translate(-50%, -50%)",
                                      color: "white",
                                    }}
                                  />
                                )}
                              </div>
                            ))}
                            <div
                              style={{
                                cursor: "pointer",
                                fontSize: "24px",
                                backgroundColor: "#D9D9D9",
                                height: "50px",
                                width: "50px",
                                borderRadius: "50px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                              onClick={handleDropdownClick}
                            >
                              <BsThreeDots />
                            </div>
                          </div>
                          {showDropdown && (
                            <div
                              style={{
                                position: "absolute",
                                top: "100%",

                                width: "80%",
                                display: "flex",
                                marginTop: "5px",
                                gap: "2px",
                                flexWrap: "wrap",
                                left: 50,
                                backgroundColor: "#fff",
                                border: "1px solid #ccc",
                                padding: "8px",
                                borderRadius: "8px",
                                zIndex: 999,
                              }}
                            >
                              {colors.map((color, index) => (
                                <div
                                  key={index}
                                  style={{
                                    position: "relative",
                                    width: "40px",
                                    height: "40px",
                                    backgroundColor: color,
                                    borderRadius: "50%",
                                    cursor: "pointer",
                                    marginRight: "8px",
                                  }}
                                  onClick={() => handleColorSelection(color)}
                                >
                                  {selectedColor === color && (
                                    <FaCheck
                                      style={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                        color: "white",
                                      }}
                                    />
                                  )}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="accordionContent  ">
                        <div>
                          <h6>Welcome Image</h6>
                        </div>
                        <div>
                          <div
                            className="radioCollage"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "20px",
                            }}
                          >
                            <input
                              type="radio"
                              id="operators"
                              name="option"
                              value="operators"
                              checked={selectedOption === "operators"}
                              onChange={() => handleOptionSelect("operators")}
                              style={{
                                backgroundColor: "blue",
                                cursor: "pointer",
                                width: "16px",
                                height: "16px",
                              }}
                            />
                            <div className="operatorsCollage">
                              <h6 className="">
                                {" "}
                                Operators Collage <br />{" "}
                                <span>
                                  Show a collage of operators profile at the top
                                  of the widget
                                </span>
                              </h6>
                            </div>
                          </div>
                          <div
                            className=""
                            style={{
                              display: "flex",
                              gap: "20px",
                            }}
                          >
                            <input
                              className=""
                              type="radio"
                              id="logo"
                              name="option"
                              value="logo"
                              checked={selectedOption === "logo"}
                              onChange={() => handleOptionSelect("logo")}
                              style={{
                                backgroundColor: "gray",
                                cursor: "pointer",
                                borderRadius: "50%",
                                width: "16px",
                                height: "16px",
                              }}
                            />
                            <p>Your Logo</p>
                          </div>
                        </div>
                      </div>
                      <div className="accordionContent ">
                        <div>
                          <h6>Online Status</h6>
                          <p className="OnlineP">
                            Show this message in <br /> a chat widget when{" "}
                            <br /> you're 'online in your <br />
                            venixs panel
                          </p>
                        </div>
                        <div>
                          <input
                            className="widgetInput"
                            type="text"
                            placeholder="Typically Respond Instantly"
                          />
                        </div>
                      </div>
                      <div className="accordionContent">
                        <div>
                          <h6>Widget Position</h6>
                        </div>
                        <div
                          className="radioAlignment"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <label
                            style={{
                              position: "relative",
                              marginRight: "10px",
                            }}
                          >
                            <input
                              className="leftRadio"
                              type="radio"
                              value="left"
                              checked={alignment === "left"}
                              onChange={handleAlignmentChange}
                              style={{
                                width: "20px",
                                height: "20px",
                                position: "absolute",
                                left: "-12px",
                                top: "40%",
                                transform: "translateY(-50%)",
                              }}
                            />
                          </label>

                          <label style={{ position: "relative" }}>
                            <input
                              className="rightRadio"
                              type="radio"
                              value="right"
                              checked={alignment === "right"}
                              onChange={handleAlignmentChange}
                              style={{
                                width: "18px",
                                height: "18px",
                                position: "absolute",
                                right: "-12px", // Adjust as needed to align with the border
                                top: "40%",
                                transform: "translateY(-50%)",
                              }}
                            />
                          </label>
                        </div>
                      </div>
                      <div
                        style={{
                          marginTop: "72px",
                          display: "flex",
                          gap: "100px",
                        }}
                      >
                        <h6>Enable Widget Sound</h6>
                        <div
                          className="toggle-container"
                          onClick={handleToggle}
                        >
                          <div
                            className={`toggle ${checked ? "checked" : ""}`}
                          ></div>
                        </div>
                      </div>
                      <div className="accordionContent">
                        <div style={{}}>
                          <h6>Widget Logo</h6>
                          <p className="widgetLogo">
                            Custom branding in <br /> widget and emails
                          </p>
                        </div>
                        <div className="customBranding">
                          <TbExclamationCircle color="#592C83" />
                          <p>
                            Custom branding is available on the Venixs plan.{" "}
                            <br /> Visit <span>Subscriptions</span> to activate
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>

          {/* Step 2 */}
          <div className={`accordion-step ${step === 2 ? "active" : ""}`}>
            <div
              className="accordion-header"
              onClick={() => handleStepChange(2)}
            >
              <div className="flex justify-between">
                <div className="flex gap-2">
                  <IoPricetag size={18} style={{ marginTop: "6px" }} /> Button
                  Label
                </div>
                <div>
                  <FaChevronDown />
                </div>
              </div>
            </div>
            {step === 2 && (
              <div className="accordion-content">
                <div className="flex justify-center">
                  <div>
                    <div className="accordionContent">
                      <div>
                        <h6>Button Label</h6>
                        {/* <p>Custom branding in widget and emails</p> */}
                      </div>
                      <div className="toggle-container" onClick={handleToggle}>
                        <div
                          className={`toggle ${checked ? "checked" : ""}`}
                        ></div>
                      </div>
                    </div>
                    <div className="accordionContent">
                      <div style={{ marginTop: "72px" }}>
                        <h6>Label Text</h6>
                        <p>
                          Include a short <br /> message next to the <br />{" "}
                          closed chat icon.
                        </p>
                      </div>
                      <div>
                        <input
                          className="widgetInput"
                          type="text"
                          placeholder="Typically Respond Instantly"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className={`accordion-step ${step === 3 ? "active" : ""}`}>
            <div
              className="accordion-header"
              onClick={() => handleStepChange(3)}
            >
              <div className="flex justify-between">
                <div className="flex gap-2">
                  <FaGlobeAfrica size={18} style={{ marginTop: "5px" }} />{" "}
                  Multilingual
                </div>
                <div>
                  <FaChevronDown />
                </div>
              </div>
            </div>
            {step === 3 && (
              <div className="accordion-content">
                <div>
                  <p>
                    Add as many languages as you want and let your customers use
                    the widget in the language they have set up for their
                    browser.
                  </p>
                  <div>
                    {/* <CustomDropdown
                      options={availableLanguages.map((val) => ({ value: val?.value, label: val.label }))}
                      selectedOption={language}
                      onChange={(option) => { setLanguage(option) }}
                      placeholder="Preview Language Selection"
                    /> */}
                    <select className="widgetInput" name="" id="">
                      <option disabled selected>
                        Preview Language Selection
                      </option>
                      {availableLanguages.map((language) => (
                        <option key={language.value} value={language.value}>
                          {language.label}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div>
                    <div>
                      {selectedLanguages.map((language) => (
                        <div
                          className="border selectResult"
                          key={language.value}
                        >
                          <p>{language.value}</p>
                          <IoClose
                            size={15}
                            className="mt-2"
                            onClick={() => handleRemoveLanguage(language)}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      ))}
                    </div>
                    <select
                      className="widgetInput mt-2"
                      name=""
                      placeholder="Please select a language"
                      id=""
                      onChange={(e) => {
                        handleLanguageSelect({
                          value: e.target.value,
                          label: e.target.value,
                        });
                      }}
                    >
                      <option disabled selected>
                        Please select a language{" "}
                      </option>
                      {availableLanguages.map((language) => (
                        <option key={language.value} value={language.value}>
                          {language.label}
                        </option>
                      ))}
                    </select>
                    <button className="countryButton">Add </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="rightWidget">
          <div className="flex justify-center items-center">
            <div>
              <div>
                <button className="widgetButt">
                  <p>
                    Preview <FaGreaterThan /> WidgetAppearance
                  </p>
                </button>
                <div>
                  <div className="widgetCard">
                    <div></div>
                    <div>
                      <div
                        className="blackCard"
                        style={{ backgroundColor: blackCardColor }}
                      >
                        <img src={logo} alt="logo" />

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            padding: "15px",
                            alignItems: "center",
                          }}
                        >
                          <div
                            className="nameHeader"
                            style={{ color: "white" }}
                          >
                            <h6>Hello Stephenie</h6>
                            <p>How may we help you?</p>
                          </div>
                          <div>
                            <img src={people} alt="People" />
                          </div>
                        </div>
                      </div>

                      <div className="flex justify-center">
                        <div className="">
                          <div>
                            <div className="border-1 rounded-10 flex justify-between ">
                              <div className="">
                                <h6 className="font-PJ-medium font-medium text-[10.5px]">
                                  Send us a message
                                </h6>
                                <p className="font-inter font-normal  text-[7.5px]">
                                  We typical reply instantly or within 2 hours
                                </p>
                              </div>
                              <div>
                                <img src={send} alt="send" />
                              </div>
                            </div>
                            <div
                              className="border"
                              style={{
                                backgroundColor: "white",
                                width: "85%",
                                marginTop: "50px",
                                borderRadius: "8px",
                                marginLeft: "25px",
                              }}
                            >
                              <img src={skeleton} alt="skeleton" />
                            </div>
                            <div className="flex inAppBroadcast p-4 justify-between">
                              <div>
                                <p>4 days ago</p>
                                <p>
                                  Venixs-Wrapping up the year insight from 2023
                                </p>
                              </div>
                              <div>
                                <BsDot />
                                <FaChevronRight />
                              </div>
                            </div>
                            <div className="p-2">
                              <div
                                style={{ borderRadius: "9px" }}
                                className="flex inAppBroadcast p-3 justify-between border "
                              >
                                <div className="flex gap-3">
                                  <div>
                                    <img src={avatar} alt="avatar" />
                                  </div>
                                  <div>
                                    <p>8 days ago</p>
                                    <p>
                                      Venixs-Wrapping up the year insighnts{" "}
                                      <br /> from 2023
                                    </p>
                                  </div>
                                </div>

                                <div>
                                  <BsDot />
                                  <FaChevronRight />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              backgroundColor: "white",
                              justifyContent: "space-between",
                              paddingTop: "9px",
                              paddingLeft: "40px",
                              paddingRight: "40px",
                            }}
                            className="flex "
                          >
                            <div>
                              <img src={home} alt="" />
                              <p>Home</p>
                            </div>
                            <div>
                              <img src={message} alt="" />
                              <p>Messages</p>
                            </div>
                            <div>
                              <img src={faq} alt="" />
                              <p>Faq</p>
                            </div>
                          </div>
                          <div className="flex justify-center">
                            <p>Powered by Venixs</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WidgetAppearance;
