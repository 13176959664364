import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { resetPasswordRequest } from "../../../redux/features/auth/authSlice";
import Cookies from "js-cookie";
import global from "../../../redux/constants/global";
import toast from "react-hot-toast";
import logo from "../../../assets/logo-text.png";
import { FaSpinner } from "react-icons/fa";

export default function ResetPasswordPage() {
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [viewPassword, setViewPassword] = useState(false);
  const [confPassword, setConfPassword] = useState("");
  const [confPasswordError, setConfPasswordError] = useState("");
  const [confViewPassword, setConfViewPassword] = useState(false);
  const navigate = useNavigate();

  const authState = useSelector((state) => state.authReducer);

  let dispatch = useDispatch();
  const param = useParams();
  const { token } = param;

  let checkPassword = () => {
    if (!password) {
      setPasswordError("Password cannot be blank");
    } else {
      setPasswordError("");
    }
  };

  let checkConfPassword = () => {
    if (!confPassword) {
      setConfPasswordError("Please confirm your password");
    } else {
      setConfPasswordError("");
    }
  };

  let finalizePasswordReset = () => {
    let data = {
      token: token,
      password: confPassword,
    };

    if (!password) {
      toast.error("Password cannot be blank");
      return;
    }
    if (!confPassword) {
      toast.error("Please confirm your password");
      return;
    }
    if (password !== confPassword) {
      toast.error("Password does not match");
    } else {
      dispatch(resetPasswordRequest(data))
        .then((res) => {
          if (res?.payload?.status === 200) {
            toast.success("Password reset is successful", { duration: 15000 });
            navigate("/login");
          } else {
            toast.error(
              "Your reset password request is invalid, please try again"
            );
          }
        })
        .catch((err) => {
        });
    }
  };

  return (
    <div className="">
      <div className="">
        <div className=" ">
          <div className="flex justify-center items-center h-screen">
            <div className="">
              <div>
                <div className="text-center ">
                  <p className="font-PJ-medium text-[32px] leading-[40.32px] tracking-[-4%]">
                    Set your new password
                  </p>
                </div>
                <div className="mt-4">
                  <label htmlFor="password">Password</label>
                  <div className="input-group flex items-center">
                    <input
                      type={viewPassword ? "text" : "password"}
                      value={password}
                      className={`form-control border !rounded-r-none px-4 w-full ${
                        passwordError ? "input-error" : ""
                      }`}
                      onBlur={checkPassword}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="*********"
                    />
                    <span>
                      <div
                        className="border form-control
                          flex justify-center h-[44px] px-1 items-center"
                        style={{ borderRadius: "0px 5px 5px 0px" }}
                      >
                        {!viewPassword ? (
                          <i
                            className="ri-eye-line ri-lg"
                            onClick={() => setViewPassword(true)}
                            style={{ cursor: "pointer" }}
                          />


                          
                        ) : (
                          <i
                            className="ri-eye-off-line ri-lg"
                            onClick={() => setViewPassword(false)}
                            style={{ cursor: "pointer" }}
                          />
                        )}      
                      </div>
                    </span>
                  </div>
                  {passwordError && (
                    <p style={{ color: "red" }} className="p-0 m-0">
                      {passwordError}
                    </p>
                  )}
                </div>

                <div className="mt-4">
                  <label htmlFor="confPassword">Confirm Password</label>
                  <div className="input-group flex items-center ">
                    <input
                      type={confViewPassword ? "text" : "password"}
                      value={confPassword}
                      className={`form-control border !rounded-r-none px-4 w-full ${
                        confPasswordError ? "input-error" : ""
                      }`}
                      onBlur={checkConfPassword}
                      onChange={(e) => setConfPassword(e.target.value)}
                      placeholder="*********"
                    />
                    <span>
                      <div
                        className="form-control border h-[44px] px-1 flex justify-center items-center  "
                        style={{ borderRadius: "0px 5px 5px 0px" }}
                      >
                        {!confViewPassword ? (
                          <i
                            className="ri-eye-line ri-lg"
                            onClick={() => setConfViewPassword(true)}
                            style={{ cursor: "pointer" }}
                          />
                        ) : (
                          <i
                            className="ri-eye-off-line ri-lg"
                            onClick={() => setConfViewPassword(false)}
                            style={{ cursor: "pointer" }}
                          />
                        )}
                      </div>
                    </span>
                  </div>
                  {confPasswordError && (
                    <p style={{ color: "red" }} className="p-0 m-0">
                      {confPasswordError}
                    </p>
                  )}
                </div>

                <button
                  className="bg-primary w-full font-inter mt-4 text-base leading-[19.36px] tracking-[-4%] text-light px-10 py-2 rounded-lg"
                  onClick={finalizePasswordReset}
                >
                  {authState.isLoading ? (
                    <div>
                      <FaSpinner className="animate-spin" />

                      <span className="sr-only"> Loading...</span>
                    </div>
                  ) : (
                    "Reset Password"
                  )}
                </button>
                <p className=" text-center mt-4">
                  Don't have an account?
                  <Link to="/signUp" className="link link-underline ms-2">
                    {" "}
                    Sign Up
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
