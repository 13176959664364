import React, { useEffect, useState } from "react";
import Header from "../../../reuseable/header";
import { BsBrushFill, BsDot, BsThreeDots } from "react-icons/bs";
import { MdOutlineMessage } from "react-icons/md";
import {
  FaCheck,
  FaChevronDown,
  FaChevronRight,
  FaGlobeAfrica,
  FaGreaterThan,
  FaHome,
  FaRegQuestionCircle,
  FaTimes,
  FaTimesCircle,
} from "react-icons/fa";
import sdkIcon from "../../../assets/widgetIcons/Sdk Icon.svg";
import { TbExclamationCircle, TbHomeCancel } from "react-icons/tb";
import { VscSend } from "react-icons/vsc";

import { IoClose, IoHomeOutline, IoPricetag } from "react-icons/io5";
import logo from "../../../assets/Venixs 2 1 (1).svg";
import people from "../../../assets/widgetIcons/Frame 1000006239.png";
import send from "../../../assets/widgetIcons/Icon.svg";
import skeleton from "../../../assets/widgetIcons/Frame 1000006186.png";
import avatar from "../../../assets/widgetIcons/Ellipse 10.svg";
import home from "../../../assets/widgetIcons/home.svg";
import message from "../../../assets/widgetIcons/message-text.svg";
import faq from "../../../assets/widgetIcons/faq.svg";
import CustomDropdown from "../../../reuseable/dropDown";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import {
  allGeneralConfigRequest,
  generalConfigUpdateRequest,
} from "../../../redux/features/configurations/generalConfig/generalConfig";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { SketchPicker } from "react-color";
import { RiHome2Line, RiHome5Line } from "react-icons/ri";

const WidgetAppearance = () => {
  const [step, setStep] = useState(1);
  const [checked, setChecked] = useState(false);
  const [checkeds, setCheckeds] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Collage");
  const [showDropdown, setShowDropdown] = useState(false);
  const [showWidget, setShowWidget] = useState(true);
  const [showBorder, setShowBorder] = useState([true, false, false]);
  const [selectedColor, setSelectedColor] = useState(null);
  const [blackCardColor, setBlackCardColor] = useState("#000");
  const [buttonText, setButtonText] = useState("");
  const [inputValue, setInputValue] = useState("");
  // const [logo, setLogo] = useState(null);
  const [configData, setConfigData] = useState({});
  const [supportName, setSupportName] = useState("");
  const [alignment, setAlignment] = useState("Right");

  const colors = [
    "#c2daf1",
    "#000", // green
    "#DED848", // blue
    "#229ACE", // yellow
    "#DA3D3D", // magenta
    "#D9D9D9", // cyan
    "#800080", // purple
    "#ff7f50", // coral
    "#ffa500", // orange
    "#ff6347", // tomato
    "#ff4500", // orangered
    "#ffd700", // gold
    "#ffa07a", // lightcoral
    "#20b2aa", // lightseagreen
    "#ff69b4", // hotpink
    "#8a2be2", // blueviolet
    "#00ced1", // darkturquoise
    "#7fffd4", // aquamarine
    "#008080", // teal
    "#8b0000", // darkred
  ];
  const [showButton, setShowButton] = useState(false);

  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [availableLanguages, setAvailableLanguages] = useState([
    { value: "English", label: "English" },
    { value: "French", label: "French" },
    { value: "Spanish", label: "Spanish" },
    // Add more languages here...
    { value: "German", label: "German" },
    { value: "Chinese", label: "Chinese" },
    { value: "Japanese", label: "Japanese" },
    { value: "Italian", label: "Italian" },
    { value: "Portuguese", label: "Portuguese" },
    { value: "Russian", label: "Russian" },
    { value: "Arabic", label: "Arabic" },
    { value: "Dutch", label: "Dutch" },
    { value: "Swedish", label: "Swedish" },
    { value: "Korean", label: "Korean" },
    { value: "Polish", label: "Polish" },
    { value: "Greek", label: "Greek" },
    { value: "Turkish", label: "Turkish" },
    { value: "Hindi", label: "Hindi" },
    { value: "Bengali", label: "Bengali" },
    { value: "Thai", label: "Thai" },
    { value: "Vietnamese", label: "Vietnamese" },
    // Add 20 more languages here...
  ]);
  const profileState = useSelector((state) => state.profileReducer);
  const generalConfigState = useSelector((state) => state.generalConfigReducer);
  const dispatch = useDispatch();
  let orgId = Cookies.get("orgId") || "";

  useEffect(() => {
    getGeneralConfig();
  }, []);

  let getGeneralConfig = () => {
    dispatch(allGeneralConfigRequest())
      .then((res) => {
        if (res?.payload?.status === 200) {
          setConfigData(res.payload.data[0]);

          return;
        } else {
          toast.error(res?.payload?.data?.response);
        }
      })
      .catch((err) => {});
  };

  let updateGeneralConfig = () => {
    let data = new FormData();

    data.append("support_name", supportName);
    data.append("config_name", profileState?.profileInfo?.data?.name);
    data.append("color", selectedColor);
    data.append("welcome_image", selectedOption);
    data.append("online_status", inputValue);
    data.append("widget_position", alignment);
    data.append("widget_sound", checked);
    data.append("button_label", checkeds);
    data.append("widget_label", buttonText);
    // data.append("logo", logo);
    data.append("organization_id", orgId);

    if (!selectedColor) {
      toast.error("Please pick or indicate your theme");
      return;
    }

    dispatch(generalConfigUpdateRequest(data))
      .then((res) => {
        if (res?.payload?.status === 200) {
          toast.success("General Configuration successfully updated");
          // setSupportName("");
          return;
        } else {
          toast.error(res?.payload?.data?.response);
        }
      })
      .catch((err) => {});
  };

  const handleLanguageSelect = (language) => {
    setSelectedLanguages([...selectedLanguages, language]);
    setAvailableLanguages(
      availableLanguages.filter((lang) => lang.value !== language.value)
    );
  };

  const handleRemoveLanguage = (language) => {
    setSelectedLanguages(
      selectedLanguages.filter((lang) => lang.value !== language.value)
    );
    setAvailableLanguages([...availableLanguages, language]);
  };
  const handleInputChanges = (event) => {
    setInputValue(event.target.value);
  };
  const handleSupportName = (event) => {
    setSupportName(event.target.value);
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  const visibleColors = colors.slice(0, 5);

  const handleDropdownClick = () => {
    setShowDropdown(!showDropdown);
  };

  const handleToggle = () => {
    setChecked((prevChecked) => !prevChecked);
  };
  const handleToggles = () => {
    setCheckeds((prevChecked) => !prevChecked);
  };

  useEffect(() => {
    if (configData) {
      setSelectedColor(configData?.color);
      setBlackCardColor(configData?.color ? configData?.color : "#000");
      setSupportName(configData?.support_name);
      setSelectedOption(
        configData?.welcome_image ? configData?.welcome_image : "Collage"
      );
      setAlignment(
        configData?.widget_position ? configData?.widget_position : "Right"
      );
      setChecked(configData?.widget_sound);
      setInputValue(
        configData?.online_status === "null" ? "" : configData?.online_status
      );
      setCheckeds(configData?.button_label ? configData?.button_label : false);
      setButtonText(
        configData?.widget_label === "null" ? "" : configData?.widget_label
      );
    }
    // setSelectedColor("#000");
  }, [configData]);

  const handleColorSelection = (color) => {
    setSelectedColor(color);
    setBlackCardColor(color);
  };

  const handleAlignmentChange = (event) => {
    setAlignment(event.target.value);
  };

  const handleStepChange = (newStep) => {
    setStep(newStep);
    setShowBorder((prevState) =>
      prevState.map((_, index) => index === newStep - 1)
    );
    setShowButton(newStep === 2);
    setShowWidget(newStep !== 2);
  };

  // const handleSetShowBorder = (value) => {
  //   setShowBorder(true);
  // };

  const handleInputChange = (event) => {
    setButtonText(event.target.value);
  };

  return (
    <div className="mt-11 ">
      <div className="flex  justify-between gap-[2%] ">
        <div className="leftWidget ">
          <div className={`accordion-step shadow-custom  ${step === 1 ? "active" : ""}`}>
            <div
              className="accordion-header"
              onClick={() => handleStepChange(1)}
            >
              <div className="flex px-4 pr-8 justify-between items-center mb-4 mt-2 ">
                <div className="flex gap-2 items-center">
                  <BsBrushFill size={16} /> <h4>Widget Appearance</h4>
                </div>
                <div>
                  <FaChevronDown />
                </div>
              </div>
              {step === 1 && <hr />}
            </div>
            {step === 1 && (
              <>
                <div className="accordion-content ">
                  <div className="px-10">
                    <div>
                      <div className=" flex items-center gap-[100px]   ">
                        <div className="">
                          <h6 className="w-[158px]">Background Color</h6>
                        </div>
                        <div style={{ position: "relative" }}>
                          <div className=" flex gap-3 ">
                            {visibleColors.map((color, index) => (
                              <div
                                key={index}
                                style={{
                                  position: "relative",
                                  width: "48px",
                                  height: "48px",
                                  backgroundColor: color,
                                  borderRadius: "50%",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleColorSelection(color)}
                              >
                                {selectedColor === color && (
                                  <FaCheck
                                    style={{
                                      position: "absolute",
                                      top: "50%",
                                      left: "50%",
                                      transform: "translate(-50%, -50%)",
                                      color: "white",
                                    }}
                                  />
                                )}
                              </div>
                            ))}
                            <div
                              style={{
                                cursor: "pointer",
                                fontSize: "24px",
                                backgroundColor: "#D9D9D9",
                                height: "50px",
                                width: "50px",
                                borderRadius: "50px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                              onClick={handleDropdownClick}
                            >
                              <BsThreeDots />
                            </div>
                          </div>
                          {showDropdown && (
                            <div
                              style={{
                                position: "absolute",
                                top: 60,
                                right: 0,
                              }}
                            >
                              <SketchPicker
                                disableAlpha
                                color={blackCardColor}
                                onChangeComplete={(value) =>
                                  handleColorSelection(value.hex)
                                }
                              />
                              {/* {colors.map((color, index) => (
                                <div
                                  key={index}
                                  style={{
                                    position: "relative",
                                    width: "40px",
                                    height: "40px",
                                    backgroundColor: color,
                                    borderRadius: "50%",
                                    cursor: "pointer",
                                    marginRight: "8px",
                                  }}
                                  onClick={() => handleColorSelection(color)}
                                >
                                  {selectedColor === color && (
                                    <FaCheck
                                      style={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                        color: "white",
                                      }}
                                    />
                                  )}
                                </div>
                              ))} */}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="accordionContent  ">
                        <div>
                          <h6 className="w-[158px]">Agent Name</h6>
                        </div>
                        <div>
                          <input
                            className=" border rounded-lg px-4 py-[10px] w-[350px]"
                            type="text"
                            placeholder="Support Name"
                            value={supportName}
                            onChange={handleSupportName}
                          />
                        </div>
                      </div>
                      <div className="accordionContent">
                        <div>
                          <h6 className="w-[158px]">Welcome Image</h6>
                        </div>
                        <div>
                          <div className="radioCollage flex justify-between gap-3">
                            <input
                              type="radio"
                              id="Collage"
                              name="option"
                              value="Collage"
                              className="cursor-pointer w-5 h-5 mt-[7px]"
                              checked={selectedOption === "Collage"}
                              onChange={() => handleOptionSelect("Collage")}
                            />
                            <div className="operatorsCollage">
                              <h6 className="font-PJ-medium text-sm leading-[21px] ">
                                Operators Collage
                              </h6>
                              <p className="font-PJ-light text-sm leading-[21px]">
                                Show a collage of operators profile at the top
                                of the widget
                              </p>
                            </div>
                          </div>

                          <div className="flex gap-3 opacity-50 pointer-events-none items-center">
                            <input
                              className="cursor-pointer w-5 h-5 "
                              type="radio"
                              id="logo"
                              name="option"
                              value="logo"
                              checked={selectedOption === "logo"}
                              onChange={() => handleOptionSelect("logo")}
                              disabled // Grey out the option
                            />
                            <p className="font-PJ-medium text-sm leading-[21px] ">
                              Your Logo
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordionContent ">
                      <div className="">
                        <h6 className="w-[158px]">Online Status</h6>
                        <p className="OnlineP w-[158px] text-[#1C1F00]">
                          Show this message in chat widget when you're online in
                          your venixs panel
                        </p>
                      </div>
                      <div>
                        <input
                          className=" border rounded-lg px-4 py-[10px] w-[350px]"
                          type="text"
                          placeholder="Typically Respond Instantly"
                          value={inputValue}
                          onChange={handleInputChanges}
                        />
                      </div>
                    </div>
                    <div className="accordionContent">
                      <div>
                        <h6 className="w-[158px]">Widget Position</h6>
                      </div>
                      <div
                        className="radioAlignment"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label
                          style={{
                            position: "relative",
                            marginRight: "10px",
                          }}
                        >
                          <input
                            className="leftRadio"
                            type="radio"
                            value="Left"
                            checked={alignment === "Left"}
                            onChange={handleAlignmentChange}
                            style={{
                              width: "20px",
                              height: "20px",
                              position: "absolute",
                              left: "-12px",
                              top: "40%",
                              transform: "translateY(-50%)",
                            }}
                          />
                        </label>

                        <label style={{ position: "relative" }}>
                          <input
                            className="rightRadio"
                            type="radio"
                            value="Right"
                            checked={alignment === "Right"}
                            onChange={handleAlignmentChange}
                            style={{
                              width: "18px",
                              height: "18px",
                              position: "absolute",
                              right: "-12px",
                              top: "40%",
                              transform: "translateY(-50%)",
                            }}
                          />
                        </label>
                      </div>
                    </div>
                    <div className="mt-[72px] flex gap-[60px]">
                      <h6 className="text-lg w-[200px] leading-[27px] tracking-[-1.5%]  font-inter">
                        Enable Widget Sound
                      </h6>
                      <div
                        className={`w-[45px] h-[26px] rounded-[20px] relative border-2 cursor-pointer ${
                          checked
                            ? "bg-white border-purple-800 "
                            : "bg-purple-800 border-grey"
                        }`}
                        onClick={handleToggle}
                      >
                        <div
                          className={`w-[22px] h-[21px] rounded-full absolute top-[0.5px] left-[-1px]  transform transition-transform transition-background ${
                            checked
                              ? "bg-purple-800"
                              : "translate-x-full bg-white"
                          }`}
                        ></div>
                      </div>
                    </div>
                    <div className="accordionContent gap-[100px] ">
                      <div className="w-[158px]">
                        <h6>Widget Logo</h6>
                        <p className="widgetLogo">
                          Custom branding in widget and emails
                        </p>
                      </div>
                      <div className=" bg-[#F5ECFF] w-[471px] rounded-lg p-3 flex gap-2   ">
                        <TbExclamationCircle
                          className="mt-1"
                          color="#592C83"
                          size={20}
                        />
                        <p className="font-semibold text-lg leading-[27px] text-[#534C5E] font-semiboldInter">
                          Custom branding is available on the Venixs plan. Visit{" "}
                          <Link
                            to={"/subscription"}
                            className="text-[#370089] underline cursor-pointer"
                          >
                            Subscriptions
                          </Link>{" "}
                          to activate
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* </div> */}
              </>
            )}
          </div>

          {/* Step 2 */}
          <div className={`accordion-step shadow-custom  ${step === 2 ? "active" : ""}`}>
            <div
              className="accordion-header"
              onClick={() => handleStepChange(2)}
            >
              <div className="flex px-4 pr-8 justify-between items-center mb-2">
                <div className="flex gap-2 items-center">
                  <IoPricetag size={16} /> Button Label
                </div>
                <div>
                  <FaChevronDown />
                </div>
              </div>
              {step === 2 && <hr className="" />}
            </div>
            {step === 2 && (
              <div className="accordion-content opacity-50 pointer-events-none">
                <div className="px-10">
                  <div className="flex justify-between">
                    <div>
                      <div className="flex items-center gap-[100px] mt-10 mb-10">
                        <div>
                          <h6 className="w-[158px]">Button Label</h6>
                        </div>
                        <div
                          className={`w-[45px] h-[25px] rounded-[20px] relative border-2 cursor-pointer ${
                            checkeds
                              ? "bg-white border-purple-800"
                              : "bg-purple-800 border-purple-800"
                          }`}
                          onClick={handleToggles}
                        >
                          <div
                            className={`w-[22px] h-[21px] rounded-full absolute top-[0.5px] left-[-1px] transform transition-transform transition-background ${
                              checkeds
                                ? "bg-purple-800"
                                : "translate-x-full bg-white"
                            }`}
                          ></div>
                        </div>
                      </div>
                      <div className="flex gap-[100px] items-center">
                        <div className="w-[158px]">
                          <h6>Label Text</h6>
                          <p>
                            Include a short message next to the closed chat
                            icon.
                          </p>
                        </div>
                        <div className="w-[310px]">
                          <input
                            className="border w-full pl-3"
                            type="text"
                            placeholder="Chat with us"
                            value={buttonText}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className={`accordion-step shadow-custom  ${step === 3 ? "active" : ""}`}>
            <div
              className="accordion-header"
              onClick={() => handleStepChange(3)}
            >
              <div className="flex px-4 pr-8 justify-between items-center mb-2">
                <div className="flex gap-2 items-center">
                  <FaGlobeAfrica size={16} /> Multilingual
                </div>
                <div>
                  <FaChevronDown />
                </div>
              </div>
              {step === 3 && <hr className="" />}
            </div>
            {step === 3 && (
              <div className="accordion-content opacity-50 pointer-events-none">
                <div className="px-10">
                  <p className="font-PJ-light text-lg leading-6 mb-8">
                    Add as many languages as you want and let your customers use
                    the widget in the language they have set up for their
                    browser.
                  </p>
                  <div className="flex gap-6 justify-between">
                    <div className="w-full">
                      <div className="flex gap-2 flex-wrap">
                        {selectedLanguages.map((language) => (
                          <div
                            className="border font-regularInter text-sm flex rounded-lg mt-6 items-center gap-2 py-2 px-2"
                            key={language.value}
                          >
                            <p>{language.value}</p>
                            <IoClose
                              size={15}
                              className=""
                              onClick={() => handleRemoveLanguage(language)}
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                        ))}
                      </div>
                      <div className="flex mt-4 gap-10">
                        <div className="w-full">
                          <CustomDropdown
                            options={availableLanguages}
                            selectedOption={
                              selectedLanguages.length > 0
                                ? selectedLanguages[0].label
                                : ""
                            }
                            onChange={(value) => handleLanguageSelect(value)}
                            placeholder="Select Language"
                          />
                        </div>
                        <button
                          className="px-10 py-2 bg-primary font-semiboldInter text-base tracking-[-4%] leading-[19.36px] text-light rounded-lg"
                          disabled
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="rightWidget">
          <div className="flex justify-center items-center">
            <button className=" flex gap-2 font-inter text-xs items-center border p-4 rounded-lg leading-[14.52px] tracking-[-4%]  ">
              <p className="text-[#777777]">Preview</p>

              <FaGreaterThan className="w-2 text-[#1E1E1E66]" />
              <p>WidgetAppearance</p>
            </button>
          </div>
          {showWidget && (
            <div className="flex justify-center ">
              <div>
                <div>
                  <div>
                    <div className="">
                      <div
                        className="rounded-[9px] "
                        style={{ boxShadow: `0px 0px 5px ${blackCardColor}` }}
                      >
                        <div>
                          <div
                            className="blackCard relative"
                            style={{ backgroundColor: blackCardColor }}
                          >
                            {/* <img
                              src={logo}
                              className="flex justify-start"
                              alt="logo"
                            /> */}

                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                padding: "10px",
                                alignItems: "center",
                                gap: "3px",
                              }}
                            >
                              <div className="">
                                <div className=" text-white">
                                  <h1 className="font-PJ-bold text-xl">
                                    Hey There 👋,
                                  </h1>
                                  <p className="font-PJ-bold  text-base">
                                    How can we help you
                                  </p>
                                  {/* <p className="font-inter mt-4 mb-6 text-sm">
                                    How may we help you?
                                  </p> */}
                                </div>
                              </div>
                              <div
                                className="nameHeader"
                                style={{ color: "white" }}
                              >
                                {/* <h6>Hello Stephenie</h6>
                                <p>How may we help you?</p> */}
                              </div>
                              <div>
                                <img src={people} className="" alt="People" />
                              </div>
                            </div>
                          </div>

                          <div className="flex justify-center items-center ">
                            <div className="relative ">
                              <div className="">
                                <div className="flex justify-center items-center">
                                  <div className="p-4 rounded-xl absolute w-[90%] top-[-20px] bg-white flex items-center shadow-lg justify-between ">
                                    <div className="">
                                      <h6 className="font-PJ-medium font-medium text-[10.5px]">
                                        Send us a message
                                      </h6>
                                      <p className="font-inter mt-1 font-normal  text-[7.5px]">
                                        {inputValue}
                                      </p>
                                    </div>
                                    <div>
                                      <VscSend
                                        style={{ color: blackCardColor }}
                                        className="w-[18px]  h-[18px]"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className=" flex justify-center ">
                                  <div className="border mt-[70px] rounded-lg w-[95%]   ">
                                    <img src={skeleton} alt="skeleton" />
                                  </div>
                                </div>
                                <div className="flex inAppBroadcast p-4 justify-between">
                                  <div>
                                    <p>4 days ago</p>
                                    <p>
                                      Venixs-Wrapping up the year insight from
                                      2023
                                    </p>
                                  </div>
                                  <div
                                    className={`flex justify-center items-center  w-4 h-4 rounded-full border`}
                                    style={{ color: blackCardColor }}
                                  >
                                    <FaChevronRight width={2.25} height={4.5} />
                                  </div>
                                </div>
                                <div className="p-2">
                                  <div className="flex inAppBroadcast p-3 rounded-[9px] justify-between border ">
                                    <div className="flex gap-3">
                                      <div>
                                        <img src={avatar} alt="avatar" />
                                      </div>
                                      <div>
                                        <p>8 days ago</p>
                                        <p>
                                          Venixs-Wrapping up the year insights{" "}
                                          <br /> from 2023
                                        </p>
                                      </div>
                                    </div>

                                    <div
                                      className=" flex justify-center items-center w-[18px] h-[18px] rounded-full "
                                      style={{
                                        backgroundColor: blackCardColor,
                                      }}
                                    >
                                      <FaChevronRight
                                        width={2.25}
                                        height={4.5}
                                        color="white"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="border-t">
                                <div
                                  className={`flex justify-between px-10 mt-3 font-PJ-medium text-[9px] leading-[11.34px] tracking-[-4%] `}
                                >
                                  <div className="  text-center flex justify-center flex-col items-center">
                                    <RiHome5Line
                                      style={{ color: blackCardColor }}
                                      className="w-[18px]  h-[18px]"
                                    />
                                    <p>Home</p>
                                  </div>
                                  <div className="text-center flex justify-center flex-col items-center">
                                    <MdOutlineMessage
                                      style={{ color: blackCardColor }}
                                      className="w-[18px]  h-[18px]"
                                    />
                                    <p className="text-center">Messages</p>
                                  </div>
                                  <div className="text-center flex justify-center flex-col items-center">
                                    <FaRegQuestionCircle
                                      style={{ color: blackCardColor }}
                                      className="w-[18px]  h-[18px]"
                                    />
                                    <p className="text-center">Faq</p>
                                  </div>
                                </div>
                              </div>
                              <div className="flex justify-center mb-2 mt-2 ">
                                <p className="font-inter text-[9px] text-[#838383] leading-[10.89px] tracking-[-4%] ">
                                  Powered by Venixs
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {showButton && (
        <div className="flex justify-end">
          <button className="py-[10px] flex justify-between items-center border-b-2 border-b-[#AD39AD] px-2 bg-[#19032e] rounded-xl text-white font-medium leading-[19.36px] font-pj">
            <img src={sdkIcon} alt="" className="w-8 h-8 " />
            {buttonText}
          </button>
        </div>
      )}
      <hr className="mt-[66px] mb-[66px]" />
      <button
        onClick={updateGeneralConfig}
        className="px-5 py-4 mb-24 bg-[#010101] rounded-lg text-[#e5c9ff] font-semibold leading-[19.36px] font-inter "
      >
        Save Changes
      </button>
    </div>
  );
};

export default WidgetAppearance;
